import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast } from '@chakra-ui/react'
import {  MdAdd, MdPeople, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'

export default function PlacementExample({entidade, axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()

    

    const formik = useFormik({
      initialValues:{
        tipo_registo:''
      },
      onSubmit: async (values)=> {
        const res =  await AXIOS.post(`/registro-produtos-cliente`,{...values, cliente: entidade})

        toast({
          title: 'Parametro de registo',
          description: "À carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Parametro de registo',
              description: res.data.mensagem,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            axiosReferencias()
          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Parametro de registo',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      if(loginValue){
       const value = JSON.parse(loginValue)
       setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.900' _hover={{backgroundColor:"blue.800"}} color="white" onClick={()=>setIsOpen(true)}>
          {t("Novo")}
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Parametrizar registo")}</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl>
                        <FormLabel>{t("Empresa")}</FormLabel>
                        <Select placeholder='Selecione o tipo de produto' required name='tipo_registo' value={formik.values.tipo_registo} onChange={formik.handleChange}>
                        <option value={1}>{t("Pagamento")}</option>                       
                        <option value={1}>{t("Carregamento")}</option>                       
                        {/* <option value={2}>{t("Recargas")}</option>                        */}
                        <option value={3}>{t("Facturas")}</option>                       
                      </Select>
                    </FormControl>
                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>{t("Parametrizar")}</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }