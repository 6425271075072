import { Card, CardBody, Container, Spinner, Flex, Box, Text, Heading, Center, Table,  Tr, Tbody, Td,
  Thead,
  Th,Button} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdCorporateFare, MdPeople } from "react-icons/md"
import { BotaoTop, Loader, NavBarAdm, SideBarMenuAdm } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import AXIOS from '../../Config/axiosConfig'
import Chart from 'react-apexcharts'
import useSWR from 'swr'


function Referencias() {

    const [referencias, setReferencias] = useState([])
    
    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({}) 
    


  const fetcher = async (url) =>{
    const {data} = await AXIOS.get(url) 
    
    return data
  }

  const { data, error, isLoading } = useSWR(`/dashboard/`, fetcher)
  
  
    useEffect(()=>{
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/adm/login')
          }
    },[])

    if (isLoading){ 
      return <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
              <Center mt={10}> 
                <Loader /> 
              </Center>
            </Flex>
    }
    
    
  return (
  <>
    
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm/>
      <BotaoTop/>
      <Box flex={1} justifyContent="space-around">
      <NavBarAdm/>
      
            <Flex>              
              {/* <Card mx={5} mt={5} flex={1} bg="red.500" color="white">
                <CardBody display="flex" justifyContent="space-between" alignItems="center">
                  <MdCorporateFare size={70}/>
                  <Container>
                    <Heading>Entidades</Heading>
                    <Text>{referencias.length}</Text>
                  </Container>
                </CardBody>
              </Card> */}
              <Box px={5} w="100%">
              

              <Box mt={5} rounded={5} flex={1} shadow="md"  className="donut" bg="#111" py={5} px={5} direction="column">
                  <Chart options={{
                          chart: {
                            id: "basic-bar",
                            stacked: true,
                          },
                          xaxis: {
                            categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]
                          },
                          stroke:{
                            curve: "smooth"
                          }
                        }} series={[
                            {
                              name:"Quantidade",
                              data: data.mensagem.somatorioMensais.quantidade
                            },
                            {
                              name:"Montante",
                              data: data.mensagem.somatorioMensais.soma
                            }
                        ]
                        } type="area"  width="100%" height={400}/>
                  </Box>
                  
              
              <Flex color="gray.500" py={3} mt={2} justifyContent="space-between" flexDir="column">

                <Flex mt={10} flex={1} width="100%" className="donut" rounded={5}  bg="#111" p={5} direction="column">
                  <Table variant='unstyled' colorScheme='gray'>
                    <Thead>
                      <Tr>
                        <Th>Entidade</Th>
                        <Th>Referência</Th>
                        <Th>Montante</Th>
                        <Th>data e hora</Th>
                        <Th>Periodo</Th>
                        <Th>Transação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.mensagem.ultimosPagamentos.map((el,i) =>
                          <Tr key={i} color="white">
                            <Td>{el.numero_entidade}</Td>
                            <Td>{el.referencia_do_servico}</Td>
                            <Td color="whatsapp.300" fontWeight={700}>AKZ {Intl.NumberFormat("PT-br").format(el.montante_da_operacao)}</Td>
                            <Td>{new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleDateString()} {new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleTimeString()}</Td>
                            <Td>{el.Identificacao_Log_EGR}</Td>
                            <Td>{el.Identificacao_Log_EGR + el.numero_Log_EGR}</Td>
                          </Tr>
                      ).slice(0,20)}
                    </Tbody>
                  </Table>
                </Flex>

              </Flex>
            </Box>
              {/* <Card mx={5} flex={1} bg="orange.500" color="white">
                <CardBody display="flex" justifyContent="space-between" alignItems="center">
                  <MdPeople size={70}/>
                  <Container>
                    <Heading>Pagamentos</Heading>
                    <Text>{pagamentos.length}</Text>
                  </Container>
                </CardBody>
              </Card>
              <Card mx={5} flex={1}  bg="yellow.500" color="white">
                <CardBody display="flex" justifyContent="space-between" alignItems="center">
                  <MdPeople size={70}/>
                  <Container>
                    <Heading>Notificações</Heading>
                    <Text>{12}</Text>
                    </Container>
                </CardBody>
              </Card>
              <Card mx={5} flex={1}  bg="purple.500" color="white">
                <CardBody display="flex" justifyContent="space-between" alignItems="center">                
                  <MdPeople size={70}/>
                  <Container>
                    <Heading>Ficheiros</Heading>
                    <Text>{files.length}</Text>
                  </Container>
                </CardBody>
              </Card> */}
            
            </Flex>
            
      </Box>
    </Flex>
  </>
  )
}

export default Referencias