import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, RadioGroup,
  Stack,
  Radio,FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast, Tooltip } from '@chakra-ui/react'
import {  MdAdd, MdFilter, MdPeople, MdSearch, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export default function PlacementExample({preencherNaTabela, entidade}) {
    const [placement] = useState('left')
    const [referencias, setReferencias] = useState([])
    const [produtos, setProdutos] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [tipo_re, setTipo_re] = useState(["Pagamento/Carregamento"])
    const [user, setUser] = useState('1')
    const [indicador_id, setIndicador_produto_id] = useState('1')
    const refREF = useRef(null)
    const refREFOPT1 = useRef(null)
    const refREFOPT2 = useRef(null)
    const refREFOPT3 = useRef(null)
    const refREFMontante = useRef(null)
    const refCodCli = useRef(null)
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    
    async function axiosReferencias(){
      try {
        
          const referencia = await AXIOS.get(`/registro-produtos-cliente/entidade/${entidade}`)
          if(referencia.data.status === "erro")
              setReferencias([])
          else{
              setReferencias(referencia.data.mensagem[0].registo)
          }

        }catch (error) {
          navigate('/sem-comunicacao')
      }
      
          
  }  

  const buscarProduto =  async (item) =>{ 
  
    try{
      const referencia = await AXIOS.get(`/produtos-clientes/produto/${item}`)
      if(referencia.data.status === "erro")
          setProdutos([])
        else{
          if(referencia.data.mensagem.length){
            setProdutos(referencia.data.mensagem[0].registo)
            console.log(referencia.data.mensagem[0].registo)
          }
          else
            setProdutos([])
        }

      }catch (error) {
        navigate('/sem-comunicacao')
    }
  }
    
  const onTipoRegisto = (value)=>{
      try{
        const valores = value.split(':')
        if(valores[1] === "1"){
          setTipo_re(["Pagamento/Carregamento"])
        }
        
        if(valores[1] === "2"){
          setTipo_re(["Recargas"])
        }
        
        if(valores[1] === "3"){
          setTipo_re(["Facturas"])
        }
        
        buscarProduto(valores[0])
        // refREF.current.setAttribute('value','')
      }catch (error) {
        navigate('/sem-comunicacao')
    }
  }
  
  const formik = useFormik({
    initialValues:{
      num_referencia:'',
      tipo_de_registro:'',
      indicador_de_produtos: '',
      Identificacao_Log_EGR: '',
      numero_Log_EGR: '',
      data_limite_pagamento: '',
      hora_do_movimento: '',
      data_movimento: '',
      montante_da_operacao: '',
      tipo_de_terminal: ''
    },
    onSubmit: async (values)=> {
    
      
      
      toast({
        title: 'Pesquisar pagamentos',
        description: "Á carregar o registro",
        status: 'loading',
        duration: 500,
        isClosable: true,
      })

      const res =  await AXIOS.post(`/transacoes/pesquisar`,{...values, entidade_cliente: entidade, indicador_produto_id: indicador_id}) 
      
      if(res.data.status === "sucesso"){
        
          toast({
            title: 'Pesquisar pagamentos',
            description: "Pesquisa feita com sucesso",
            status: 'success',
            duration: 2000,
            isClosable: true,
          })

          preencherNaTabela(res)
          
      }else{
        setTimeout(()=>{
          toast({
            title: 'Pesquisar pagamentos',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },5000)
      }
      
      
    },
  })
  
  const validarDigitos = (evt) =>{
    try{
      const keys = ["8","46","48","49","50","51","52","53","54","55","56","57"]
      const find = keys.find(key => key == evt.keyCode)
      if(!find) evt.target.value = evt.target.value.replace(evt.target.value.substr(-1),'')
    }catch (error) {
      navigate('/sem-comunicacao')
    }
      
  }
  
  
  const onClose = function(){
      setIsOpen(false)
  }

  useEffect(()=>{
    axiosReferencias();
      
  },[])

    return (
      <>
        <Button leftIcon={<MdFilter/>} bg='blue.400' ml={2} _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          {t("Filtro")}
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Filtragem de transações")}</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl mt={4}>
                          <FormLabel>{t("Referência")}</FormLabel>
                          <Flex direction="row" alignItems="center">
                            <Input type='text' onKeyUp={validarDigitos} name='num_referencia'  ref={refREF}
                            onChange={formik.handleChange}
                            value={formik.values.num_referencia} />
                          </Flex>
                      </FormControl>
                      <FormControl ml={4} mt={4}>
                          <FormLabel>{t("Montante")}</FormLabel>
                          <Flex direction="row" alignIterems="center">
                            <Input type='number' name='montante_da_operacao' onKeyUp={validarDigitos} min={10} max={19999999999}
                              onChange={formik.handleChange}
                              value={formik.values.montante_da_operacao} />
                          </Flex>
                      </FormControl>
                    </Flex>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <FormControl mt={4}>
                            <FormLabel>{t("Para qual tipo")}</FormLabel>
                            <Select placeholder={t('Selecione o tipo de produto')}  name='tipo_de_registro' value={formik.values.tipo_de_registro} onChange={(evt) =>{formik.handleChange(evt);onTipoRegisto(evt.target.options.item(evt.target.options.selectedIndex).getAttribute('id'))}}>
                            {referencias.map(el => <option id={el.id_tipo_produto_clientes + ":" + el.id_tipo_produto} value={el.id_tipo_produto}>{t(el.registo_produto)}</option>)}
                          </Select>  
                        </FormControl> 
                        <FormControl ml={4} mt={4}>
                            <FormLabel>{t("Indica seu produto")}</FormLabel>
                            <Select placeholder={t('Selecione o seu produto para referência')}  name='indicador_de_produtos' value={formik.values.indicador_de_produtos} onChange={(evt)=>{setIndicador_produto_id(evt.target.options.item(evt.target.options.selectedIndex).getAttribute('id'));formik.handleChange(evt);}}>
                              {produtos.map(el => <option id={el.id_produto} value={el.codigo_do_produto}>{el.produto}</option>)}
                            </Select>
                        </FormControl>
                    </Flex>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">                      
                     <FormControl mt={4}>
                          <FormLabel>{t("Data de limite de pagamento")}</FormLabel>
                          <Input type='date' name='data_limite_pagamento'  
                            onChange={formik.handleChange}
                            value={formik.values.data_limite_pagamento} />
                      </FormControl>
                     <FormControl mt={4} ml={4}>
                          <FormLabel>{t("Data do movimento")}</FormLabel>
                          <Input type='date' name='data_movimento'  
                            onChange={formik.handleChange}
                            value={formik.values.data_movimento} />
                      </FormControl>
                     <FormControl mt={4} ml={4}>
                          <FormLabel>{t("Hora do movimento")}</FormLabel>
                          <Input type='time' name='hora_do_movimento'  
                            onChange={formik.handleChange}
                            value={formik.values.hora_do_movimento} />
                      </FormControl>
                    </Flex>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">                      
                     <FormControl mt={4}>
                          <FormLabel>{t("Tipo de terminal")}</FormLabel>
                          <Select placeholder={t('Selecione o terminal')}  name='tipo_de_terminal' value={formik.values.tipo_de_terminal} onChange={formik.handleChange}>
                            <option value="A">ATM</option>
                            <option value="M">Multicaixa Express</option>
                            <option value="L">Internet Banking</option>
                          </Select>
                      </FormControl>
                     <FormControl mt={4} ml={4}>
                          <FormLabel>{t("Periodo")}</FormLabel>
                          <Input type='text' name='Identificacao_Log_EGR'  
                            onChange={formik.handleChange}
                            value={formik.values.Identificacao_Log_EGR} />
                      </FormControl>
                     <FormControl mt={4} ml={4}>
                          <FormLabel>{t("Log EGR")}</FormLabel>
                          <Input type='text' name='numero_Log_EGR'  
                            onChange={formik.handleChange}
                            value={formik.values.numero_Log_EGR} />
                      </FormControl>
                    </Flex>
                    
                    <Button leftIcon={<MdSearch/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Pesquisar")}</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }