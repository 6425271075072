import { Flex, Box, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Button, Center, Spinner, useToast, Select, Input,NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper, } from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import { MdArrowBack, MdArrowForward, MdError, MdFileDownload, MdFileUpload, MdOutlineFileDownload, MdUpgrade} from "react-icons/md"
import { ModalDownloadFiles, SideBarMenu, NavBar, SideBarMenuAdm, NavBarAdm, BotaoTop } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'


function Ficheiros() {

    const [referencias, setReferencias] = useState([])
    const [referenciasFilter, setReferenciasFilter] = useState([])
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState('1')
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const {t, i18n} = useTranslation()
    const [pagina, setPagina] = useState('1')
    const [paginas, setPaginas] = useState('1')
    const [quantidade, setQuantidade] = useState('100')
    
    async function axiosReferencias(){
    
    try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/ficheiros/arquivos/movimentos/?limite=${quantidade}&pagina=${pagina}`)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem)
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
      }
          
      console.log(referencia.data.mensagem)
      setLoader(true)
      
    }catch (error) {
      navigate('/sem-comunicacao')
  }
          
    }

    async function actulizarFiles(){
    
      try {
        setLoader(false)
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}").mensagem.numero_entidade
        const referencia = await AXIOS.get(`/ficheiros/arquivos/movimentos/?limite=${quantidade}&pagina=${pagina}`)
        if(referencia.data.status === "erro")
            setReferencias([])
        else{
            setReferencias(referencia.data.mensagem)
            setReferenciasFilter(referencia.data.mensagem)
            setPagina(referencia.data.registros.pagina_actual) 
            setPaginas(referencia.data.registros.paginas) 
            setQuantidade(referencia.data.registros.limite) 
        }
            
        console.log(referencia.data.mensagem)
        setLoader(true)
        
      }catch (error) {
        navigate('/sem-comunicacao')
    }
            
      }
    
  
    useEffect(()=>{
              axiosReferencias();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/adm/login')
          }
    },[])
    
    const encaminharFileEMIS = async (sequencia) => {
    
    try {
      const res = await AXIOS.get(`/ficheiros/arquivos/movimentos/encaminhar/${sequencia}`) 
      if(res.data.status === "sucesso") {     
      
        toast({
          title: 'Parametrização das referências',
          description: res.data.mensagem,
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      }else{      
      
        toast({
          title: 'Parametrização das referências',
          description: res.data.mensagem,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }
      
      axiosReferencias();
      
    }catch (error) {
      toast({
        title: 'Alerta',
        description: "Não conseguimos ter comunicação! Verifique sua internet",
        status: 'error',
      })
  }
    }

  
const buscarComPaginacao  = async function(){
  try {
    setLoader(false)
    
    const referencia = await AXIOS.get(`/ficheiros/arquivos/movimentos/?limite=${quantidade}&pagina=${pagina}`)
    console.log(referencia.data)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem) 
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }
      
      setLoader(true)
        
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
}
    
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const itemsPerPage = 50
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = referenciasFilter.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
    const selectdItem = (evt) =>{
          const tipo = evt.target.value 
          const filtered = referencias.filter(item => item.tipo.includes(tipo))
          setReferenciasFilter(filtered)
    }
    
    const selectdItemText = (evt) =>{
      const tipo = evt.target.value.toLocaleLowerCase()
      const modified = referencias.map(item => {
        
        return {
          ...item,
          "arquivo": item.arquivo,
          "sequencia": item.sequencia,
          "tipo": item.tipo,
          "gerado_em": item.gerado_em,
          "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
          "registo_produto": item.registo_produto
        }
        
      
      })
      const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
      setReferenciasFilter(filtered)
      
    }

    let permissao = user?.pagina_fmovs == "true";

    if(user?.pagina_fmovs) permissao = user?.pagina_fmovs == "true"
    else permissao = user.todas_areas ? true : false;
    
  return (
    <>
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm />
      <BotaoTop/>
      <Box flex={1}>
      <NavBarAdm/>
          {
          
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
            <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Arquivos de movimentos</Text>
          <Flex color="gray.500" alignItems="center">
            <Input w="sm" type="search" bg="white" placeholder='Pesquise ...' onChange={selectdItemText}/>
            <Button onClick={actulizarFiles} bg="gray.600" color="white" 
                    _hover={{ backgroundColor: "gray.700" }} ml={2} leftIcon={<MdUpgrade/>}>Actualizar lista</Button>
          </Flex>
          <Flex color="gray.500" mt={2} alignItems="center">

              <NumberInput
                  name='pagina'
                  onChange={(valueString) => setPagina(parseInt(valueString))}
                  value={pagina}
                  max={paginas}
                  min={1}
                  width={120}
                  ml={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                </Select>
                <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
                
              </Flex>
          {
          loader
          ?
          
          <TableContainer my={5} bg="white">
            <Table variant='striped' colorScheme='gray'>
              <TableCaption>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
              </TableCaption>
              <Thead p={10}>
                <Tr bg="gray.600">
                  <Th py={6} color="gray.300">Entidade</Th>
                  <Th py={6} color="gray.300">Ficheiro</Th>
                  <Th py={6} color="gray.300">Gerado em</Th>
                  <Th py={6} color="gray.300">Estado</Th>
                  <Th py={6} color="gray.300">Baixar</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map(el =>
                    <Tr key={el.idficheiros} color="blackAlpha.700">
                      <Td>{el.entidade_destino.substr(0,18)}<b>{el.entidade_destino.substr(-5)}</b></Td>
                      <Td>{el.ficheiro}</Td>
                      <Td>{new Date(el.inserido_em).toLocaleDateString()} {new Date(el.inserido_em).toLocaleTimeString()}</Td>
                      <Td>Recebido</Td>
                      <Td>
                          <a target='_blank' download={`${CONFIG.HOST_API}/fmovs/`+el.ficheiro} href={`${CONFIG.HOST_API}/fmovs/`+el.ficheiro}><MdOutlineFileDownload size={30} /></a>
                      </Td>
                    </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          :
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>}
          
          </Box>

          :   

          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
          }
      </Box>
    </Flex>
  </>
  )
}

export default Ficheiros