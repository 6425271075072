import { useEffect, useRef, useState } from 'react'
import { MdAccessible, MdAccountBox, MdClose, MdDoneOutline, MdError, MdOutlineWifiTetheringErrorRounded, MdPassword, MdSave, MdSupervisorAccount, MdWebhook } from "react-icons/md"
import {BsToggleOn, BsToggleOff} from 'react-icons/bs'
import {  BotaoTop, Loader, ModalMudarSenha, NavBar, NavBarAdm, SideBarMenu, SideBarMenuAdm } from '../../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Center, useToast, Switch, Textarea, Select, Spinner} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'


function Referencias() {

    const [registo, setReferencias] = useState({})    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const toast = useToast() 
    const [showAnyEvents, setShowAnyEvents] = useState(false)
    const refEvento =  useRef();
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
    
    try {
      
      const referencia = await AXIOS.get(`/usuarios/${id}/permissoes`);

      console.log(referencia.data.mensagem[0])
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length){          
            setReferencias(referencia.data.mensagem[0])
        }else{
            setReferencias([])
        }
      }

      
        
        setLoader(true)
      
      }catch (error) {
        navigate('/sem-comunicacao')
    }
          
  }    
  
  const formik = useFormik({
    initialValues:{
      todas_areas:'false',
      pagina_entidades:'false',
      pagina_usuarios:'false',
      pagina_pos_gpo:'false',
      pagina_fmovs:'false',
      pagina_comprovantes:'false',
      pagina_transacoes_percentual:'false',
      pagina_custo_uso:'false',
      pagina_email:'false',
      pagina_whatsapp:'false',
      pagina_sessao:'false',
      pagina_sms: 'false',
      pagina_integracao:'false',
      pagina_webhooks:'false',
      pagina_tipo_pagamentos:'false',
      pagina_servicos:'false',
      pagina_referencia: 'false',
      pagina_pagamento: 'false',
      pagina_contabilistico: 'false',
      pagina_relatorio: 'false',
      pagina_transacoes: 'false',
      pagina_sms_notify: 'false',
      pagina_faq: 'false',
      pagina_afiliado: 'false',
      pagina_servico_api: 'false',
      pagina_token: 'false',
      pagina_webhooks: 'false',
      pagina_notificacao: 'false',
      pagina_perfil: 'false',
      pagina_permissoes: 'false',
      pagina_requisicoes: 'false',
      pagina_req_geral: 'false',
    },
    onSubmit: async (values)=> {
    try{

      values.todas_areas = values.todas_areas ? "true" : "false"
      values.pagina_referencia = values.pagina_referencia ? "true" : "false"
      values.pagina_entidades = values.pagina_entidades ? "true" : "false"
      values.pagina_pos_gpo = values.pagina_pos_gpo ? "true" : "false"
      values.pagina_usuarios = values.pagina_usuarios ? "true" : "false"
      values.pagina_fmovs = values.pagina_fmovs ? "true" : "false"
      values.pagina_comprovantes = values.pagina_comprovantes ? "true" : "false"
      values.pagina_transacoes_percentual = values.pagina_transacoes_percentual ? "true" : "false"
      values.pagina_custo_uso = values.pagina_custo_uso ? "true" : "false"
      values.pagina_email = values.pagina_email ? "true" : "false"
      values.pagina_whatsapp = values.pagina_whatsapp ? "true" : "false"
      values.pagina_sessao = values.pagina_sessao ? "true" : "false"
      values.pagina_integracao = values.pagina_integracao ? "true" : "false"
      values.pagina_tipo_pagamentos = values.pagina_tipo_pagamentos ? "true" : "false"
      values.pagina_servicos = values.pagina_servicos ? "true" : "false"
      values.pagina_pagamento = values.pagina_pagamento ? "true" : "false"
      values.pagina_contabilistico = values.pagina_contabilistico ? "true" : "false"
      values.pagina_relatorio = values.pagina_relatorio ? "true" : "false"
      values.pagina_transacoes = values.pagina_transacoes ? "true" : "false"
      values.pagina_sms_notify = values.pagina_sms_notify ? "true" : "false"
      values.pagina_faq = values.pagina_faq ? "true" : "false"
      values.pagina_afiliado = values.pagina_afiliado ? "true" : "false"
      values.pagina_servico_api = values.pagina_servico_api ? "true" : "false"
      values.pagina_sms = values.pagina_sms ? "true" : "false"
      values.pagina_token = values.pagina_token ? "true" : "false"
      values.pagina_webhooks = values.pagina_webhooks ? "true" : "false"
      values.pagina_notificacao = values.pagina_notificacao ? "true" : "false"
      values.pagina_perfil = values.pagina_perfil ? "true" : "false"
      values.pagina_permissoes = values.pagina_permissoes ? "true" : "false"
      values.pagina_requisicoes = values.pagina_requisicoes ? "true" : "false"
      values.pagina_req_geral = values.pagina_req_geral ? "true" : "false"
      
      const res =  await AXIOS.patch(`/usuarios/${id}/permissao/${registo.permissoes_usuarios}/permissoes`,{...values})
      
      if(res.data.status === "sucesso"){
        toast({
          title: t('Permissões de usuaris'),
          description: t(res.data.mensagem),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        
      }else{
        toast({
          title: t('Permissões de usuarios'),
          description: t(res.data.mensagem),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      navigate(`/adm/permissoes`)
      axiosReferencias(); 
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
      
    },
  })

  
    useEffect(()=>{
      axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
          setUser(value.mensagem)
       }else{
          navigate('/adm/login')
      }
          
      
          
    },[])
    
    
      formik.initialValues.todas_areas = registo.todas_areas == "true" ? true : false
      formik.initialValues.pagina_entidades = registo.pagina_entidades == "true" ? true : false
      formik.initialValues.pagina_pos_gpo = registo.pagina_pos_gpo == "true" ? true : false
      formik.initialValues.pagina_usuarios = registo.pagina_usuarios == "true" ? true : false
      formik.initialValues.pagina_fmovs = registo.pagina_fmovs == "true" ? true : false
      formik.initialValues.pagina_comprovantes = registo.pagina_comprovantes == "true" ? true : false
      formik.initialValues.pagina_transacoes_percentual = registo.pagina_transacoes_percentual == "true" ? true : false
      formik.initialValues.pagina_custo_uso = registo.pagina_custo_uso == "true" ? true : false
      formik.initialValues.pagina_email = registo.pagina_email == "true" ? true : false
      formik.initialValues.pagina_whatsapp = registo.pagina_whatsapp == "true" ? true : false
      formik.initialValues.pagina_sessao = registo.pagina_sessao == "true" ? true : false
      formik.initialValues.pagina_integracao = registo.pagina_integracao == "true" ? true : false
      formik.initialValues.pagina_tipo_pagamentos = registo.pagina_referencia == "true" ? true : false
      formik.initialValues.pagina_referencia = registo.pagina_tipo_pagamentos == "true" ? true : false
      formik.initialValues.pagina_servicos = registo.pagina_servicos == "true" ? true : false
      formik.initialValues.pagina_referencia = registo.pagina_referencia == "true" ? true : false
      formik.initialValues.pagina_pagamento = registo.pagina_pagamento == "true" ? true : false
      formik.initialValues.pagina_contabilistico =  registo.pagina_contabilistico == "true" ? true : false
      formik.initialValues.pagina_relatorio =  registo.pagina_relatorio == "true" ? true : false
      formik.initialValues.pagina_transacoes =  registo.pagina_transacoes == "true" ? true : false
      formik.initialValues.pagina_sms_notify =  registo.pagina_sms_notify == "true" ? true : false
      formik.initialValues.pagina_servico_api =  registo.pagina_servico_api == "true" ? true : false
      formik.initialValues.pagina_faq =  registo.pagina_faq == "true" ? true : false
      formik.initialValues.pagina_afiliado =  registo.pagina_afiliado == "true" ? true : false
      formik.initialValues.pagina_sms =  registo.pagina_sms == "true" ? true : false
      formik.initialValues.pagina_token =  registo.pagina_token == "true" ? true : false
      formik.initialValues.pagina_webhooks =  registo.pagina_webhooks == "true" ? true : false
      formik.initialValues.pagina_notificacao =  registo.pagina_notificacao == "true" ? true : false
      formik.initialValues.pagina_perfil =  registo.pagina_perfil == "true" ? true : false
      formik.initialValues.pagina_permissoes =  registo.pagina_permissoes == "true" ? true : false
      formik.initialValues.pagina_requisicoes =  registo.pagina_requisicoes == "true" ? true : false
      formik.initialValues.pagina_req_geral =  registo.pagina_req_geral == "true" ? true : false
      
      
      // if(formik.initialValues.todas_areas){
      //     formik.initialValues.pagina_entidades = false
      //     formik.initialValues.pagina_usuarios = false
      //     formik.initialValues.pagina_fmovs = false
      //     formik.initialValues.pagina_comprovantes = false
      //     formik.initialValues.pagina_transacoes_percentual = false
      //     formik.initialValues.pagina_custo_uso = false
      //     formik.initialValues.pagina_email = false
      //     formik.initialValues.pagina_whatsapp = false
      //     formik.initialValues.pagina_sessao = false
      //     formik.initialValues.pagina_integracao = false
      //     formik.initialValues.pagina_tipo_pagamentos = false
      //     formik.initialValues.pagina_referencia = false
      //     formik.initialValues.pagina_servicos = false
      //     formik.initialValues.pagina_referencia = false
      //     formik.initialValues.pagina_pagamento = false
      //     formik.initialValues.pagina_contabilistico = false 
      //     formik.initialValues.pagina_relatorio = false
      //     formik.initialValues.pagina_transacoes = false
      //     formik.initialValues.pagina_sms_notify = false
      //     formik.initialValues.pagina_servico_api = false
      //     formik.initialValues.pagina_faq =  false
      //     formik.initialValues.pagina_afiliado = false
      //     formik.initialValues.pagina_sms =  false
      //     formik.initialValues.pagina_token = false
      //     formik.initialValues.pagina_webhooks = false
      //     formik.initialValues.pagina_notificacao = false
      //     formik.initialValues.pagina_perfil = false
      //     formik.initialValues.pagina_permissoes = false
      //     formik.initialValues.pagina_requisicoes = false
      //     formik.initialValues.pagina_req_geral = false
      // }
  
      
      
      
  let permissao = user?.pagina_permissoes == "true";

  if(user?.pagina_permissoes) permissao = user?.pagina_permissoes == "true"
  else permissao = user.todas_areas ? true : false;
      
  return (
  
    <>
    <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>{
        
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
            {registo.id_usuarios && <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Permissões de usuários - {registo.nome_usuario}</Text>}
            {loader ?
            <Flex color="black">
            {registo.id_usuarios && <Box flex={1} px={10}>
            <form onSubmit={formik.handleSubmit}>
              <Box bg='white' p={10} rounded={5} boxShadow="md">
                
                <Heading mt={4}>Acesso completo</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='todas_areas' mb='0'>
                      {t("Acesso Master")}
                    </FormLabel>
                    <Switch id='todas_areas' name='todas_areas' onChange={formik.handleChange} value={formik.values.todas_areas} defaultChecked={formik.values.todas_areas} /> 
                  </FormControl>
                </Flex>
                <Heading mt={4}>Gestão</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_entidades' mb='0'>
                      {t("Entidades")}
                    </FormLabel>
                    <Switch id='pagina_entidades' name='pagina_entidades' onChange={formik.handleChange} value={formik.values.pagina_entidades} defaultChecked={formik.values.pagina_entidades} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_pos_gpo' mb='0'>
                      {t("Terminais GPO (POS)")}
                    </FormLabel>
                    <Switch id='pagina_pos_gpo' name='pagina_pos_gpo' onChange={formik.handleChange} value={formik.values.pagina_pos_gpo} defaultChecked={formik.values.pagina_pos_gpo} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_usuarios' mb='0'>
                      {t("Usuarios")}
                    </FormLabel>
                    <Switch id='pagina_usuarios' name='pagina_usuarios' onChange={formik.handleChange} value={formik.values.pagina_usuarios} defaultChecked={formik.values.pagina_usuarios} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_fmovs' mb='0'>
                      {t("FMOVs")}
                    </FormLabel>
                    <Switch id='pagina_fmovs' name='pagina_fmovs' onChange={formik.handleChange} value={formik.values.pagina_fmovs} defaultChecked={formik.values.pagina_fmovs} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_transacoes' mb='0'>
                      {t("Transações")}
                    </FormLabel>
                    <Switch id='pagina_transacoes' name='pagina_transacoes' onChange={formik.handleChange} value={formik.values.pagina_transacoes} defaultChecked={formik.values.pagina_transacoes} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_comprovantes' mb='0'>
                      {t("Comprovantes")}
                    </FormLabel>
                    <Switch id='pagina_comprovantes' name='pagina_comprovantes' onChange={formik.handleChange} value={formik.values.pagina_comprovantes} defaultChecked={formik.values.pagina_comprovantes} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_custo_uso' mb='0'>
                      {t("Custo por uso")}
                    </FormLabel>
                    <Switch id='pagina_custo_uso' name='pagina_custo_uso' onChange={formik.handleChange} value={formik.values.pagina_custo_uso} defaultChecked={formik.values.pagina_custo_uso} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_referencia' mb='0'>
                      {t("Referencias")}
                    </FormLabel>
                    <Switch id='pagina_referencia' name='pagina_referencia' onChange={formik.handleChange} value={formik.values.pagina_referencia} defaultChecked={formik.values.pagina_referencia} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_pagamento' mb='0'>
                      {t("Pagamentos")}
                    </FormLabel>
                    <Switch id='pagina_pagamento' name='pagina_pagamento' onChange={formik.handleChange} value={formik.values.pagina_pagamento} defaultChecked={formik.values.pagina_pagamento} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_pagamento' mb='0'>
                      {t("Periodo Contabilistico")}
                    </FormLabel>
                    <Switch id='pagina_contabilistico' name='pagina_contabilistico' onChange={formik.handleChange} value={formik.values.pagina_contabilistico} defaultChecked={formik.values.pagina_contabilistico} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_relatorio' mb='0'>
                      {t("Relatórios")}
                    </FormLabel>
                    <Switch id='pagina_relatorio' name='pagina_relatorio' onChange={formik.handleChange} value={formik.values.pagina_relatorio} defaultChecked={formik.values.pagina_relatorio} /> 
                  </FormControl>
                </Flex>
                <Heading mt={4}>Comunicação</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_email' mb='0'>
                      {t("Email Marketing")}
                    </FormLabel>
                    <Switch id='pagina_email' name='pagina_email' onChange={formik.handleChange} value={formik.values.pagina_email} defaultChecked={formik.values.pagina_email} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_sms' mb='0'>
                      {t("SMS Marketing")}
                    </FormLabel>
                    <Switch id='pagina_sms' onChange={formik.handleChange} value={formik.values.pagina_sms} defaultChecked={formik.values.pagina_sms} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_whatsapp' mb='0'>
                      {t("Whatsapp Marketing")}
                    </FormLabel>
                    <Switch id='pagina_whatsapp' name='pagina_whatsapp' onChange={formik.handleChange} value={formik.values.pagina_whatsapp} defaultChecked={formik.values.pagina_whatsapp} /> 
                  </FormControl>
                </Flex>
                <Heading mt={4}>Documentação</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_servico_api' mb='0'>
                      {t("Serviços API")}
                    </FormLabel>
                    <Switch id='pagina_servico_api' name='pagina_servico_api' onChange={formik.handleChange} value={formik.values.pagina_servico_api} defaultChecked={formik.values.pagina_servico_api} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_faq' mb='0'>
                      {t("FAQ")}
                    </FormLabel>
                    <Switch id='pagina_faq' name='pagina_faq' onChange={formik.handleChange} value={formik.values.pagina_faq} defaultChecked={formik.values.pagina_faq} /> 
                  </FormControl>
                </Flex>
                <Heading mt={4}>Configurações</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_afiliado' mb='0'>
                      {t("Afiliados")}
                    </FormLabel>
                    <Switch id='pagina_afiliado' name='pagina_afiliado' onChange={formik.handleChange} value={formik.values.pagina_afiliado} defaultChecked={formik.values.pagina_afiliado} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_sms_notify' mb='0'>
                      {t("Notificações de SMS")}
                    </FormLabel>
                    <Switch id='pagina_sms_notify' name='pagina_sms_notify' onChange={formik.handleChange} value={formik.values.pagina_sms_notify} defaultChecked={formik.values.pagina_sms_notify} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_token' mb='0'>
                      {t("Token API")}
                    </FormLabel>
                    <Switch id='pagina_token' name='pagina_token' onChange={formik.handleChange} value={formik.values.pagina_token} defaultChecked={formik.values.pagina_token} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_webhooks' mb='0'>
                      {t("Webhooks")}
                    </FormLabel>
                    <Switch id='pagina_webhooks' name='pagina_webhooks' onChange={formik.handleChange} value={formik.values.pagina_webhooks} defaultChecked={formik.values.pagina_webhooks} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_notificacao' mb='0'>
                      {t("Notificações")}
                    </FormLabel>
                    <Switch id='pagina_notificacao' name='pagina_notificacao' onChange={formik.handleChange} value={formik.values.pagina_notificacao} defaultChecked={formik.values.pagina_notificacao} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_perfil' mb='0'>
                      {t("Perfil")}
                    </FormLabel>
                    <Switch id='pagina_perfil' name='pagina_perfil' onChange={formik.handleChange} value={formik.values.pagina_perfil} defaultChecked={formik.values.pagina_perfil} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_sessao' mb='0'>
                      {t("Sessão")}
                    </FormLabel>
                    <Switch id='pagina_sessao' name='pagina_sessao' onChange={formik.handleChange} value={formik.values.pagina_sessao} defaultChecked={formik.values.pagina_sessao} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_integracao' mb='0'>
                      {t("Integrações")}
                    </FormLabel>
                    <Switch id='pagina_integracao' name='pagina_integracao' onChange={formik.handleChange} value={formik.values.pagina_integracao} defaultChecked={formik.values.pagina_integracao} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_tipo_pagamentos' mb='0'>
                      {t("Serviços de pagamentos")}
                    </FormLabel>
                    <Switch id='pagina_tipo_pagamentos' name='pagina_tipo_pagamentos' onChange={formik.handleChange} value={formik.values.pagina_tipo_pagamentos} defaultChecked={formik.values.pagina_tipo_pagamentos} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_servicos' mb='0'>
                      {t("Serviços e produtos")}
                    </FormLabel>
                    <Switch id='pagina_servicos' name='pagina_servicos' onChange={formik.handleChange} value={formik.values.pagina_servicos} defaultChecked={formik.values.pagina_servicos} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_transacoes_percentual' mb='0'>
                      {t("Transações e percentuais")}
                    </FormLabel>
                    <Switch id='pagina_transacoes_percentual' name='pagina_transacoes_percentual' onChange={formik.handleChange} value={formik.values.pagina_transacoes_percentual} defaultChecked={formik.values.pagina_transacoes_percentual} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_permissoes' mb='0'>
                      {t("Permissões")}
                    </FormLabel>
                    <Switch id='pagina_permissoes' name='pagina_permissoes' onChange={formik.handleChange} value={formik.values.pagina_permissoes} defaultChecked={formik.values.pagina_permissoes} /> 
                  </FormControl>
                </Flex>
                <Heading mt={4}>Logs</Heading>
                <Flex direction="column" alignItems="center" justifyContent="space-between">
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_requisicoes' mb='0'>
                      {t("Requisições")}
                    </FormLabel>
                    <Switch id='pagina_requisicoes' name='pagina_requisicoes' onChange={formik.handleChange} value={formik.values.pagina_requisicoes} defaultChecked={formik.values.pagina_requisicoes} /> 
                  </FormControl>
                  <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                    <FormLabel color="gray.500" htmlFor='pagina_req_geral' mb='0'>
                      {t("Geral")}
                    </FormLabel>
                    <Switch id='pagina_req_geral' name='pagina_req_geral' onChange={formik.handleChange} value={formik.values.pagina_req_geral} defaultChecked={formik.values.pagina_req_geral} /> 
                  </FormControl>
                </Flex>
                <Button display="block" leftIcon={<MdSave/>} onChange={formik.handleChange} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Salvar alterações")}</Button>
             
            </Box> 
            </form>
            </Box>}
            {(registo.length == false) && <Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Usuário invalido")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não conseguimos ter informações dos seus dados.")}</Text>
                  
                  <Text as={Link} mt={10} to="/adm" color='blue.500'>{t("Voltar")}</Text>
              </Flex>
            </Center>}
        </Flex>
            :
            <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>}
          </Box>
          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default Referencias