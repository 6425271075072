import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./langs/en.json"
import pt from "./langs/pt.json"
import fr from "./langs/fr.json"
import ar from "./langs/ar.json"
import it from "./langs/it.json"
import es from "./langs/es.json"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: en,
      pt: pt,
      fr: fr,
      ar: ar,
      it: it,
      es: es,
    },
    // lng: "en", // if you're using a language detector, do not define the lng option
    // fallbackLng: "en",
    detection: {
      order: ["localStorage", "navigator"],
      lookupQuerystring: "lng",
      caches: ["localStorage"]
    },
    debug: true,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });