import React, {useState} from 'react'
import { Button,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton, Heading} from '@chakra-ui/react'
import { MdCached} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalRemoveRef({registo, encaminhar}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    return (
      <>
        <Button ml={2} size="sm" leftIcon={<MdCached />} onClick={onOpen} bg='gray.900' color="white" variant='solid'></Button> 
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>Gerar está referencia para EMIS?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Heading mb={5}>{registo.num_referencia}</Heading>
            </ModalBody>
            <ModalFooter>
              <Button onClick={()=>{encaminhar(registo, setOpen)}} bg="blue.400" color="white">Continuar</Button>
              <Button bg="red.400" color="white" ml={2} onClick={onOpen} >Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalRemoveRef
