import { Flex, Text, Heading, Box, OrderedList, ListItem, UnorderedList, Center, Image } from '@chakra-ui/react'
import {   MdOutlineWifiTetheringErrorRounded} from "react-icons/md"
import {Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import LOGO from '../Assets/images/logo/logoi.png'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Pagina404() {

    const navigate =  useNavigate()
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()
    
return (
  
    <Flex flex={4} color="gray.500" p={10}  style={{minHeight:'100vh'}} direction="column">
    
          <Flex flex={4} color="gray.500" px={40}  direction="column">
          <Center mb={3}><Image src={LOGO} width={200} /></Center>
          <Heading textAlign="center">Termos e Condições</Heading>
          <Text textAlign="center">Se tiver dúvidas ou questões, <Link style={{color:"#00F"}} color='blue' to="https://intelize.digital" target='_blank'>fale connosco.</Link></Text>

<Heading my={10}>A sua relação com o INTELIZE PAGAMENTOS</Heading>

<OrderedList>
    <ListItem>O uso por si dos produtos, serviços e aplicações do Intelize Pagamentos (designados conjuntamente neste documento como “Serviços” e excluindo quaisquer serviços a si prestados pelo Intelize Pagamentos mediante um acordo escrito separado) está sujeito aos termos do acordo Legal celebrado entre si e a Intelize, empresa responsável pelo software certificado Intelize Pagamentos. “Nex” significa Nexperience Lda, com sede na Rua da Lionesa, 446, G20, 4465-671 Leça do Balio, Portugal. Este documento explica como é que o acordo é feito e regula alguns dos termos desse acordo.</ListItem>
    <ListItem>Salvo acordo escrito em contrário celebrado com a Intelize, o seu acordo com a Intelize incluirá sempre, no mínimo, os termos e condições regulados neste documento. Estes são adiante designados por “Termos Universais”</ListItem>
    <ListItem>Para além dos Termos Universais, o seu acordo com a Intelize também incluirá os termos de quaisquer Notificações Legais aplicáveis aos Serviços. Todos estes termos são adiante designados por “Termos Adicionais”. Quando os Termos Adicionais forem aplicáveis a um Serviço, estes ser-lhe-ão facultados para ler no âmbito, ou através do uso por si, desse Serviço.</ListItem>
    <ListItem>Os Termos Universais, juntamente com os Termos Adicionais, formam um acordo legalmente vinculativo entre si e a Intelize relativamente ao uso por si dos Serviços. É importante que tire tempo para lê-los atentamente. Conjuntamente, este acordo legal é adiante designado por “Termos”.</ListItem>
    <ListItem>No caso de existir alguma contradição entre o que dizem os Termos Adicionais e o que dizem os Termos Universais, então deverão prevalecer os Termos Adicionais em relação a esse Serviço.</ListItem>
</OrderedList>

<Heading my={10}>Aceitação dos Termos</Heading>

<OrderedList>
    <ListItem>Para utilizar os Serviços, terá primeiro que aceitar os Termos. Não deverá utilizar os Serviços se não aceitar os Termos.</ListItem>
    <ListItem>Pode aceitar os Termos:</ListItem>
        <OrderedList>
            <ListItem>Clicando em aceitar ou concordar com os Termos, na opção que lhe for disponibilizada pela Intelize no user interface para qualquer Serviço; ou</ListItem>
            <ListItem>Utilizando efectivamente os Serviços. Neste caso, reconhece e aceita que a Intelize tomará a sua utilização dos Serviços como uma aceitação dos Termos a partir desse momento em diante.</ListItem>
        </OrderedList>
    <ListItem>Não utilizará os Serviços e não aceitará os Termos se:</ListItem>
        <OrderedList>
            <ListItem>Não tiver idade legal para assinar um contrato vinculativo com a Intelize, ou</ListItem>
            <ListItem>For uma pessoa impedida de receber os Serviços segundo as Leis de Portugal ou outros Países, incluindo o País onde é residente ou o País a partir do qual utiliza os Serviços.</ListItem>
        </OrderedList>
    <ListItem>Antes de continuar, deverá imprimir ou guardar uma cópia local dos Termos Universais para os seus registos.</ListItem>
</OrderedList>

<Heading my={10}>Prestação de Serviços pela Intelize</Heading>

<OrderedList>
    <ListItem>A Intelize está constantemente a inovar para poder oferecer a melhor experiência aos seus utilizadores. Reconhece e aceita que a forma e natureza dos Serviços que a Intelize presta podem mudar ocasionalmente sem o/a avisar previamente.</ListItem>
    <ListItem>No âmbito desta constante inovação, reconhece e aceita que a Intelize possa, discricionariamente, parar (permanente ou temporariamente) de prestar os Serviços (ou qualquer parte dos Serviços) a si ou à generalidade dos utilizadores, sem o/as avisar previamente. Pode parar de usar os serviços a qualquer momento. Não necessita de especificamente informar a Intelize do momento em que deixar de usar os Serviços.</ListItem>
    <ListItem>Reconhece e aceita que, se a Intelize desactivar a sua conta, pode ser impedido de aceder aos Serviços, aos dados da sua conta ou a qualquer ficheiro ou outros conteúdos que estejam na sua conta.</ListItem>
</OrderedList>

<Heading my={10}>A sua utilização dos Serviços</Heading>

<OrderedList>
    <ListItem>Para aceder aos Serviços, poderão ser-lhe solicitadas informações pessoais (como identificação ou dados de contactos) como parte do processo de registo para o Serviço, ou parte do seu uso continuado dos Serviços. Aceita que a informação de registo que dê à Intelize será sempre verídica, correcta e actualizada.</ListItem>
    <ListItem>Aceita não aceder (ou sequer tentar aceder) a qualquer dos Serviços por quaisquer meios que não através da interface que é fornecida pela Intelize, excepto se para tal for especificamente autorizado num acordo separado celebrado entre si e a Intelize.</ListItem>
    <ListItem>Aceita que não levará a cabo qualquer actividade que interfira com ou interrompa os Serviços (ou os servidores e as redes que estejam relacionados com os Serviços).</ListItem>
    <ListItem>Excepto se tiver sido especificamente autorizado a tal num acordo separado celebrado entre si e a Intelize, aceita não reproduzir, duplicar, copiar, vender, comercializar ou revender os Serviços para qualquer efeito.</ListItem>
    <ListItem>Aceita que será o único responsável por (e que a Intelize não tem qualquer responsabilidade perante si ou qualquer terceira parte) por qualquer incumprimento das suas obrigações decorrentes destes Termos e pelas consequências (incluindo qualquer perda ou dano que a Intelize possa sofrer) decorrentes de qualquer um destes incumprimentos.</ListItem>
    <ListItem>Aceita que quando se regista no Intelize Pagamentos, está a dar o seu consentimento para que lhe sejam enviados emails decorrentes da sua utilização do serviço, nomeadamente relativos a notificações, alertas e de informação sobre novas funcionalidades.</ListItem>
    <ListItem>Compreende que os recursos indicados como "ilimitado", correspondem ao nosso compromisso em proporcionar um ambiente onde as restrições convencionais não limitem o crescimento da sua atividade. No entanto, é essencial que essa liberdade seja usada de forma responsável e ética. No entanto, apelamos à sua consciência e ao seu respeito pelos outros utilizadores da plataforma. A utilização responsável dos recursos é fundamental para manter uma experiência positiva para todos os envolvidos. Embora "ilimitado" sugira a ausência de restrições, a nossa intenção é evitar abusos que possam prejudicar o desempenho do sistema ou criar desequilíbrios entre os utilizadores. Caso se verifiquem este tipo de comportamentos, a sua conta poderá ser suspensa temporariamente e/ou poderão ser aplicadas condições específicas à sua subscrição.</ListItem>
</OrderedList>

<Heading my={10}>A segurança das suas passwords e da sua conta</Heading>

<OrderedList>
    <ListItem>Reconhece e aceita que será responsável por manter a confidencialidade das passwords associadas a qualquer conta que use para aceder aos Serviços.</ListItem>
    <ListItem>Consequentemente, aceita que será o único responsável perante a Intelize por todas as actividades que ocorram ao abrigo da sua conta.</ListItem>
    <ListItem>Se tomar conhecimento de qualquer uso não autorizado da sua password ou da sua conta, aceita notificar imediatamente a Intelize.</ListItem>
</OrderedList>


<Heading my={10}>Privacidade e suas informações pessoais</Heading>

<OrderedList>
    <ListItem>Para informação acerca das práticas de protecção de dados no Intelize Pagamentos, por favor leia a política de privacidade em <Link to="https://portal.intelize.ao/politica-de-privacidade/">https://portal.intelize.ao/politica-de-privacidade/</Link>. Esta política explica a forma como a Intelize trata a sua informação pessoal e protege a sua privacidade quando usa os Serviços.</ListItem>
    <ListItem>Acorda com o uso dos seus dados de acordo com a política de privacidade da Intelize</ListItem>
</OrderedList>

<Heading my={10}>Conteúdo nos Serviços</Heading>

<OrderedList>
    <ListItem>Concorda que é o único responsável por (e que a Intelize não é responsável perante si ou perante terceiros por) qualquer Conteúdo que crie, transmita ou visualize enquanto usa os Serviços e pelas consequências dessas suas acções (incluindo qualquer perda ou dano que a Intelize possa sofrer).</ListItem>
    <ListItem>A Intelize declina toda e qualquer responsabilidade pelo teor de quaisquer mensagens ou informações divulgadas pelos utilizadores, resultantes de ou relacionados com o acesso ou a utilização dos Serviços, ou da incapacidade para aceder ou utilizar o mesmo, ou qualquer das suas funções ou funcionalidades, incluindo as relacionadas com qualquer tipo de pesquisa ou descarregamento de qualquer informação, dados, texto, imagens ou outro material acessível através dos Serviços ou de qualquer site acedido a partir dele ou que dê acesso a este Site, ou pelo teor da informação de terceiros na Internet, mesmo no caso de o acesso ser efectuado através do Site.</ListItem>
</OrderedList>

<Heading my={10}>Direitos de Propriedade</Heading>

<OrderedList>
    <ListItem>Reconhece e concorda que a Intelize detém todos os direitos legais, títulos e interesses nos, e relativamente aos, Serviços, incluindo quaisquer direitos de propriedade intelectual que subsistam nos Serviços (quer esses direitos estejam registados ou não, e em qualquer parte do mundo onde esses direitos possam existir). Adicionalmente, reconhece que os Serviços podem conter informações designadas confidenciais pela Intelize e que não revelará sem o consentimento prévio e escrito da Intelize.</ListItem>
    <ListItem>A menos que tenha acordado por escrito em contrário com a Intelize, nada nos Termos lhe dá o direito de usar qualquer dos nomes de marcas, marcas de serviços, logótipos, nomes de domínio e quaisquer outros sinais distintivos da Intelize.</ListItem>
    <ListItem>Se lhe tiver sido dado um direito explícito de uso de qualquer destes sinais distintivos da marca num acordo escrito separado com a Intelize, então concorda que o uso por si dessas características será feito em cumprimento desse acordo, de quaisquer disposições aplicáveis dos Termos, e orientações de uso dos sinais da marca, tal como actualizadas ocasionalmente.</ListItem>
    <ListItem>A Intelize reconhece e aceita que não obtém qualquer direito, título ou interesse seu ao abrigo destes Termos em, ou relativamente a, qualquer Conteúdo que possa submeter, endereçar, transmitir ou visualizar nos, ou através dos, Serviços, incluindo quaisquer direitos de propriedade intelectual que possam subsistir nesse Conteúdo (quer aqueles direitos estejam registados ou não, e em qualquer parte do mundo onde aqueles direitos possam existir). A menos que tenha acordado por escrito em contrário com a Intelize, concorda que é responsável por proteger e executar aqueles direitos e que a Intelize não tem qualquer obrigação de o fazer em sua representação.</ListItem>
    <ListItem>Concorda que não removerá, obscurecerá, ou alterará quaisquer informações de direitos de propriedade (incluindo informações sobre direitos de reprodução e direitos de marcas) que possam estar afixados ou contidos nos Serviços.</ListItem>
    <ListItem>A menos que tenha sido expressamente autorizado por escrito pela Intelize a fazê-lo, reconhece que ao usar os Serviços não irá usar qualquer marca, marca de serviço, denominação comercial, logótipo de qualquer sociedade ou organização de forma a que seja provável ou pretendido que se cause confusão relativamente ao proprietário ou utilizador autorizado dessas marcas, nomes ou logótipos.</ListItem>
</OrderedList>

<Heading my={10}>Cessação da sua relação com a Intelize</Heading>

<OrderedList>
    <ListItem>Os Termos continuarão a aplicar-se até serem cessados por si ou pelo Intelize tal como estabelecido abaixo.</ListItem>
    <ListItem>Se pretender cessar o seu acordo legal com a Intelize, pode fazê-lo:
        <UnorderedList>
            <ListItem>Notificando a Intelize a qualquer tempo e</ListItem>
            <ListItem>Encerrando as suas contas para todos os Serviços por si usados, sempre que a Intelize lhe tenha disponibilizado esta opção.</ListItem>
        </UnorderedList>
    </ListItem>
    <ListItem>A Intelize pode cessar a qualquer altura este acordo legal consigo se:
    <UnorderedList>
            <ListItem>Tiver violado qualquer disposição dos Termos ou tiver agido de forma que claramente mostre que não pretende ou não pode cumprir com as disposições dos Termos; ou</ListItem>
            <ListItem>Tal for exigido à Intelize por lei (por exemplo, quando a disposição de Serviços a si é ou se torna ilegal); ou</ListItem>
            <ListItem>O parceiro com o qual a Intelize lhe ofereceu Serviços tenha terminado a sua relação com a Intelize ou tenha deixado de lhe oferecer os Serviços; ou</ListItem>
            <ListItem>A Intelize esteja a transitar para uma situação em que já não fornece Serviços a utilizadores do País no qual é residente ou no qual utiliza o Serviço; ou</ListItem>
            <ListItem>O fornecimento de Serviços a si prestados pela Intelize já não for, na opinião da Intelize, comercialmente viável.</ListItem>
    </UnorderedList>
    </ListItem>
    <ListItem>Quando estes Termos cessarem, todos os direitos legais, obrigações e responsabilidades que tenham sido beneficiados por si e pela Intelize, que tenham sido sujeitos a (ou que lhe tenham acrescido enquanto os Termos tiverem estado em vigor) ou expressamente devam continuar em vigor indefinidamente, não são afectados por esta cessação, e as disposições destes Termos continuarão a aplicar-se a esses direitos, obrigações e responsabilidades indefinidamente.</ListItem>
</OrderedList>

<Heading my={10}>Exclusão de garantias</Heading>

<OrderedList>
    <ListItem>Os serviços são proporcionados “tal como se apresentam”, conforme "estiverem disponíveis" e a Intelize não dará qualquer garantia expressa ou implícita de qualquer espécie.</ListItem>
    <ListItem>Em particular, a Intelize não garantirá que:
    <UnorderedList>
        <ListItem>O uso dos Serviços vá de encontro às suas exigências;</ListItem>
        <ListItem>O uso dos Serviços será ininterrupto, atempado, seguro ou isento da verificação de erros;</ListItem>
        <ListItem>Qualquer informação obtida em resultado do seu uso dos Serviços será precisa, confiável ou actual, e</ListItem>
        <ListItem>Quaisquer defeitos na operação ou na funcionalidade de qualquer dos Serviços, sejam corrigidos.</ListItem>
    </UnorderedList>
    </ListItem>
    <ListItem>Nenhumas condições, garantias ou outros termos (incluindo quaisquer termos implícitos relativos a qualidade satisfatória, adequação para o fim ou conformidade com a descrição) é aplicável aos Serviços, salvo na extensão com que se encontram expressamente definidos nos Termos.</ListItem>
    <ListItem>Nada nos Termos afectará os direitos estatutários aos quais sempre terá direito como consumidor e que, contratualmente, não pode acordar ceder ou renunciar aos mesmos.</ListItem>
</OrderedList>

<Heading my={10}>Limitação de responsabilidade</Heading>

<OrderedList>
    <ListItem>Nada nestes Termos excluirá ou limitará a responsabilidade da Intelize por perdas as quais não poderão ser legalmente excluídas ou limitadas pela Lei aplicável.</ListItem>
    <ListItem>Sujeito ao provisionamento geral referido acima, a Intelize não será responsável por:
        <UnorderedList>
            <ListItem>Quaisquer perdas, indirectas ou consequenciais em que possa incorrer. Isto incluirá qualquer perda de rendimento (incorrida directa ou indirectamente), qualquer perda relacionada com a boa-fé ou reputação negocial e qualquer perda de informação.</ListItem>
            <ListItem>Qualquer perda ou dano resultante de:
                <UnorderedList>
                    <ListItem>Qualquer expectativa situada ao nível da compleição, precisão, ou existência de qualquer anúncio publicitário, ou em resultado de qualquer relação ou transacção realizada com qualquer anunciante ou patrocinador cujo anúncio surja nos Serviços.</ListItem>
                    <ListItem>Quaisquer alterações que a Intelize possa fazer aos Serviços, ou por qualquer permanente ou temporária cessação do fornecimento dos Serviços (ou quaisquer características no âmbito dos próprios Serviços);</ListItem>
                    <ListItem>A eliminação, a corrupção ou a incapacidade de armazenamento de qualquer Conteúdo ou outra informação resultante de comunicações mantidas ou transmitidas por, ou através, do uso dos Serviços;</ListItem>
                    <ListItem>O não fornecimento, por sua parte, de informação correcta, relativa à conta, à Intelize;</ListItem>
                    <ListItem>A não manutenção, pela sua parte, da segurança e da confidencialidade sobre os dados da sua conta e respectiva password.</ListItem>
                </UnorderedList>
            </ListItem>
        </UnorderedList>
    </ListItem>
    <ListItem>As limitações da Intelize, mencionadas acima, serão aplicáveis independentemente da Intelize para isso ter, ou não ter sido, alertada, ou ainda que devesse ter conhecimento da possibilidade de verificação de tais perdas.</ListItem>
</OrderedList>

<Heading my={10}>Modificações aos Termos</Heading>

<OrderedList>
    <ListItem>A Intelize pode operar modificações aos Termos Universais ou Termos Adicionais periodicamente. Quando estas modificações sejam operadas, a Intelize preparará uma nova cópia dos Termos Universais e quaisquer novos Termos Adicionais ser-lhe-ão disponibilizados no âmbito ou através dos Serviços que lhe estão afectos.</ListItem>
    <ListItem>Compreende e concorda que se utilizar os Serviços em data posterior à modificação dos Termos Universais ou Adicionais, a Intelize tomará o seu uso como uma aceitação dos Termos Universais e Adicionais actualizados.</ListItem>
</OrderedList>

<Heading my={10}>Termos Legais</Heading>

<OrderedList>
    <ListItem>Em determinadas situações, durante a utilização dos Serviços, poderá (em resultado, ou através do uso dos Serviços) utilizar um serviço ou descarregar uma peça de software, ou comprar determinados bens, fornecidos por outra pessoa ou empresa. O uso destes outros serviços, software ou bens poderão ser sujeitos a termos distintos dos presentes, a estabelecer entre a empresa ou pessoa em causa. Neste caso, os presentes Termos não afectam a sua relação legal com essas outras empresas ou indivíduos.</ListItem>
    <ListItem>Os Termos constituem o acordo global sobre matérias legais com a Intelize e regulam o seu uso dos Serviços (excluindo quaisquer serviços que possam eventualmente ser fornecidos pela Intelize no âmbito de um acordo escrito distinto do presente), e substituem na integralidade quaisquer acordos anteriores estabelecidos com a Intelize em relação aos Serviços.</ListItem>
    <ListItem>Concorda que a Intelize pode fornecer-lhe informações, nomeadamente aquelas relacionadas com modificações aos Termos, através de correio eletrónico, correio regular, ou avisos nos próprios Serviços.</ListItem>
    <ListItem>Concorda que se a Intelize não exercer ou utilizar qualquer direito legal ou correctivo contido nos Termos (ou ao qual a Intelize tenha direito nos termos de qualquer Lei eventualmente aplicável), tal não será tido como uma renúncia formal aos direitos da Intelize, mantendo-se tais direitos disponíveis para todos os devidos e legais efeitos.</ListItem>
    <ListItem>Se qualquer tribunal de justiça, tendo jurisdição para decidir sobre esta matéria, determinar que qualquer disposição destes Termos é inválida, então tal disposição será removida dos Termos sem afectar a validade das restantes cláusulas que os compõem, As restantes cláusulas dos Termos permanecerão válidas e eficazes.</ListItem>
    <ListItem>Os Termos, bem como a sua relação com a Intelize no âmbito desses Termos, serão regulados pela Lei Portuguesa. Acorda com a Intelize submeter aos Tribunais Portugueses a resolução de quaisquer questões legais resultantes dos Termos. Sem prejuízo do referido, concorda ainda que a Intelize poderá ainda ter direito a apresentar medidas injuntivas (ou de tipo equivalente de garantia legal urgente) em qualquer jurisdição.</ListItem>
</OrderedList>

<Heading my={10}>Política de Reembolso</Heading>
<Text>No Intelize Pagamentos, procuramos sempre proporcionar a melhor experiência possível aos nossos clientes. No entanto, entendemos que em alguns casos pode ser necessário solicitar um reembolso. Por isso, desenvolvemos uma política de reembolso clara e justa para proteger os interesses dos nossos clientes. A seguir, detalhamos nossa política de reembolso:</Text>

<UnorderedList>
    <ListItem><span style={{fontWeight:700}}>Condições para solicitar um reembolso:</span><br />
    As solicitações de reembolso devem ser feitas para email <span style={{fontWeight:700}}>comercial@intelize.ao</span> dentro do prazo de 30 dias após a compra do serviço.
    <span style={{fontWeight:700}}>Nota:</span> A devolução é restrita a novos clientes e não se aplica a renovações ou upgrades de assinatura.</ListItem>

    <ListItem><span style={{fontWeight:700}}>Processo de solicitação de reembolso:</span><br />
    Para solicitar um reembolso, o cliente deve entrar em contato com o nosso suporte ao cliente através do e-mail <span style={{fontWeight:700}}>comercial@intelize.ao</span> e fornecer as informações necessárias, incluindo o motivo da solicitação. A equipe de suporte ao cliente analisará a solicitação e, se estiver em conformidade com nossa política de reembolso, processará o reembolso.</ListItem>

    <ListItem><span style={{fontWeight:700}}>Forma de reembolso:</span><br />
    Os reembolsos serão processados usando o mesmo método de pagamento utilizado na compra original, a menos que acordado de outra forma com o cliente.</ListItem>

    <ListItem><span style={{fontWeight:700}}>Exceções:</span><br />
    A política de reembolso do Intelize Pagamentos não se aplica a serviços adquiridos de terceiros ou revendedores autorizados. Além disso, não será concedido reembolso em casos de violação dos termos de uso ou contrato de serviço do Intelize Pagamentos.</ListItem>
</UnorderedList> 
    <Text my={10}>No Intelize Pagamentos, estamos comprometidos em fornecer um excelente atendimento ao cliente e resolver eventuais problemas da melhor forma possível. Faremos o possível para garantir que os reembolsos sejam processados de maneira justa e eficiente, sempre em conformidade com nossa política de reembolso.</Text>
         
    <Box textAlign="center">
        <Link to="/" style={{color:"#00F"}}>Voltar ao painel</Link>
    </Box>    

    </Flex>
          <Box textAlign="center">
              <Text mt={70}><b>INTELIZE INVESTIMENTOS</b> * Luanda, Talatona, Atlântico Business, Escritorio nº 28 * suporte@intelize.ao</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
          
    </Flex>
)
}

export default Pagina404