import React, {useState} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton} from '@chakra-ui/react'
import {MdPassword} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalMudarSenha ({mudarSenha}) {
    const [open, setOpen] = useState(false)
    const [senha, setSenha] = useState('')
    const [repetirsenha, setRepetirsenha] = useState('')
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    
    return (
      <>
        <Button ml={2} leftIcon={<MdPassword />}  onClick={onOpen} bg='blue.400' color="white" variant='solid'>Mudar a senha</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Mudar a senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Senha nova</FormLabel>
              <Input type='password' name='senha' required minLength={6} onChange={(evt) => setSenha(evt.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Repetir senha nova</FormLabel>
              <Input type='password' name='repetirsenha' required minLength={6} onChange={(evt) => setRepetirsenha(evt.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{ mudarSenha(senha, repetirsenha); onOpen()}} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
