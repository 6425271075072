import { Flex, Box, Center, Input, Text, Button, useToast, Checkbox} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {useFormik} from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import AXIOS from '../Config/axiosConfig'
import FUNDO from '../Assets/images/global-payment-method-guide-localize-your-ecommerce.webp'
import {useTranslation} from "react-i18next";


function Login() {

    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [loader, setLoader] = useState(false)
    const navigate =  useNavigate()
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    

    const formik = useFormik({
      initialValues:{
        user_name:'',
        user_pass:''
      },
      onSubmit: async (values)=> {
        
        try {
        
        const res =  await AXIOS.post(`afiliados/login`,values)
        
        toast({
          title: t('Autenticação'),
          description: t("Á verificar"),
          status: 'loading',
          duration: 1500,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
        
          setTimeout(()=>{
              toast({
                title: t('Autenticação'),
                description: t("Autenticação feita com sucesso"),
                status: 'success',
                duration: 2000,
                isClosable: true,
              })
          },2000)
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 1000*60*60*24*7;
          now.setTime(expireTime);
          document.cookie = CONFIG.COOKIE_SESSION.CLIENT+JSON.stringify(res.data)+';expires='+now.toGMTString()+';path=/';
          setTimeout(()=>{
          // alert(res.data.mensagem.novo_cliente)
            if(res.data.mensagem.novo_cliente == 1)
                navigate('/mudar-senha-padrao')
              else
                navigate('/')
          },3500)
          
          
      }else{
        setTimeout(()=>{
          toast({
            title: t('Autenticação'),
            description:   t(res.data.mensagem),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        },2000)
      
      }
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
        
      },
    })
    
    useEffect(()=>{
        if(loginValue){
         const value = JSON.parse(loginValue)
         if(value?.mensagem?.numero_entidade){
            navigate('/')
         }
        }
    },[])
    
  return (
    <Flex color='blackAlpha.700'  bg="WHITE"  style={{width:"100%"}}>
        <Box w="50%" bgImage={FUNDO} style={{height:"100vh"}} bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
          <Flex direction="column">
              <Box>
                <Text fontSize={30} fontWeight={900} color="#fff" textAlign="left" p={10} >INTELIZE PAGAMENTOS</Text>
              </Box>
              <Flex style={{height:"80vh"}} direction="column" alignItems="center" justifyContent="center" textAlign="center" p={10} color="white" >
                {/* <Text fontSize={25} fontWeight={400}>{t("Nos vemos outra vez!")} </Text>
                <Text fontSize={50} fontWeight={700}>{t("BEM-VINDO DE VOLTA")}</Text>
                <Text p={5} textAlign="center">
                {t("Estamos aqui para tornar os seus pagamentos ágeis, rápidas, seguros e convenientes. Nosso portal de pagamento oferece soluções modernas para atender às suas necessidades financeiras.!")}
                </Text> */}
              </Flex>
          </Flex>
        </Box>
        <Flex w="50%" bg="white" direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
          <Center>
              <form onSubmit={formik.handleSubmit}>
              <Box color="gray.500" bg='transparent' width="100%" p={10}>
                  <Text fontSize={40} fontWeight={700} color="blue.500" textAlign="center" >{t("Autenticação de afiliados")}</Text>
                  <Text mb={35} p={5} textAlign="center">
                  {t("Seja bem-vindo ao nosso portal. Introduza suas crendências")}
                  </Text>
                  <Flex direction="column" alignItems="center">
                      <Input type='text' rounded={0} placeholder="User name" _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="70%" borderLeftWidth={5} name='user_name'  required bg="gray.100"
                        onChange={formik.handleChange}
                       value={formik.values.user_name} mb={5} />
                       
                      <Input type='password' rounded={0} placeholder="Palavra passe" _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="70%" borderLeftWidth={5} name='user_pass' required bg="gray.100"
                      onChange={formik.handleChange}
                      value={formik.values.user_pass}/>
                      
                      
                    <Button bg='blue.400' rounded={0} mb={5} w="70%" _hover={{backgroundColor:"blue.600"}} color="white" type='submit' p={6} mt={35}>{t("Entrar")}</Button>
                    <Text color="blue.500" fontWeight={700}><Link to="/login" textAlign="center">Gestão</Link></Text>
                  </Flex>
                  <Flex mt={10} justifyContent="center" color="blue.500">
                    <ul>
                      <li onClick={()=> i18n.changeLanguage('pt')} style={{cursor:"pointer"}}>{t("Português")}</li>
                      <li onClick={()=> i18n.changeLanguage('fr')}  style={{cursor:"pointer"}}>{t("Françês")}</li>
                      <li onClick={()=> i18n.changeLanguage('en')}  style={{cursor:"pointer"}}>{t("Inglês")}</li>
                      <li onClick={()=> i18n.changeLanguage('es')}  style={{cursor:"pointer"}}>{t("Espanhol")}</li>
                      <li onClick={()=> i18n.changeLanguage('ar')}  style={{cursor:"pointer"}}>{t("Ârabe")}</li>
                      <li onClick={()=> i18n.changeLanguage('it')}  style={{cursor:"pointer"}}>{t("Italiano")}</li>
                    </ul>
                  </Flex>
              </Box> 
              </form>
          </Center>
          <Box mt={5} color="gray.700" textAlign="center">
            <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
            <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>
    </Flex>
  )
}

export default Login