import { Flex, Text, Heading, Box, Center, Image, ListItem, UnorderedList } from '@chakra-ui/react'
import {   MdOutlineWifiTetheringErrorRounded} from "react-icons/md"
import {Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import LOGO from '../Assets/images/logo/logoi.png'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Pagina404() {

    const navigate =  useNavigate()
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()

return (
  
    <Flex flex={4}  color="gray.500" p={10}  style={{minHeight:'100vh'}} direction="column">
    
          <Flex flex={4} color="gray.500" px={40} direction="column">
          <Center mb={3}><Image src={LOGO} width={200} /></Center>
          <Heading textAlign="center">Política de Privacidade</Heading>
          <Text textAlign="center">Se tiver dúvidas ou questões, <Link style={{color:"#00F"}} color='blue' to="https://intelize.digital" target='_blank'>fale connosco.</Link></Text>

<Text mt={10}>
Nexperience Lda, doravante Vendus ou Nex, garante a proteção de todos os dados pessoais que lhe sejam disponibilizados mediante a utilização deste website, optimizando continuamente o conjunto de procedimentos e técnicas em conformidade com o Regulamento Geral sobre a Protecção de Dados – RGPD – aprovado pelo Regulamento (EU) 2016/679 de 26 de Abril de 2016.
</Text>

<Text mt={10}>
Esta Política pretende dar a conhecer aos utilizadores deste website o modo como a Nex trata os seus dados pessoais recolhidos através da utilização deste website, bem como os que são disponibilizados através do Vendus.
</Text>

<Text mt={10}>
Assim, cada vez que utiliza este website os dados pessoais que transmitir serão tratados em conformidade com a Política de Privacidade vigente a cada momento, pelo que se sugere a consulta regular da presente Política de Privacidade.
</Text>

<Text mt={10}>
Os dados recolhidos pelo site são introduzidos no sistema informático que os trata, onde são processados automaticamente, destinando-se os dados à gestão de serviços do site.
</Text>

<Text mt={10}>
Todos os colaboradores do Vendus estão abrangidos por uma obrigação de confidencialidade relativamente aos dados aos quais tenham acesso no âmbito das operações da respectiva base informática, estando devidamente informados da importância do cumprimento desse dever legal de sigilo e sendo responsáveis pelo cumprimento dessa obrigação de confidencialidade.
</Text>

<Heading mt={10}>1 - Responsável pelo Tratamento</Heading>
<Text>
    O Responsável pelo Tratamento dos seus dados pessoais é a Nexperience Lda. NIPC 509442013, com sede na Rua da Lionesa, 446, Edifício G20, 4465-671 Leça do Balio, Portugal.
</Text>

<Text>
A Nex trata as seguintes categorias de dados pessoais em função de diferentes finalidades:
</Text>
<UnorderedList>
    <ListItem>Dados necessários para a prestação de serviços, execução de contrato e cumprimento de obrigações legais: Dados de identificação pessoal – nome; endereço de correio electrónico; NIF, morada.</ListItem>
    <ListItem>Dados decorrentes de reclamações/pedidos de exercício de direitos: Dados de identificação pessoal – Nome; morada, documento legal de identificação.</ListItem>
</UnorderedList>


<Heading mt={10}>2 - Finalidades do Tratamento</Heading>
<Text>
A Nex utiliza ou poderá utilizar os seus dados pessoais no âmbito das seguintes finalidades:
</Text>

<UnorderedList>
    <ListItem>Prestação de serviços e execução de contrato em que seja parte;</ListItem>
    <ListItem>Cumprimento de obrigações legais a que a Nex está vinculada;</ListItem>
    <ListItem>Envio de comunicações de marketing directo;</ListItem>
    <ListItem>Gestão de pedidos de contacto, informação e reclamações;</ListItem>
    <ListItem>Antes de lhe ser enviada a primeira comunicação de marketing, ser-lhe-á solicitado o seu consentimento expresso para o envio deste tipo de comunicações.</ListItem>
</UnorderedList>

<Heading mt={10}>3 - Licitude do Tratamento</Heading>
<Text>
A Nex apenas tratará os seus dados pessoais quando existir um fundamento de licitude que legitime o respetivo tratamento, designadamente:
</Text>

<UnorderedList>
<ListItem>Diligências pré-contratuais e execução de um contrato;</ListItem>
<ListItem>Obrigação legal;</ListItem>
<ListItem>Interesse público ou exercício da autoridade pública;</ListItem>
<ListItem>Interesse legítimo desde que tais interesses não se sobreponham aos seus direitos e liberdades fundamentais;</ListItem>
<ListItem>Consentimento;</ListItem>
<ListItem>Defesa dos seus interesses vitais ou de terceiro.</ListItem>
</UnorderedList>

<Heading mt={10}>4 - Prazo de Conservação dos Dados</Heading>
<Text>
A Nex apenas conservará os seus dados pessoais durante o tempo estritamente necessário para atingir a finalidade para a qual os mesmos foram recolhidos. A Nex pode ainda conservar alguns dos seus dados pessoais, na medida em que tal seja necessário para cumprir com as suas obrigações legais, bem como para gerir ou fazer valer os seus direitos. Findos os prazos de conservação acima referidos, os dados pessoais serão eliminados permanentemente das bases de dados da Nex.
</Text>

<Heading mt={10}>5 - Partilha de Dados</Heading>
<Text>
A Nex apenas recorrerá a entidades terceiras na medida do estritamente necessário para prestar o desenvolvimento da sua actividade, assegurando que as mesmas cumprirão as suas obrigações em matéria de privacidade e proteção de dados pessoais, através da celebração de um Acordo de Subcontratação por forma a regular todos os aspetos inerentes às atividades de tratamento dos seus dados pessoais.
</Text>
<Text>
A Nex não efectua transferências internacionais dos seus dados para um país terceiro ou organização internacional que se encontre fora da União Europeia.
</Text>

<Heading mt={10}>6 - Direitos dos Titulares dos Dados</Heading>
<Text mb={5}>Nos termos da legislação vigente em matéria de proteção dos dados pessoais, informamos que são garantidos ao titular dos dados:</Text>

<UnorderedList>
    <ListItem>Direito de ser informado: no momento da recolha dos dados pessoais:
        <UnorderedList>
            <ListItem>Dos contactos do responsável pelo tratamento;</ListItem>
            <ListItem>Da(s) finalidade(s) de tratamento;</ListItem>
            <ListItem>Do prazo de conservação;</ListItem>
            <ListItem>Dos seus direitos enquanto titulares de dados pessoais;</ListItem>
            <ListItem>Da possibilidade de recurso à autoridade de controlo para apresentação de queixa (e respectivos contactos);</ListItem>
            <ListItem>Da (eventual) existência de decisões automatizadas e/ou profiling;</ListItem>
            <ListItem>Saber onde estão armazenados, e como são tratados os seus dados;</ListItem>
            <ListItem>Ser informado de como a Nex cumpre com suas as obrigações de proteção de dados.</ListItem>
        </UnorderedList>
    </ListItem>
    <ListItem>Direito de acesso: o titular pode aceder aos seus dados pessoais; confirmar a existência ou não de tratamento dos seus dados e requerer uma cópia dos mesmos.</ListItem>
    <ListItem>Direito de oposição ao tratamento: o titular tem o direito de se opor ao tratamento por motivos relacionados com a sua situação particular.</ListItem>
    <ListItem>Direito de rectificação: o titular tem o direito de rectificar os seus dados pessoais se os mesmos estiverem incorrectos, desactualizados ou incompletos.</ListItem>
    <ListItem>Direito ao apagamento: o titular dos dados pode solicitar, a qualquer momento, a eliminação de todos os seus dados pessoais recolhidos pelo Vendus.</ListItem>
    <ListItem>Direito de Portabilidade: o titular dos dados tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido, num formato estruturado, de uso corrente e de leitura automática; e o direito de transmitir esses dados a outro Responsável pelo tratamento sempre que:
        <UnorderedList>
            <ListItem>O tratamento se basear no consentimento ou num contrato; e</ListItem>
            <ListItem>O tratamento for realizado por meios automatizados.</ListItem>
        </UnorderedList>
    </ListItem>
    <ListItem>Direito à limitação do tratamento: em certas situações, que devem ser ponderadas caso a caso, o titular tem o direito a solicitar a restrição do tratamento dos seus dados.</ListItem>
</UnorderedList>

<Text my={5}>
Poderá ainda apresentar queixa junto da Comissão Nacional de Proteção de Dados (CNPD) e/ou obter reparação e/ou indemnização, se considerar que o tratamento realizado viola os seus direitos e/ou a legislação em matéria de proteção de dados pessoais e de privacidade aplicáveis.
</Text>

<Heading mt={10}>7 - Armazenamento e Segurança dos Dados</Heading>
<Text mb={5}>A Nex utiliza um conjunto de tecnologias e procedimentos de segurança adequados para proteger os seus dados pessoais do acesso, uso ou divulgação não autorizados, tais como: controlo de acessos; registo de atividade dos utilizadores e testes de penetração.</Text>

<Text mb={5}>
Os seus dados pessoais poderão ser armazenados para alojamento e backup em servidores localizados na União Europeia, em datacenters protegidos por equipas de segurança, num ambiente seguro, com acesso restrito e controlado, video-vigilância 24 horas por dia, e cuja gestão está a cargo da empresa Amazon Web Services, com as quais a Nex tem celebrados Acordos de Subcontratação.
</Text>

<Text mb={5}>
A Nex guarda os seus dados pessoais em servidores que se encontram em ambiente seguro, protegidos de acessos não autorizados.
</Text>

<Text mb={5}>
Para que possamos proteger os dados dos nossos utilizadores contra as ameaças que existem online e que podem, eventualmente, comprometer os dados que utilizamos, adotamos medidas de proteção que são revistas com a regularidade necessária de forma a manter a segurança da navegação.
</Text>

<Text mb={5}>
Solicitamos, também, aos nossos utilizadores que protejam os seus dispositivos contra vírus ou outras ameaças que possam comprometer a sua segurança, através de programas específicos para o efeito.
</Text>

<Text mb={5}>
O tratamento de dados e a recolha de informações do utilizador está limitada a um número restrito de pessoas. Deste modo, podemos salvaguardar acessos indevidos aos sistema e comprometimento de dados pessoais.
</Text>

<Text mb={5}>
Compete, porém, a todos os utilizadores assegurar que os equipamentos que utilizam estão protegidos contra softwares nocivos, vírus informáticos e worms, bem como garantir a configuração segura do seu programa de navegação.
</Text>

<span style={{fontWeight:700}}>Política de Privacidade - Versão 2.0 – 16 de Julho de 2024</span>

        <Box mt={10} textAlign="center">
            <Link to="/" style={{color:"#00F"}}>Voltar ao painel</Link>
        </Box>    

        </Flex>
          <Box textAlign="center">
              <Text mt={70}><b>INTELIZE INVESTIMENTOS</b> * Luanda, Talatona, Atlântico Business, Escritorio nº 28 * suporte@intelize.ao</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
          
    </Flex>
    
)
}

export default Pagina404