import React, {useState, useEffect, useRef} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal, Flex,Divider,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Heading,
    HStack,
    PinInput,
    PinInputField,
    Alert,
    Text,
    Select,
    Box,
    ModalCloseButton,
  useToast} from '@chakra-ui/react'
import {MdDelete, MdEdit, MdError, MdMonetizationOn, MdMoney, MdMonitor, MdPassword, MdSend} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'


function ModalMudarSenha ({entidade, axiosReferenciass}) {
    const [open, setOpen] = useState(false)
    const [user, setUser] = useState('1')    
    const [remetente, setRemetente] = useState({})
    const [valor1, setValor1] = useState("")
    const [userCod, setUserCod] = useState({texto1:'',texto2:'',texto3:'',texto4:'',texto5:'',texto6:'',texto7:'',texto8:'',texto9:'',texto10:''})
    const {t, i18n} = useTranslation()
    const toast = useToast()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    
    


    const onOpen = () => {
            setOpen(!open)
    }
 
  async function axiosReferenciasRemetente(entidade){
    const referencia = await AXIOS.get(`/clientes/sms-remetente/entidade/${entidade}`);
    
    if(referencia.data.status === "erro")
        setRemetente([])
    else{
      if(referencia.data.mensagem?.id_api_rementente)
        setRemetente(referencia.data.mensagem)
      else
        setRemetente({}) 
    }
        
  }

  const actualizarEstadoRemetente = async () => {
    toast({
      title: 'Configurar remetente',
      description: "Á carregar o pedido",
      status: 'loading',
      duration: 1000,
      isClosable: true,
    })

    const res =  await AXIOS.get(`/clientes/sms-remetente/${remetente?.id_api_rementente}`)

      if(res.data.status === "sucesso"){
        setTimeout(()=>{
          remetente.estado_remetente = res.data.mensagem.estado_remetente
              toast({
                title: 'Configurar remetente',
                description: "Actualização feita",
                status: 'success',
                duration: 2000,
                isClosable: true,
              })
              axiosReferenciass();
            },3500)
      }else{
          setTimeout(()=>{
            toast({
              title: 'Configurar remetente',
              description: res.data.mensagem,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          },5000)
      }
  }
  
  const formik = useFormik({
    initialValues:{
      nome_rementente:'',
      campo_envio:'opcional_3',
      entidade_remetente: ''
    },
    onSubmit: async (values)=> {

      values.entidade_remetente =  entidade
    
    
      const res =  await AXIOS.post(`/clientes/sms-remetente`, values) 

      toast({
        title: 'Configurar remetente',
        description: "Á carregar o pedido",
        status: 'loading',
        duration: 3000,
        isClosable: true,
      })
      
      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          formik.resetForm()
        },1000)
        
        
        
        setTimeout(()=>{
          toast({
            title: 'Configurar remetente',
            description: res.data.mensagem,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        },3500)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Configurar remetente',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },5000)
      }
    },
  })

    
    
  useEffect(()=>{
    axiosReferenciasRemetente(entidade)
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.numero_entidade){
      setUser(value.mensagem)
    }
    
},[])
    
    
    return (
      <>
      <Button  bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={onOpen}>
      Nome de Rementente
      </Button>
        
  
        <Modal isOpen={open} onClose={onOpen} size="2xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Configurar nome de remetente</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
          <Flex justifyContent="space-between">
              <Box>
                  <form onSubmit={formik.handleSubmit}>
                  <FormControl mt={4}>
                      <FormLabel>{t("Remetente")}</FormLabel>
                        <Input  rows={10} name='nome_rementente' isDisabled={(remetente?.estado_remetente !== undefined)} minLength={3} maxLength={11} onChange={formik.handleChange} value={formik.values.nome_rementente} />
                  </FormControl>
                  <FormControl mt={2}>
                    <FormLabel>Campo do Terminal telefonico</FormLabel>
                      <Select isDisabled={(remetente?.estado_remetente !== undefined)} required name='campo_envio' onChange={formik.handleChange} value={formik.values.campo_envio}>
                        <option value="opcional_3">Campo 3</option>
                        <option value="opcional_2">Campo 2</option>
                        <option value="opcional_1">Campo 1</option>
                      </Select>
                  </FormControl>
                  </form>
              </Box>
              {remetente?.estado_remetente ? 
              <Box mt={5}>
                <Text>Remetente: <b>{remetente?.nome_rementente}</b></Text>
                <Text>Status para uso: <b>{remetente?.estado_remetente == "PENDING" ? <span style={{color:"red"}}>{remetente?.estado_remetente}</span> : <span style={{color:"green"}}>{remetente?.estado_remetente}</span>}</b></Text>
                <Text>Campo do terminal: <b>{remetente?.campo_envio.toUpperCase().replace('_',' ')}</b></Text>
                <Button onClick={actualizarEstadoRemetente} colorScheme='orange' mt={3}>Actualizar</Button>
              </Box> : <Flex justifyContent="center" align="center"><MdError color='red' size={150} /></Flex>}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={(remetente?.estado_remetente !== undefined)} onClick={()=>{formik.submitForm() }} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
