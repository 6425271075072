import { Flex, Box, Text, Center} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader, BotaoTop } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { MdError } from 'react-icons/md'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  
  async function axiosReferencias(){
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/usuarios/`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }
        
    console.log(referencia.data.mensagem)
    setLoader(true)
        
}
  useEffect(()=>{
     
    axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let permissao = user?.pagina_sms == "true";

  if(user?.pagina_sms) permissao = user?.pagina_sms == "true"
  else permissao = user.todas_areas ? true : false;
  
return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
          {
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Comunicar por SMS</Text>
        {
        loader
        ?
        <Box shadow="sm" rounded={5} bg="white" p={10} >
          BREVEMENTE
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
          </Box>

          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes