import { Flex, Box, FormControl, FormLabel, Center, Input, Text, Button, Spinner, useToast, Checkbox,Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Image,
  Heading,} from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import {useFormik} from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import LOGO from '../Assets/images/logo/logoi.png'
import userEvent from '@testing-library/user-event'
import { useTranslation } from 'react-i18next'
import { MdError } from 'react-icons/md'

function Login() {

    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [loader, setLoader] = useState(false)
    const [texto, setTexto] = useState({bg:"error", texto:"Senha fraca"})
    const navigate =  useNavigate()
    const toast = useToast()
    const senhaStrong =  useRef(null)
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()
    
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    
    function StrengthChecker(PasswordParameter) {
    
    try {
      if(strongPassword.test(PasswordParameter)) {
        setTexto({bg:"success", texto:"Senha forte"})
      } else if(mediumPassword.test(PasswordParameter)) {
        setTexto({bg:"warning", texto:"Senha rasoável"})
      } else {
        setTexto({bg:"error", texto:"Senha fraca"})
      }
      
    }catch(e){
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
    }
  }
  
  const clearLogout = function(){
  try {
    document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0'
    navigate('/login')
  }catch(e) {
    toast({
      title: t('Alerta'),
      description: t("Não conseguimos ter comunicação! Verifique sua internet"),
      status: 'error',
    })
    }
  }

    const formik = useFormik({
      initialValues:{
        senhaNova:'',
        senha:'',
        novo_cliente:'0'
      },
      onSubmit: async (values)=> {
        
        try {
        if(values.senha === values.senhaNova){
          if((texto.bg === "warning") || (texto.bg === "success")){
                delete values.senhaNova
                const res =  await AXIOS.patch(`/clientes/${user.id_clientes}`,values)
                console.log(res.data)
                
                if(res.data.status === "sucesso"){
                
                  toast({
                    title: t('Actualização de senha'),
                    description: t("Actualização feita com sucesso"),
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  })
                  
                  setTimeout(()=>{
                    clearLogout()
                  },3000)
                  
                  
              }else{
                toast({
                  title: t('Actualização de senha'),
                  description:  t(res.data.mensagem),
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                })
              }          
          }else{
            toast({
              title: t('Aviso'),
              description:  t("Sua senha não pode ser fraca"),
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
                
        }else{
          toast({
            title: t('Aviso'),
            description:  t("Suas senha não são correspondentes!"),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
        
        
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
        
      },

    })
    
    
    
    useEffect(()=>{
        if(loginValue){
         const value = JSON.parse(loginValue)
         if(value?.mensagem?.numero_entidade){
          setUser(value.mensagem)
         }else{
               navigate('/login')
             }
         
        }
    },[])
    
    let permissao = user?.pagina_perfil == "true";

    if(user?.pagina_perfil) permissao = user?.pagina_perfil == "true"
    else permissao = user.numero_entidade

  return (
    <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" style={{minHeight:'100vh'}} direction="column" >
        {permissao ? <Box w="100%" bg="white" h="100%">
          <Center>
              <form onSubmit={formik.handleSubmit}>
              <Box color="gray.500" bg='transparent' p={10}>
                  <Center mb={1}><Image src={LOGO} width={200} /></Center>
                  <Text fontSize={40} fontWeight={700} color="blue.500" mb={10} textAlign="center" >{t("Actualização de senha")}</Text>
                  
                  <Flex direction="column" alignItems="center">
                      <Input type='password' rounded={0} placeholder={t("Palavra pass nova")} _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="100%" borderLeftWidth={5} name='senha'  required bg="gray.100"
                        onChange={evt => {formik.handleChange(evt);StrengthChecker(evt.target.value)}}
                       value={formik.values.senha} mb={5} />
                       
                      <Input type='password' rounded={0} placeholder={t("Repita palavra passe nova")} _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="100%" borderLeftWidth={5} name='senhaNova' required bg="gray.100"
                      onChange={formik.handleChange}
                      value={formik.values.senhaNova}/>
                      
                    <Button bg='blue.400' rounded={0} w="100%" _hover={{backgroundColor:"blue.600"}} color="white" type='submit' mb={5} p={6} mt={35}>{t("Mudar")}</Button>
                    {(formik.values.senha.length > 0) && <Alert w='100%' mb={2} status={texto.bg} variant="left-accent">
                                                            <AlertIcon />
                                                           {t(texto.texto)}
                                                          </Alert>}
                    <Link to="/minha-conta" color='blue.500'>{t("Voltar")}</Link>
                  </Flex>
              </Box> 
              </form>
          </Center>
        </Box>
        :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
        <Box>
              <Text mt={70}><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
    </Flex>
  )
}

export default Login