import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast } from '@chakra-ui/react'
import {  MdAdd, MdPeople} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export default function PlacementExample({axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const [eventos, setEVentos] = useState([])
    const [tipoSenha, setTipoSenha] = useState('password')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    const navigate =  useNavigate()


    const verSenha = () => {

      if(tipoSenha == "password") {
        setTipoSenha('text')
      }else{
        setTipoSenha('password')
      }
    }

    const gruposEfetchAsync = async () =>{
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      try {
        const res = await AXIOS.get(`/afiliados/identificacao/afiliados/clientes/entidade/${entidade}`)
        if (res.data.status == "sucesso"){
            setEVentos(res.data.mensagem)
        }else {
          setEVentos([])
        }
      }catch (error) {
        navigate('/sem-comunicacao')
      }
        
    } 
    

    const formik = useFormik({
      initialValues:{
        nome_afiliado:'',
        email_afiliado: '',
        user_name: '',
        user_pass: '',
        grupo_identificacao:''
      },
      onSubmit: async (values)=> {

        values.cliente_id = user.id_clientes
        
        const res =   await AXIOS.post(`/afiliados`,values)
        
        toast({
          title: 'Criação de afiliados',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){          
          
          
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de afiliados',
              description: res.data.mensagem,
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferencias()
          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de afiliados',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    const onClose = function(){
        setIsOpen(false)
    }

    useEffect(()=>{
      const value = JSON.parse(loginValue)
      gruposEfetchAsync();
      if(value?.mensagem?.numero_entidade){
        setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          Cadastrar
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Cadastrar afiliados</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                  <FormControl>
                      <FormLabel>{t("Grupo de indetificação")}</FormLabel>
                      <Flex direction="row" alignItems="center">
                        <Select required name='grupo_identificacao' onChange={formik.handleChange} value={formik.values.grupo_identificacao}>
                        <option selected value="">Selecione</option>
                        {eventos.map(fl => <option value={fl.id_identificacao_afiliados}>{fl.nome_identificacao}</option>)}
                      </Select>
                      </Flex>
                  </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>Nome completo</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='nome_afiliado' onChange={formik.handleChange} value={formik.values.nome_afiliado} />
                         </Flex>
                    </FormControl>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl mt={2}>
                          <FormLabel>Username</FormLabel>
                          <Input type='text' name='user_name' onChange={formik.handleChange} required value={formik.values.user_name} />
                      </FormControl>
                      <FormControl mt={2} ml={2}>
                          <FormLabel>Email</FormLabel>
                          <Input type='email' name='email_afiliado'  onChange={formik.handleChange} value={formik.values.email_afiliado} />
                      </FormControl>
                    </Flex>
                    <FormControl mt={2}>
                        <FormLabel>Senha</FormLabel>
                        <Flex>
                          <Input type={tipoSenha} name='user_pass' required onChange={formik.handleChange} value={formik.values.user_pass} />
                          <Button ml={1} colorScheme='orange' onClick={verSenha} leftIcon={tipoSenha == "password" ? <FaEye /> : <FaEyeSlash />} />
                        </Flex>
                    </FormControl>
                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Salvar</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }