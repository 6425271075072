import React, {useState} from 'react'
import { Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Heading } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

function ModalRemoveRef({registo, excluirRef}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    } 
    
    return (
      <>
        <Button ml={2} size="sm" leftIcon={<MdDelete />} onClick={onOpen} colorScheme='red' variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="xs" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>{t("Pretende excluir?")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Heading mb={5}>{registo.num_referencia}</Heading>
            </ModalBody>
            <ModalFooter>
              <Button onClick={()=>{excluirRef(registo);onOpen()}} bg="blue.400" color="white">{t("Continuar")}</Button>
              <Button bg="red.400" color="white" ml={2} onClick={onOpen} >{t("Cancelar")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalRemoveRef
