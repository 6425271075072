import React from 'react'
import './css/loader.css'

function Loader() {
  return (
    // <div className="loader-page">
    //     <h2 className='h2loader'>Intelize pagamentos</h2>
    //     <div className="loader">
    //       <div className="outer"></div>
    //       <div className="middle"></div>
    //       <div className="inner"></div>
    //     </div>
    // </div>

    <div className="loader-page">
    <h2 className='h2loader'>Intelize pagamentos</h2>
    <span className="loader"></span>
    </div>
  )
}

export default Loader
