import { Flex, Text, Button, Heading, Box } from '@chakra-ui/react'
import {  MdError,} from "react-icons/md"
import {Link } from 'react-router-dom'
import CONFIG from '../../Config'


function Pagina404() {
  
return (
    <Flex direction="column" justifyContent="space-between" style={{minHeight:'100vh'}}>
        <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" style={{minHeight:'100vh'}} direction="column">
              <MdError size={100} color="red"/>
              <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={10}>Pagina não encontrada</Heading>
              <Text>A pagina ou o recurso que pretende aceder não se encontra em nosso servidor. Por favor click no botão abaixo para iniciar o serviço </Text>
              <Button as={Link} to="/adm" bg="gray.700" color="white" mt={3}>Voltar ao inicio</Button>
        </Flex>
        <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>Todos direitos reservados &copy; {new Date().getFullYear()}</Text>
        </Box>
    </Flex>
)
}

export default Pagina404