import {
  Flex,
  Box,
  Center,
  Input,
  Text,
  Button,
  useToast,
  Alert,
  AlertIcon,
  Image
} from "@chakra-ui/react";
import AXIOS from '../Config/axiosConfig'
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import CONFIG from "../Config";
import LOGO from "../Assets/images/logo/logoi.png";
import { useTranslation } from "react-i18next";

function Login() {
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const ENTITY_COD = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.ID_CODE_ENTITY))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.ID_CODE_ENTITY))
        .split(CONFIG.COOKIE_SESSION.ID_CODE_ENTITY)[1]
    : "";
  const [loader, setLoader] = useState(false);
  const [texto, setTexto] = useState({ bg: "error", texto: "Senha fraca" });
  const navigate = useNavigate();
  const toast = useToast();
  const senhaStrong = useRef(null);
  const [user, setUser] = useState("1");
  const {t, i18n} = useTranslation()

  const strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  const mediumPassword = new RegExp(
    "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  );

  function StrengthChecker(PasswordParameter) {
    try {
      if (strongPassword.test(PasswordParameter)) {
        setTexto({ bg: "success", texto: "Senha forte" });
      } else if (mediumPassword.test(PasswordParameter)) {
        setTexto({ bg: "warning", texto: "Senha rasoável" });
      } else {
        setTexto({ bg: "error", texto: "Senha fraca" });
      }
    } catch (e) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  }

  const clearLogout = function () {
    try {
      document.cookie = CONFIG.COOKIE_SESSION.ID_CODE_ENTITY + "=; Max-Age=0";
      navigate("/login");
    } catch (e) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      senhaNova: "",
      senha: "",
      novo_cliente: "0"
    },
    onSubmit: async (values) => {
      try {
        if (values.senha === values.senhaNova) {
          if (texto.bg === "warning" || texto.bg === "success") {
            delete values.senhaNova;
            const res = await AXIOS.patch(
              `/clientes/${ENTITY_COD}`,
              values
            );
            console.log(res.data);

            if (res.data.status === "sucesso") {
              toast({
                title: t("Actualização de senha"),
                description: t("Actualização feita com sucesso"),
                status: "success",
                duration: 2000,
                isClosable: true
              });

              setTimeout(() => {
                clearLogout();
              }, 3000);
            } else {
              toast({
                title: t("Actualização de senha"),
                description: t(res.data.mensagem),
                status: "error",
                duration: 5000,
                isClosable: true
              });
            }
          } else {
            toast({
              title: t("Aviso"),
              description: t("Sua senha não pode ser fraca"),
              status: "error",
              duration: 2000,
              isClosable: true
            });
          }
        } else {
          toast({
            title: t("Aviso"),
            description: t("Suas senha não são correspondentes!"),
            status: "error",
            duration: 2000,
            isClosable: true
          });
        }
      } catch (error) {
        toast({
          title: t("Alerta"),
          description:
            t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: "error"
        });
      }
    }
  });

  useEffect(() => {
    if (loginValue) {
      const value = JSON.parse(loginValue);
      if (value?.mensagem?.numero_entidade) {
        navigate("/");
      } else if (!ENTITY_COD) {
        navigate("/login");
      }
    }
  }, []);

  return (
    <Flex
      flex={4}
      textAlign="center"
      color="gray.500"
      p={10}
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
      direction="column"
    >
      <Box w="50%" bg="white" h="100%">
        <Center>
          <form onSubmit={formik.handleSubmit}>
            <Box color="gray.500" bg="transparent" p={10}>
              <Center mb={1}>
                <Image src={LOGO} width={200} />
              </Center>
              <Text
                fontSize={40}
                fontWeight={700}
                color="blue.500"
                mb={10}
                textAlign="center"
              >
                {t("Nova senha para o seu perfil")}
              </Text>
              <Text mb={1} p={5} textAlign="center">
                {t("Esta pagina so é apresentada ao passar em nosso codigo de segurança da INTELIZE PAGAMENTOS")}
                <br />
                {t("Actualize sua senha para poder aceder as suas informações")}
              </Text>

              <Flex direction="column" alignItems="center">
                <Input
                  type="password"
                  rounded={0}
                  placeholder={t("Palavra pass nova")}
                  _placeholder={{ color: "gray.300" }}
                  borderLeftColor="blue.500"
                  p={6}
                  w="70%"
                  borderLeftWidth={5}
                  name="senha"
                  required
                  bg="gray.100"
                  onChange={(evt) => {
                    formik.handleChange(evt);
                    StrengthChecker(evt.target.value);
                  }}
                  value={formik.values.senha}
                  mb={5}
                />

                <Input
                  type="password"
                  rounded={0}
                  placeholder={t("Repita palavra passe nova")}
                  _placeholder={{ color: "gray.300" }}
                  borderLeftColor="blue.500"
                  p={6}
                  w="70%"
                  borderLeftWidth={5}
                  name="senhaNova"
                  required
                  bg="gray.100"
                  onChange={formik.handleChange}
                  value={formik.values.senhaNova}
                />

                <Button
                  bg="blue.400"
                  rounded={0}
                  w="70%"
                  _hover={{ backgroundColor: "blue.600" }}
                  color="white"
                  type="submit"
                  mb={5}
                  p={6}
                  mt={35}
                >
                  {t("Redifinir senha")}
                </Button>
                {formik.values.senha.length > 0 && (
                  <Alert w="70%" mb={2} status={texto.bg} variant="left-accent">
                    <AlertIcon />
                    {t(texto.texto)}
                  </Alert>
                )}
                <Link to="/login" color="blue.500">
                  {t("Cancelar")}
                </Link>
              </Flex>
              <Flex mt={10} justifyContent="center" color="blue.500">
                    <ul>
                      <li onClick={()=> i18n.changeLanguage('pt')} style={{cursor:"pointer"}}>{t("Português")}</li>
                      <li onClick={()=> i18n.changeLanguage('fr')}  style={{cursor:"pointer"}}>{t("Françês")}</li>
                      <li onClick={()=> i18n.changeLanguage('en')}  style={{cursor:"pointer"}}>{t("Inglês")}</li>
                      <li onClick={()=> i18n.changeLanguage('es')}  style={{cursor:"pointer"}}>{t("Espanhol")}</li>
                      <li onClick={()=> i18n.changeLanguage('ar')}  style={{cursor:"pointer"}}>{t("Ârabe")}</li>
                      <li onClick={()=> i18n.changeLanguage('it')}  style={{cursor:"pointer"}}>{t("Italiano")}</li>
                    </ul>
                  </Flex>
            </Box>
          </form>
        </Center>
      </Box>
      <Box>
        <Text mt={70}>
          <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
          {CONFIG.COMPANY.EMAILS[0]}
        </Text>
        <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
      </Box>
    </Flex>
  );
}

export default Login;
