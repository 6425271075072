import { Flex, Box, FormControl, FormLabel, Center, Input, Text, Button, Spinner, useToast, Heading} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect} from 'react'
import {useFormik} from 'formik'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import FUNDO from '../../Assets/images/secure-online-payment-internet-banking-via-credit-card-mobile-scaled.webp'

function Login() {

    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const navigate =  useNavigate()
    const toast = useToast()
    
    const formik = useFormik({
      initialValues:{
        email_:'',
        senha_:''
      },
      onSubmit: async (values)=> {
        
        const res =  await AXIOS.post(`/usuarios/login`,values)        
            
        toast({
          title: 'Autenticação',
          description: "Á verificar",
          status: 'loading',
          duration: 1500,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){

          setTimeout(()=>{
            toast({
              title: 'Autenticação',
              description: "Autenticação feita com sucesso",
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          },2000)
          
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 1000*60*60*24*7;
            now.setTime(expireTime);
            document.cookie = CONFIG.COOKIE_SESSION.ADM+JSON.stringify(res.data)+';expires='+now.toGMTString()+';path=/adm';
            setTimeout(()=>{
              navigate('/adm')
            },3500)
        }else{

          setTimeout(()=>{
            toast({
              title: 'Autenticação',
              description: res.data.mensagem,
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          },2000)
        
        }
        
      },
    })
    
    useEffect(()=>{
        if(loginValue){
         const value = JSON.parse(loginValue)
         if(value?.mensagem?.id_usuarios){
            navigate('/adm')
         }
        }
    },[])
    
  return (
    <Flex color='blackAlpha.700' justifyContent="center" align="center" bg="gray.700" style={{height:"100vh"}} bgImage={FUNDO} bgPosition="center" bgRepeat="no-repeat" bgSize="100%">
        <Center >
            <form onSubmit={formik.handleSubmit}>
            <Box color="white" bg='rgba(255, 255, 255, .25)' p={20} width={500}  rounded={20} boxShadow='2xl' >
                <Heading fontSize={45} fontWeight={900} textAlign="center" color="white" mb={1}>INTELIZE PAGAMENTOS</Heading>
                <Text fontSize={20} fontWeight={500} textAlign="center" color="white" mb={10}>Autenticação do portal</Text>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type='email' name='email_'  required
                      onChange={formik.handleChange}
                     value={formik.values.email_} />
                </FormControl>
                <FormControl mt={2}>
                    <FormLabel>Palavra passe</FormLabel>
                    <Input type='password' name='senha_' required
                    onChange={formik.handleChange}
                    value={formik.values.senha_}/>
                </FormControl>
                <Button bg='blue.500' _hover={{backgroundColor: "blue.700"}} color="white" type='submit' mt={5}>Entrar</Button>
             
            </Box> 
            </form>
        </Center>
    </Flex>
  )
}

export default Login