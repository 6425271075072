import { Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Center, Input, useToast, Button, Select,NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  } from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import { ModalViewPagamento, SideBarMenu, NavBar, Loader, SideBarMenuAdm, NavBarAdm, BotaoTop, ModalViewPagamentoIncompletos } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { MdArrowBack, MdArrowForward, MdDoneAll, MdError, MdOutlineRemoveDone, MdUpgrade} from "react-icons/md"
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'


function Pagamentos() {

  const [referencias, setReferencias] = useState([])
  const [entidades, setEntidades] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  const {id} = useParams();
  const {t, i18n} = useTranslation()
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')
  
  async function axiosReferencias(){
  
  try {
    setLoader(false)
    const entidade = id
    const referencia = await AXIOS.get(`/pagamentos/pagamento/nao/processados/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
    const clientes = await AXIOS.get(`/clientes`)

    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)  
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite)
      }

    if(clientes.data.status === "erro")
        setEntidades([])
    else{
        setEntidades(clientes.data.mensagem)
      }
      
      setLoader(true)
        
    }catch (error) {
      toast({
        title: 'Alerta',
        description: "Não conseguimos ter comunicação! Verifique sua internet",
        status: 'error',
      })
  }
}


  useEffect(()=>{
            axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.id_usuarios){
     setUser(value.mensagem)
    }else{
          navigate('/login')
        }
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  
  const selectdItem = (evt) =>{
    const tipo = evt.target.value.toLocaleLowerCase()
    const modified = referencias.map(item => {
      
      return {
        ...item,
        "num_referencia": item.num_referencia,
        "referencia_do_montante": item.referencia_do_montante,
        "data_limite_pagamento": item.data_limite_pagamento,
        "data_inicio_de_pagamento": item.data_inicio_de_pagamento,
        "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
        "registo_produto": item.registo_produto
      }
      
    
    })
    const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
    setReferenciasFilter(filtered)
    
}

const actualizarPagamentos = async () => {
try {
  setLoader(false)
  const entidade = id
  const referencia = await AXIOS.get(`/pagamentos/pagamento/nao/processados/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem)
      setPagina(referencia.data.registros.pagina_actual) 
      setPaginas(referencia.data.registros.paginas) 
      setQuantidade(referencia.data.registros.limite)  
    }
    
    setLoader(true)
      
  }catch (error) {
    toast({
      title: 'Alerta',
      description: "Não conseguimos ter comunicação! Verifique sua internet",
      status: 'error',
    })
}
};

const mudarEntidade = async (evt) => {   
navigate(`/adm/pagamentos/${evt.target.value}`)
await axiosReferencias()
}

const buscarComPaginacao  = async function(){
try {
  setLoader(false)
  const entidade = id
  const referencia = await AXIOS.get(`/pagamentos/pagamento/nao/processados/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
  console.log(referencia.data)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem) 
      setPagina(referencia.data.registros.pagina_actual) 
      setPaginas(referencia.data.registros.paginas) 
      setQuantidade(referencia.data.registros.limite) 
    }
    
    setLoader(true)
      
  }catch (error) {
    toast({
      title: t('Alerta'),
      description: t("Não conseguimos ter comunicação! Verifique sua internet"),
      status: 'error',
    })
  }
}

let permissao = user?.pagina_pagamento == "true";

if(user?.pagina_pagamento) permissao = user?.pagina_pagamento == "true"
else permissao = user.todas_areas ? true : false;
  
return (
<>
  
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
    <NavBarAdm/>
    {
       
       permissao

        ? 
    <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
        <Box px={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mt={5} mb={10}>Entidade {id} | Pagamentos</Text>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex color="gray.500" alignItems="center">
              <Select value={id} width="sm" bg="white" onChange={mudarEntidade}>
                {entidades.map(el => <option value={el.numero_entidade}>{el.nome_empresa}</option>)}
              </Select>
              <Text></Text> <Input width="sm" bg="white" type="search" placeholder='Pesquise ...' ml={1} onChange={selectdItem}/>
              <Button
                  bg="gray.600"
                  _hover={{ backgroundColor: "gray.700" }}
                  ml={1}
                  onClick={actualizarPagamentos}
                  leftIcon={<MdUpgrade />}
                  color="white"
                  w="xs"
                >
                  Actualizar lista
                </Button>
            </Flex>
            <Flex color="gray.500" alignItems="center">

            <NumberInput
                name='pagina'
                onChange={(valueString) => setPagina(parseInt(valueString))}
                value={pagina}
                max={paginas}
                min={1}
                width={120}
                ml={1}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="5000">5000</option>
                  <option value="10000">10000</option>
              </Select>
              <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
              
            </Flex>
          </Flex>
          
        {loader ?
        <TableContainer mt={5} bg="white">
          <Table variant='striped' colorScheme='gray'>
            <TableCaption>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
            </TableCaption>
            <Thead p={10}>
              <Tr bg="gray.600">
                <Th py={6} color="gray.300">Referência</Th>
                <Th py={6} color="gray.300">Montante</Th>
                <Th py={6} color="gray.300">Data do processamento</Th>
                {/* <Th py={6} color="gray.300">Hora</Th> */}
                {/* <Th py={6} color="gray.300">Produto</Th> */}
                <Th py={6} color="gray.300">Localidade</Th>
                {/* <Th py={6} color="gray.300">Notificação</Th> */}
                <Th py={6} color="gray.300">Conciliação</Th>
                {/* <Th py={6} color="gray.300">Estado da concialiação</Th> */}
                <Th py={6} color="gray.300">Acção</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map(el =>
                  <Tr color="blackAlpha.700">
                    <Td>{el.referencia_do_servico}</Td>
                    <Td>AKZ {Intl.NumberFormat('PT-br').format(el.montante_da_operacao)}</Td>
                    <Td>{el.PRT == "ACEITE" ? new Date(el.data_movimento).toLocaleDateString() : new Date(el.data_do_processamento).toLocaleDateString()}</Td>
                    {/* <Td>{el.PRT == "ACEITE" ? new Date('2000-01-01 '+el.hora_do_movimento).toLocaleTimeString() : new Date(el.data_hora_transacao_cliente).toLocaleTimeString()}</Td> */}
                    {/* <Td>{el.produto}</Td> */}
                    <Td>{el.localidade}</Td>
                    {/* <Td>{el.PRT == "ACEITE" ? <MdDoneAll size={26} color='green' /> : <MdOutlineRemoveDone size={26} color='red' />}</Td> */}
                    <Td>{el.MFT == "CONCLUIDO" ? <MdDoneAll size={26} color='green' /> : <MdOutlineRemoveDone size={26} color='red' />}</Td>
                    {/* <Td fontWeight={700}>{el.estado_pagamento == "FINALIZADO" ? <span style={{color:"#090"}}>{el.estado_pagamento}</span> : el.estado_pagamento == "ANULADO" ? <span style={{color:"#900"}}>{el.estado_pagamento}</span> : <span style={{color:"#009"}}>{el.estado_pagamento}</span>}</Td> */}
                    <Td>
                        <ModalViewPagamentoIncompletos registo={el}/>
                    </Td>
                  </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        :
        <Center>
          {/* <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          /> */}
          <Loader />
        </Center>}
      </Box>
      <Box mt={5} color="gray.700" textAlign="center">
                <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                <Text>Todos direitos reservados &copy; {new Date().getFullYear()}</Text>
      </Box>
    </Flex>

    :
              
    <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
      <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
      <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
      <MdError color='red' size={200} />
    </Box>
    }
    </Box>
  </Flex>
</>
)
}

export default Pagamentos