import { useEffect, useRef, useState } from 'react'
import { MdAccessible, MdAccountBox, MdClose, MdDoneOutline, MdError, MdOutlineWifiTetheringErrorRounded, MdPassword, MdSave, MdSupervisorAccount, MdWebhook } from "react-icons/md"
import {BsToggleOn, BsToggleOff} from 'react-icons/bs'
import {  Loader, ModalMudarSenha, NavBar, SideBarMenu } from '../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Center, useToast, Switch, Textarea, Select} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'


function Referencias() {

    const [registo, setReferencias] = useState({})    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const toast = useToast()    
    const [eventos, setEVentos] = useState([])
    const [showAnyEvents, setShowAnyEvents] = useState(false)
    const refEvento =  useRef();
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
    
    try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/afiliados/${id}/entidade/${entidade}/permissoes`);
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length){
            if(referencia.data.mensagem[0].numero_entidade == entidade)
                setReferencias(referencia.data.mensagem[0])
            else
                setReferencias([])
        }else{
          setReferencias([])
        }
      }
      
      const res = await AXIOS.get(`/eventos`)
        if (res.data.status == "sucesso"){
            setEVentos(res.data.mensagem)
        }
        
        setLoader(true)
      
      }catch (error) {
        navigate('/sem-comunicacao')
    }
          
  }    
  
  const formik = useFormik({
    initialValues:{
      pagina_referencia:'false',
      pagina_pagamento:'false',
      pagina_contabilistico:'false',
      pagina_relatorio:'false',
      pagina_servico_api:'false',
      pagina_faq:'false',
      pagina_afiliado:'false',
      pagina_sms:'false',
      pagina_token:'false',
      pagina_webwook:'false',
      pagina_notificacao:'false',
      pagina_perfil: 'false',
      pagina_sair: 'false'
    },
    onSubmit: async (values)=> {
    try{

      values.pagina_referencia = values.pagina_referencia ? "true" : "false"
      values.pagina_pagamento = values.pagina_pagamento ? "true" : "false"
      values.pagina_contabilistico = values.pagina_contabilistico ? "true" : "false"
      values.pagina_relatorio = values.pagina_relatorio ? "true" : "false"
      values.pagina_servico_api = values.pagina_servico_api ? "true" : "false"
      values.pagina_faq = values.pagina_faq ? "true" : "false"
      values.pagina_afiliado = values.pagina_afiliado ? "true" : "false"
      values.pagina_sms = values.pagina_sms ? "true" : "false"
      values.pagina_token = values.pagina_token ? "true" : "false"
      values.pagina_webwook = values.pagina_webwook ? "true" : "false"
      values.pagina_notificacao = values.pagina_notificacao ? "true" : "false"
      values.pagina_perfil = values.pagina_perfil ? "true" : "false"
      values.pagina_sair = values.pagina_sair ? "true" : "false"
      
      const res =  await AXIOS.patch(`/afiliados/${registo.id_afiliado}/permissoes`,{...values})
      
      if(res.data.status === "sucesso"){
        toast({
          title: t('Permissões de afiliados'),
          description: t(res.data.mensagem),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        
      }else{
        toast({
          title: t('Permissões de afiliados'),
          description: t(res.data.mensagem),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      navigate(`/afiliado/permissoes/${id}`)
      axiosReferencias(); 
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
      
    },
  })
  
  
    useEffect(()=>{
      axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
          
      
          
    },[])
    
    
      formik.initialValues.pagina_referencia = registo.pagina_referencia == "true" ? true : false
      formik.initialValues.pagina_pagamento = registo.pagina_pagamento == "true" ? true : false
      formik.initialValues.pagina_contabilistico =  registo.pagina_contabilistico == "true" ? true : false
      formik.initialValues.pagina_relatorio =  registo.pagina_relatorio == "true" ? true : false
      formik.initialValues.pagina_servico_api =  registo.pagina_servico_api == "true" ? true : false
      formik.initialValues.pagina_faq =  registo.pagina_faq == "true" ? true : false
      formik.initialValues.pagina_afiliado =  registo.pagina_afiliado == "true" ? true : false
      formik.initialValues.pagina_sms =  registo.pagina_sms == "true" ? true : false
      formik.initialValues.pagina_token =  registo.pagina_token == "true" ? true : false
      formik.initialValues.pagina_webwook =  registo.pagina_webwook == "true" ? true : false
      formik.initialValues.pagina_notificacao =  registo.pagina_notificacao == "true" ? true : false
      formik.initialValues.pagina_perfil =  registo.pagina_perfil == "true" ? true : false
      formik.initialValues.pagina_sair =  registo.pagina_sair == "true" ? true : false
      
      
      
  let permissao = user?.pagina_afiliado == "true";

  if(user?.pagina_afiliado) permissao = user?.pagina_afiliado == "true"
  else permissao = user.numero_entidade
      
  return (
  
  <>
    <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
      <SideBarMenu/>
      <Box flex={1}>
          <NavBar titulo={t("Permissões de afiliado")} imagem={<MdSupervisorAccount size={200}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
          {permissao ? <Box px={10} color="black" mt={10}>
                {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>{t("Modificar endpoint")}</Text> */}
                {loader ?
                <Flex color="black">
                    {registo?.id_afiliado && <Box flex={1} px={10}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box bg='white' p={10} rounded={5} boxShadow="md">
                       <FormControl>
                            <FormLabel>Nome completo</FormLabel>
                            <Flex direction="row" alignItems="center">
                              <Input type='text' disabled name='nome_afiliado' onChange={formik.handleChange} value={registo.nome_afiliado} />
                             </Flex>
                        </FormControl>
                        
                        <Heading mt={4}>Gestão</Heading>
                        <Flex direction="column" alignItems="center" justifyContent="space-between">
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_referencia' mb='0'>
                              {t("Referências")}
                            </FormLabel>
                            <Switch id='pagina_referencia' name='pagina_referencia' onChange={formik.handleChange} value={formik.values.pagina_referencia} defaultChecked={formik.values.pagina_referencia} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_pagamento' mb='0'>
                              {t("Pagamentos")}
                            </FormLabel>
                            <Switch id='pagina_pagamento' name='pagina_pagamento' onChange={formik.handleChange} value={formik.values.pagina_pagamento} defaultChecked={formik.values.pagina_pagamento} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_pagamento' mb='0'>
                              {t("Periodo Contabilistico")}
                            </FormLabel>
                            <Switch id='pagina_contabilistico' name='pagina_contabilistico' onChange={formik.handleChange} value={formik.values.pagina_contabilistico} defaultChecked={formik.values.pagina_contabilistico} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_relatorio' mb='0'>
                              {t("Relatórios")}
                            </FormLabel>
                            <Switch id='pagina_relatorio' name='pagina_relatorio' onChange={formik.handleChange} value={formik.values.pagina_relatorio} defaultChecked={formik.values.pagina_relatorio} /> 
                          </FormControl>
                        </Flex>
                        <Heading mt={4}>Documentação</Heading>
                        <Flex direction="column" alignItems="center" justifyContent="space-between">
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_servico_api' mb='0'>
                              {t("Serviços API")}
                            </FormLabel>
                            <Switch id='pagina_servico_api' name='pagina_servico_api' onChange={formik.handleChange} value={formik.values.pagina_servico_api} defaultChecked={formik.values.pagina_servico_api} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_faq' mb='0'>
                              {t("FAQ")}
                            </FormLabel>
                            <Switch id='pagina_faq' name='pagina_faq' onChange={formik.handleChange} value={formik.values.pagina_faq} defaultChecked={formik.values.pagina_faq} /> 
                          </FormControl>
                        </Flex>
                        <Heading mt={4}>Configurações</Heading>
                        <Flex direction="column" alignItems="center" justifyContent="space-between">
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_afiliado' mb='0'>
                              {t("Afiliados")}
                            </FormLabel>
                            <Switch id='pagina_afiliado' name='pagina_afiliado' onChange={formik.handleChange} value={formik.values.pagina_afiliado} defaultChecked={formik.values.pagina_afiliado} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_sms' mb='0'>
                              {t("SMS Notify")}
                            </FormLabel>
                            <Switch id='pagina_sms' name='pagina_sms' onChange={formik.handleChange} value={formik.values.pagina_sms} defaultChecked={formik.values.pagina_sms} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_token' mb='0'>
                              {t("Token API")}
                            </FormLabel>
                            <Switch id='pagina_token' name='pagina_token' onChange={formik.handleChange} value={formik.values.pagina_token} defaultChecked={formik.values.pagina_token} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_webwook' mb='0'>
                              {t("Webhooks")}
                            </FormLabel>
                            <Switch id='pagina_webwook' name='pagina_webwook' onChange={formik.handleChange} value={formik.values.pagina_webwook} defaultChecked={formik.values.pagina_webwook} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_notificacao' mb='0'>
                              {t("Notificações")}
                            </FormLabel>
                            <Switch id='pagina_notificacao' name='pagina_notificacao' onChange={formik.handleChange} value={formik.values.pagina_notificacao} defaultChecked={formik.values.pagina_notificacao} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_perfil' mb='0'>
                              {t("Perfil")}
                            </FormLabel>
                            <Switch id='pagina_perfil' name='pagina_perfil' onChange={formik.handleChange} value={formik.values.pagina_perfil} defaultChecked={formik.values.pagina_perfil} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel color="gray.500" htmlFor='pagina_sair' mb='0'>
                              {t("Terminar sessão")}
                            </FormLabel>
                            <Switch id='pagina_sair' name='pagina_sair' onChange={formik.handleChange} value={formik.values.pagina_sair} defaultChecked={formik.values.pagina_sair} /> 
                          </FormControl>
                        </Flex>
                        <Button display="block" leftIcon={<MdSave/>} onChange={formik.handleChange} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Salvar alterações")}</Button>
                     
                    </Box> 
                  </form>
                    </Box>}
                    {(registo.length == false) && <Center width="100%">
                      <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                          <MdError size={200} color="red"/>
                          <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Afiliados invalido")}</Heading>
                          <Text>{t("Desculpamos pelo transtorno causado, não conseguimos ter informações dos seus dados.")}</Text>
                          
                          <Text as={Link} mt={10} to="/afiliados" color='blue.500'>{t("Voltar")}</Text>
                      </Flex>
                    </Center>}
                </Flex>
                :
                <Center>
                  
                <Loader />
              </Center>}
              </Box>
              :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </>
  )
}

export default Referencias