import { Flex, Box, Text, Center, Heading, Button, Divider, useToast} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader, ModalMudarPercentualComun, DrawerPercentualPorUso, ModalMudarPercentual, BotaoTop } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { MdArrowBack, MdArrowForward, MdEdit, MdError } from 'react-icons/md'
import ReactPaginate from 'react-paginate'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [referenciasEspecifico, setReferenciasEspecifico] = useState([])
  const [PercentualEspecifico, setPercentualEspecifico] = useState([])
  const [PercentualComun, setPercentualComun] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const [referenciasEspecificoFilter, setReferenciasEspecificoFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  
  async function axiosReferencias(){
    const referencia = await AXIOS.get(`clientes/percentual/comum`)
    const referenciaEspecifico = await AXIOS.get(`clientes/percentual/especifico`)

    if(referencia.data.status === "erro")
        setPercentualComun([])
    else{
        setPercentualComun(referencia.data.mensagem)
        setReferencias(referencia.data.mensagem.clientes)
        setReferenciasFilter(referencia.data.mensagem.clientes)
    }

    if(referenciaEspecifico.data.status === "erro")
        setPercentualEspecifico([])
    else{
        setPercentualEspecifico(referenciaEspecifico.data.mensagem.percentualEspecificoTodos)
        setReferenciasEspecifico(referenciaEspecifico.data.mensagem.percentualEspecifico)
        setReferenciasEspecificoFilter(referenciaEspecifico.data.mensagem.percentualEspecifico)
    }
        
    setLoader(true)
        
}

const alternarCliente = async (cliente) => {
    let values = {}
    if(cliente.caraterizacao_cliente == "Comum"){
        values = {
          caraterizacao_cliente: "Especifico",
          percentagem_de_uso: '0'
        }
    }else if(cliente.caraterizacao_cliente == "Especifico"){
        values = {
          caraterizacao_cliente: "Comum",
          percentagem_de_uso: '1'
        }
    }else{
      toast({
        title: 'Alternar entidade',
        description: "Caracterização inexistente",
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }

    const res =  await AXIOS.patch(`/clientes/${cliente.id_clientes}`,values)
    
    toast({
      title: 'Alternar entidade',
      description: "Á processar",
      status: 'loading',
      duration: 1000,
      isClosable: true,
    })

    if(res.data.status === "sucesso"){
      
      setTimeout(()=>{
        toast({
          title: 'Alternar entidade',
          description: res.data.mensagem,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        axiosReferencias()
      },2000)
    }else{
      setTimeout(()=>{
        toast({
          title: 'Alternar entidade',
          description: res.data.mensagem,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },2000)
    }
}

const alterarPercentual = async (valorFixo, percentual, minimo, maximo, formaDeUso ,id_por_uso)=>{
  
  if((valorFixo) && (percentual) && (minimo) && (maximo)){
    const res =  await AXIOS.patch(`/clientes/percentual/${id_por_uso}`,{valor_fixo:valorFixo, minimo:minimo, maximo: maximo, percentual: percentual, forma_de_uso: formaDeUso})
    
    toast({
      title: 'Actualização de percentual por uso',
      description: "Á processar",
      status: 'loading',
      duration: 1000,
      isClosable: true,
    })

    if(res.data.status === "sucesso"){
      
      setTimeout(()=>{
        toast({
          title: 'Actualização de percentual por uso',
          description: res.data.mensagem,
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        axiosReferencias()
      },2000)
    }else{
      setTimeout(()=>{
        toast({
          title: 'Actualização de percentual por uso',
          description: res.data.mensagem,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },2000)
    }
  }else{
    toast({
      title: 'Actualização de percentual por uso',
      description: "Dados incompletos",
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
  }
}
  useEffect(()=>{
     
    axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 10
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffsetEspecifico, setItemOffsetEspecifico] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPageEspecifico = 50
  const endOffsetEspecifico = itemOffsetEspecifico + itemsPerPageEspecifico;
  console.log(`Loading items from ${itemOffsetEspecifico} to ${endOffsetEspecifico}`);
  const currentItemsEspecifico = referenciasEspecificoFilter.slice(itemOffsetEspecifico, endOffsetEspecifico);
  const pageCountEspecifico = Math.ceil(referenciasEspecificoFilter.length / itemsPerPageEspecifico);

  // Invoke when user click to request another page.
  const handlePageClickEspecifico = (event) => {
    const newOffsetEspecifico = (event.selected * itemsPerPageEspecifico) % referenciasEspecificoFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffsetEspecifico}`
    );
    setItemOffsetEspecifico(newOffsetEspecifico);
  };

      
let permissao = user?.pagina_transacoes_percentual == "true";

if(user?.pagina_transacoes_percentual) permissao = user?.pagina_transacoes_percentual == "true"
else permissao = user.todas_areas ? true : false;
  
return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
      {
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Percentual por uso em transações</Text>
        {
        loader
        ?
        <Box shadow="sm" rounded={5} bg="white" p={3} >
          <Flex justifyContent="space-between">
            <Box width="49%" p={2} border="solid #2a7221 2px" fontSize="larger" p={5}>
              <Heading mb={5} size="2xl">Uso comun</Heading>
              <Text>Valor Fixo: <b> AKZ {Intl.NumberFormat("PT-br").format(PercentualComun.valor_fixo)}</b></Text>
              <Text>Percentual: <b>{Intl.NumberFormat("PT-br").format(String(PercentualComun.percentual).replace("0.00","."))}%</b></Text>
              <Text>Valor Minimo: <b> AKZ {Intl.NumberFormat("PT-br").format(PercentualComun.minimo)}</b></Text>
              <Text>Valor Maximo: <b> AKZ {Intl.NumberFormat("PT-br").format(PercentualComun.maximo)}</b></Text>

              <ModalMudarPercentualComun alterarPercentual={alterarPercentual} percentualExistente={PercentualComun}/>
              <Divider />
              <Heading mt={5} mb={5} size="lg">Entidades associadas ({PercentualComun.clientes.length})</Heading>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
              <Text mt={5} mb={5}></Text>
              {currentItems.map(ref =>
                <Flex justifyContent="space-between" alignItems="center" mb={2}>                  
                  <Text>{ref.nome_empresa}</Text>
                  <Button colorScheme='green' onClick={() => alternarCliente(ref)}>Alternar</Button>
                </Flex> 
              )}
            </Box>
            <Box width="49%" p={2} border="solid #800e13 2px" fontSize="larger" p={5}>
              <Heading mb={5} size="2xl">Uso Especifico </Heading>
              <DrawerPercentualPorUso axiosReferencias={axiosReferencias} produto={{id_por_uso:0}} entidades={PercentualEspecifico} />
              <Heading mt={5} mb={5} size="lg">Entidades associadas ({referenciasEspecifico.length})</Heading>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClickEspecifico}
                   pageRangeDisplayed={5}
                   pageCount={pageCountEspecifico}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
              <Text mt={5} mb={5}></Text>
              {currentItemsEspecifico.map(ref =>
                <Flex justifyContent="space-between" alignItems="center" mb={2}>
                  <ModalMudarPercentual  alterarPercentual={alterarPercentual}  percentualExistente={ref} entidade={ref.nome_empresa}/>
                  <Button colorScheme='red' onClick={() => alternarCliente(ref)}>Alternar</Button>
                </Flex> 
              )}
            </Box>
          </Flex>
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
          </Box>

          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes