import { Flex, Box, Text, Center, WrapItem, Avatar, Card, CardHeader, CardBody, Stack, StackDivider, Heading, useToast, Button } from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import { SideBarMenu, NavBar, Loader, BotaoTop } from '../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { MdEmojiPeople, MdError } from 'react-icons/md'


function MninhaConta() {

    const [referencias, setReferencias] = useState([])
    const [referenciasFilter, setReferenciasFilter] = useState([])
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState('1')
    const [loader, setLoader] = useState(false)
    const refInputFile = useRef(null)
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
      try {
        await AXIOS.get(`/`)
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/referencias/entidade/${entidade}`)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem)
      }
          
      console.log(referencia.data.mensagem)
      setLoader(true)
    }catch (error) {
        navigate('/sem-comunicacao')
    }
          
  }
  
    useEffect(()=>{
              axiosReferencias();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
    },[])
    
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const itemsPerPage = 7
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = referenciasFilter.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
    
    const clickButtonFile = async () => {
    try {
      refInputFile.current.click()
    } catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
    }
      
}

const uploadFile = async (evt) => {

      try {
          const form = new FormData();
          const filename = user.email + Date.now() + "." + evt.target.files[0].type.split('/')[1]
          form.append('foto_perfil', evt.target.files[0], filename)
          form.append('id_clientes', user.id_clientes)
          
          const res = await AXIOS.patch(`/clientes/mudar/foto`,form)
          if(res.data.status === "sucesso"){
        
            toast({
              title: t('Alteração de foto de perfil'),
              description: t(res.data.mensagem),
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
            
            // var now = new Date();
            // var time = now.getTime();
            // var expireTime = time + 1000*60*60*24*7;
            // now.setTime(expireTime);
            // document.cookie = CONFIG.COOKIE_SESSION.CLIENT+JSON.stringify(res.data)+';expires='+now.toGMTString()+';path=/';
            
            // axiosReferencias();
            
        }else{
          toast({
            title: t('Alteração de foto de perfil'),
            description: t(res.data.mensagem),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        
        }
      } catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
      }
}
    
  
let permissao = user?.pagina_perfil == "true";

if(user?.pagina_perfil) permissao = user?.pagina_perfil == "true"
else permissao = user.numero_entidade

  return (
    <>
      <Flex color='white' bg="white" direction="row">
        <SideBarMenu />
        <BotaoTop/>
        <Box flex={1}>
        <NavBar titulo={t("Minha conta - Informações")} imagem={<MdEmojiPeople size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}/>
        <Flex direction="column" justifyContent="space-between" mt={5} style={{minHeight:'90vh'}}>
            {permissao ? <Box px={10} color="black" mt={10}>
              <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}></Text>
            {
            loader
            ?
            <Flex justifyContent="space-between">
                <Box shadow="sm" w="35%" rounded={5} bg="white" p={10} textAlign="center" boxShadow="md">
                  <Center>
                    <WrapItem onClick={clickButtonFile} cursor="pointer" display="flex" flexDirection="column">
                      <Avatar size='2xl' name={`${user.nome_empresa}`} src={`${CONFIG.HOST_API}/images/${user.logo}`} />{' '}  
                    </WrapItem>                   
                  </Center>
                  <input type='file' onChange={uploadFile} ref={refInputFile} style={{visibility:"hidden"}} accept='image/*'/>
                  <Heading mt={0}>{user.nome_empresa}</Heading>
                  <Text mt={0} mb={5}>{user.responsavel}</Text>
                  <Button color="white" _hover={{backgroundColor:'blue.700'}} bg="blue.500" as={Link} to="/mudar-senha">{t("Mudar a senha")}</Button>
                </Box>
                
                <Box shadow="sm" w="62%" rounded={5} bg="white" p={10} boxShadow="md">
                    <Card  boxShadow="none">
                      <CardHeader>
                        <Heading size='md'>{user.primeiro_nome} {user.ultimo_nome}</Heading>
                      </CardHeader>
                    
                      <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Como
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {user.responsavel}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              E-mail
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {user.email}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Terminal
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {user.contacto}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Usuário desde
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {new Date(user.criando_em).toLocaleDateString()} {new Date(user.criando_em).toLocaleTimeString()}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Ultimo login
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {new Date(user.ultimo_login).toLocaleDateString()} {new Date(user.ultimo_login).toLocaleTimeString()}
                            </Text>
                          </Box>
                        </Stack>
                      </CardBody>
                    </Card>
                </Box>
            </Flex>
            :
            <Center>
              {/* <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              /> */}
              <Loader />
            </Center>}
            </Box>
            :<Center width="100%">
            <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                <MdError size={200} color="red"/>
                <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
            </Flex>
          </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>Todos direitos reservados &copy; {new Date().getFullYear()}</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default MninhaConta