import { Flex, Text, Heading, Box } from '@chakra-ui/react'
import {   MdOutlineWifiTetheringErrorRounded} from "react-icons/md"
import {Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

function Pagina404() {

    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()
    
    
    
    useEffect(()=>{
        const value = JSON.parse(loginValue)
        if(value?.mensagem?.numero_entidade){
         setUser(value.mensagem)
        }else{
              navigate('/login')
    }
},[])
  
return (
  
    <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" style={{minHeight:'100vh'}} direction="column">
    
          <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
              <MdOutlineWifiTetheringErrorRounded size={200} color="red"/>
              <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Estamos sem comunicação")}</Heading>
              <Text>{t("Desculpamos pelo transtorno causado, não conseguimos ter informações dos seus dados. Por favor aguadar ou verifique sua internet")}</Text>
              
              <Text as={Link} mt={10} to="/" color='blue.500'>{t("Inicio")}</Text>
          </Flex>
          <Box>
              <Text mt={70}><b>INTELIZE INVESTIMENTOS</b> * Luanda, Talatona, Atlântico Business, Escritorio nº 28 * suporte@intelize.ao</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
          
    </Flex>
)
}

export default Pagina404