import React, {useState} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Select} from '@chakra-ui/react'
import {MdEdit, MdPassword} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalMudarSenha ({alterarPercentual, percentualExistente, entidade}) {
    const [open, setOpen] = useState(false)
    const [valorFixo, setValorFico] = useState('')
    const [percentual, setPercentual] = useState('')
    const [minimo, setMinimo] = useState('')
    const [maximo, setMaximo] = useState('')
    const [formaDeUso, setFormaDeUso] = useState('')
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }

    console.log(percentualExistente)
    
    return (
      <>
        <Text cursor="pointer" textDecoration="underline" fontWeight={500} mt={10} onClick={onOpen}>{entidade}</Text>
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Alterar custo por uso especifico</ModalHeader>
          <ModalHeader mt={-8} fontSize={15} color="blue.600">{entidade}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Valor Fixo</FormLabel>
              <Input type='number' name='valor_fixo' defaultValue={percentualExistente.valor_fixo} required minLength={6} onChange={(evt) => setValorFico(evt.target.value)} />
            </FormControl>

            <FormControl>
              <FormLabel>Percentual</FormLabel>
              <Input type='number' name='percentual' defaultValue={percentualExistente.percentual} required minLength={6} onChange={(evt) => setPercentual(evt.target.value)} />
            </FormControl>

            <FormControl>
              <FormLabel>Valor Minimo</FormLabel>
              <Input type='number' name='minimo' defaultValue={percentualExistente.minimo} required minLength={6} onChange={(evt) => setMinimo(evt.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Valor Maximo</FormLabel>
              <Input type='number' name='maximo' defaultValue={percentualExistente.maximo} required minLength={6} onChange={(evt) => setMaximo(evt.target.value)} />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>{t("Forma de uso")}</FormLabel>
              <Select required name='forma_de_uso'  defaultValue={percentualExistente.forma_de_uso} onChange={(evt) => setFormaDeUso(evt.target.value)} >
                <option value="Percentual">Percentual</option>
                <option value="Valor Fixo">Valor Fixo</option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{ alterarPercentual(valorFixo || percentualExistente.valor_fixo , percentual || percentualExistente.percentual, minimo || percentualExistente.minimo, maximo || percentualExistente.maximo, formaDeUso || percentualExistente.forma_de_uso , percentualExistente.id_por_uso); onOpen()}} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
