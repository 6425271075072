import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast } from '@chakra-ui/react'
import {  MdAdd, MdPeople, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'

export default function PlacementExample({axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()

    const formik = useFormik({
      initialValues:{
        nome_usuario:'',
        tipo_usuario:'',
        email_: '',
        senha_: '',
      },
      onSubmit: async (values)=> {
        const res =  await AXIOS.post(`/usuarios`,values, {headers: { gerador: user.id_usuarios}})

        toast({
          title: 'Criação de usuarios',
          description: "À carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{            
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de usuarios',
              description: res.data.mensagem,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })

            axiosReferencias()

          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de usuarios',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      axiosReferencias();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.900' color="white" onClick={()=>setIsOpen(true)}>
          Adcionar
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Criar usuarios</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl>
                        <FormLabel>Nome do usuario</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='nome_usuario' onChange={formik.handleChange} value={formik.values.nome_usuario} />
                         </Flex>
                    </FormControl>
                      <FormControl mt={2}>
                          <FormLabel>Email</FormLabel>
                          <Input type='email' name='email_'  required onChange={formik.handleChange} value={formik.values.email_} />
                      </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>Senha</FormLabel>
                        <Input type='password' name='senha_' required onChange={formik.handleChange} value={formik.values.senha_} />
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>Como</FormLabel>
                        <Select placeholder='Selecione tipo de usuario' required name='tipo_usuario' value={formik.values.numero_entidade} onChange={formik.handleChange}>
                          <option value={1}>Administrador</option>                       
                          <option value={2}>Gerente</option>                     
                          <option value={3}>Comercial</option>                     
                          <option value={4}>Contablidade</option>                     
                          <option value={5}>Financeiro</option>                     
                      </Select>
                    </FormControl>
                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Criar</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }