import React, {useState, useEffect} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal, Text, Divider, Box, Flex, Heading,
    ModalOverlay,
    ModalContent,
    Tooltip,
    ModalHeader,
    ModalBody,useToast,
    ModalCloseButton} from '@chakra-ui/react'
import {MdDoneAll, MdGridView, MdPassword, MdRefresh} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useNavigate } from 'react-router-dom'

function ModalReenvioEndpointFalhados ({entidade, erros}) {
    const [open, setOpen] = useState(false)
    const [senha, setSenha] = useState('')
    const [data, setData] = useState(new Date().toISOString().split('T')[0])
    const [resp, setResp] = useState([])
    const [repetirsenha, setRepetirsenha] = useState('')
    const {t, i18n} = useTranslation()
    const navigate = useNavigate();
    const [referencias, setReferencias] = useState([]);
    const toast = useToast()
    const onOpen = () => {
            setOpen(!open)

    }

    async function axiosReferencias() {
      try {

        
        const referencia = await AXIOS.get(
          `/logs-requisicao/entidade/${entidade}/todas-falhas/?data=${data}`
        );
  
        if (referencia.data.status === "erro") setReferencias([]);
        else {
          setReferencias(referencia.data.mensagem);
        }
  
      } catch (error) {
        navigate("/sem-comunicacao");
      }
    }


    // const respostas = () => {
    //   const parde = JSON.parse(texto.resposta)
    //   for (const iterator in parde) {
    //     const fn = resp.find( i => i == `${iterator} = ${parde[iterator]}`)      
    //     setResp(pv => [...pv, `${iterator} = ${parde[iterator]}`])
    //   }

    // }
  
    const reenviarRequest = async (logs) => {

      toast({
        title: 'Reenviar requisição',
        description: "Á processar",
        status: 'loading',
        duration: 2500,
        isClosable: true,
      })

      try {

        const dadosT = {
          entidade_aceite: entidade,
          logs: logs
        }
          
            const responseR = await AXIOS.post('/logs-requisicao/reenvio/varias', dadosT);

            if(responseR.data.status == "sucesso"){
                setTimeout(()=>{
                  toast({
                    title: 'Reenviar requisições',
                    description: responseR.data.mensagem, 
                    status: 'success',
                    duration: 2500,
                    isClosable: true,
                  })
      
                },2800)
                
                onOpen()
            }else {
              setTimeout(()=>{
                toast({
                  title: 'Reenviar requisições',
                  description: responseR.data.mensagem, 
                  status: 'error',
                  duration: 2500,
                  isClosable: true,
                })
            },2800)
            }
            
        }
        catch (error) {
          console.log("STATUS",error);
          setTimeout(()=>{
            toast({
              title: 'Reenviar requisições',
              description: error.message,
              status: 'error',
              duration: 2500,
              isClosable: true,
            })
        },2800)
        }
      }

      useEffect(()=>{
        axiosReferencias()
    },[])
      
    return (
      <>
        <Button ml={1} leftIcon={<MdDoneAll/>}  onClick={onOpen} bg='blue.400' color="white" variant='solid'>Reenviar requisições falhadas</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="4xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Reenvio de requisições</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
              <Flex justifyContent="">
                  <Input type='date' value={data} onChange={(e) => setData(e.target.value)} />
                <Button mb={10} onClick={axiosReferencias} colorScheme='blue' ml={5}>Buscar</Button>
              </Flex>
              <Heading size="md">Todos logs</Heading>
              <Text fontSize={45} fontWeight="bold">{referencias?.todosErros?.quantidade}</Text>
              <Button mb={5} onClick={()=> reenviarRequest(referencias?.todosErros?.logs)} colorScheme='green'>Resolver</Button>
              <Divider/>
              <Flex mt={5} justifyContent="space-between">
                <Box>
                  <Heading size="md" color="orange.500">HTTP Erros 30*...</Heading>
                  <Text fontSize={45} fontWeight="bold">{referencias?.erros300?.quantidade}</Text>
                  <Button mb={5} onClick={()=> reenviarRequest(referencias?.erros300?.logs)} colorScheme='green'>Resolver</Button>
                </Box>
                <Box>
                  <Heading size="md" color="red.700">HTTP Erros 40*...</Heading>
                  <Text fontSize={45} fontWeight="bold">{referencias?.erros400?.quantidade}</Text>
                  <Button mb={5} onClick={()=> reenviarRequest(referencias?.erros400?.logs)} colorScheme='green'>Resolver</Button>
                </Box>
                <Box>
                  <Heading size="md" color="violet">HTTP Erros 50*...</Heading>
                  <Text fontSize={45} fontWeight="bold">{referencias?.erros500?.quantidade}</Text>
                  <Button mb={5} onClick={()=> reenviarRequest(referencias?.erros500?.logs)} colorScheme='green'>Resolver</Button>
                </Box>
              </Flex>
           </ModalBody>

          <ModalFooter>
            
            
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalReenvioEndpointFalhados
