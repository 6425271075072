import { Flex, Box, Text, Heading, Center, } from '@chakra-ui/react'  
import { SideBarMenu, NavBar, BotaoTop } from '../Components/index'
import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { MdError, MdHelp } from 'react-icons/md'

function FAQ() {

  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"  
  const navigate =  useNavigate()
  const [user, setUser] = useState('1')
  const {t, i18n} = useTranslation()
  

  useEffect(()=>{
  
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
          navigate('/login')
        }
  },[])

  let permissao = user?.pagina_faq == "true";

  if(user?.pagina_faq) permissao = user?.pagina_faq == "true"
  else permissao = user.numero_entidade
  
return (
  user?.habilitar_area_docs == "true"  ?  <>
  <Flex color='white' bg="white" direction="row">
    <SideBarMenu />
    <BotaoTop/>
    <Box flex={1}>
      <NavBar titulo={t("FAQ - Perguntas frequentes")} imagem={<MdHelp size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
      <Flex direction="column" justifyContent="space-between" mt={5} style={{minHeight:'90vh'}}>
      {permissao ? <Box px={10} color="black" mt={10}>
            {/* <Heading fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}></Heading> */}
          <Box bg="white" rounded={5} p={10} boxShadow="md">
            <details style={{marginTop:15, padding:10,fontWeight:600,fontSize:25}}>
              <summary>{t("Como gerar referências")}</summary>
              <p style={{fontSize:16, fontWeight:300}}>
                Para que se possa gerar as referências de pagamentos deverá clicar no link REFERENCIAS no menu ao lado. Terá um botão(adicionar) e depois de clicar preencha os dados necessarios
              </p>
            </details>
            <details style={{marginTop:15, padding:10,fontWeight:600,fontSize:25}}>
              <summary>{t("Como usar a API do portal")}</summary>
                <p style={{fontSize:16, fontWeight:300}}>
                A nossa plataforma é simples e facil de usar. Para que possas usar a nossa API, deverá solicitar os certificados de autenticação e gerar o TOKEN de autorização para fazer requisições nos endpoints
                </p>
            </details>
            <details style={{marginTop:15, padding:10,fontWeight:600,fontSize:25}}>
              <summary>{t("Como parametriazar as referências nos ATMs")}</summary>
              <p style={{fontSize:16, fontWeight:300}}>
                As Referências servem para que seus clientes realizem pagamento dos seus serviços nos terminais <b>ATM, MULTICAIXA EXPRESS e INTERNET BANK</b>. Então deverá gerar no menu referências e mante-las activas com data de limite de pagamento aceite
              </p>
            </details>
            <details style={{marginTop:15, padding:10,fontWeight:600,fontSize:25}}>
              <summary>{t("Como é garantido o pagamento feito ao meu cliente")}</summary>
              <p style={{fontSize:16, fontWeight:300}}>
                  A transações são garantidas a 100%. Apois o cliente pago um serviço, esse pagamento passa em primeiro na dententora da rede que é a EMIS, é nos encaminhado e validos para sí. Apois alguns minutos é feito a conciliação bancaria automaticamente.
                  Poderá ver esse proceso no menu Pagamentos
                </p>
            </details>
            <details style={{marginTop:15, padding:10,fontWeight:600,fontSize:25}}>
              <summary>{t("Como é feito a integração dos pagamentos no meu serviço")}</summary>
              <p style={{fontSize:16, fontWeight:300}}>
                De modo geral, nossos clientes têm 3 formas de usar a nossa plataforma pois pretendemos atingir todo tipo de cliente.
                A primeira é por via do portal, onde o cliente não precisa ter muitos conhecimentos tecnicos e pode usar os nossos serviços de pagamentos.
                <br />
                <br />
                Segunda, é usar a nossa API e fazer com que a nossa plataforma seja consumida para diversas soluções do cliente por intermedio do protocolo HTTP. Com a API poderá fazer com que seu sistema ou programa posso interagir com a plataforma sem a necessidade de ter um humano
                <br />
                <br />
                E por ultimo, utilizar o nosso webhook. Os webhooks estão presentes em diversos sistemas e plataformas. Ele têm um papel quase contrario da API. Póis ao invês do cliente requisitar os nossos recursos, a nossa plataforma o faz no serviço do cliente.
                 O webhooks da <b>INTELIZE PAGAMENTOS</b> leva as inforções até o sitema do cliente por via de uma API com base á algum acontecimento ocorrido na conta do cliente! Quer seja ao receber um pagamento ou gerar uma referência
              </p>
            </details>
          </Box>
          </Box>
          :<Center width="100%">
          <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
              <MdError size={200} color="red"/>
              <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
              <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
          </Flex>
        </Center>}
      <Box mt={5} color="gray.700" textAlign="center">
            <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
            <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
        </Box>
      </Flex>
    </Box>
  </Flex>
  </>
  :
  navigate("/404")
)
}

export default FAQ