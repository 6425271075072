import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast } from '@chakra-ui/react'
import {  MdAdd, MdPeople, MdTerminal} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export default function PlacementExample({entidade,axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()
    const navigate =  useNavigate()  
    const toast = useToast();
    

    const formik = useFormik({
      initialValues:{
        pos_nome:'',
        pos_codigo_emis: '',
        comerciante_id: entidade
      },
      onSubmit: async (values)=> {

        values.cliente_id = user.id_clientes
        
        const res =   await AXIOS.post(`/pos-clientes/`,values)
        
        toast({
          title: 'Criação de pos',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){          
          
          
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de pos',
              description: res.data.mensagem,
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferencias()
          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de pos',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    const onClose = function(){
        setIsOpen(false)
    }

    
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          Adicionar
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Cadastrar POS</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl mt={2}>
                        <FormLabel>Nome do Terminal</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='pos_nome' onChange={formik.handleChange} value={formik.values.pos_nome} />
                         </Flex>
                    </FormControl>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl mt={2}>
                          <FormLabel>Número do POS</FormLabel>
                          <Input type='text' name='pos_codigo_emis' onChange={formik.handleChange} required value={formik.values.pos_codigo_emis} />
                      </FormControl>
                    </Flex>
                    <Button leftIcon={<MdTerminal/>} bg="blue.900" color="white"  type='submit' mt={5}>Salvar</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }