import React, {useEffect, useRef, useState} from 'react'
import { Button, Center, ModalFooter, Spinner, useToast, Modal, Box, Text, Heading,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,Flex} from '@chakra-ui/react'
import {  MdFileUpload} from 'react-icons/md'
import AXIOS from '../Config/axiosConfig'
import { useTranslation } from 'react-i18next'
import CONFIG from '../Config'
import { useNavigate } from 'react-router-dom'


function MOdalReportsReferencia ({entidade}) {
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(true)
    const {t} = useTranslation()
    const [user, setUser] = useState('1')
    const [resposta, setResposta] = useState({sucesso: 0, erros: 0, total: 0})
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const onOpen = () => {
            setOpen(!open)
    }
    const refInputFile = useRef(null)

  
    useEffect(()=>{
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
      setUser(value.mensagem)
      }else{
          navigate('/login')
        }
      },[])


    const uploadFile = async (evt) => {

      try {
          const form = new FormData();
          const filename = user.email + Date.now() + "." + evt.target.files[0].type.split('/')[1]
          form.append('file_xml', evt.target.files[0], filename)
          form.append('id_clientes', user.id_clientes)

          toast({
            title: t('Importar XML para referencias'),
            description: "Aguarde a finalização",
            status: 'loading',
            duration: 2000,
            isClosable: true,
          })
          
          const res = await AXIOS.post(`/referencias/importar/xml`,form, {'Content-Type': 'multipart/form-data'})
          if(res.data.status === "sucesso"){
        
            setResposta(res.data.mensagem)
            console.log(res.data.mensagem)

            toast({
              title: t('Importar XML para referencias'),
              description: "Importação concluida",
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
            
            
        }else{
          toast({
            title: t('Importar XML para referencias'),
            description: t(res.data.mensagem),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        
        }
      } catch (error) {
        toast({
          title: t('Alerta'), 
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
      }
}

const clickButtonFile = async () => {
  try {
    refInputFile.current.click()
  } catch (error) {
    toast({
      title: t('Alerta'),
      description: t("Não conseguimos ter comunicação! Verifique sua internet"),
      status: 'error',
    })
  }
    
}


    
    return (
      <>
        <Button leftIcon={<MdFileUpload/>} ml={2} color="white" onClick={onOpen} bg="blue.400"  _hover={{backgroundColor:"blue.600"}} variant='solid'>{t("Importar")}</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="2xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
          backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Button leftIcon={<MdFileUpload/>} onClick={clickButtonFile} colorScheme='facebook'>Importar</Button>
            <input type='file' onChange={uploadFile} ref={refInputFile} style={{visibility:"hidden", marginBottom:10}} accept="text/*"/>
            <Flex mt={5} justifyContent="space-between">
                <Box>
                  <Text>Total importados</Text>
                  <Heading textAlign="center">{resposta.total}</Heading>
                </Box>
                <Box>
                  <Text>Referências Não inseridas</Text>
                  <Heading color="red.400" textAlign="center">{resposta.erros}</Heading>
                </Box>
                <Box>
                  <Text>Referências Adicionadas</Text>
                  <Heading color="green.400" textAlign="center">{resposta.sucesso}</Heading>
                </Box>
            </Flex>
            {loader ? 
            // <ReportsReferencias referencias={referencias}/> 
            null :
            <Center>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>}
          </ModalBody>

          <ModalFooter>  
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default MOdalReportsReferencia
