import React, {useState} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton} from '@chakra-ui/react'
import {MdEdit, MdPassword} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalConfgIPS ({mudarIPS, registro}) {
    const [open, setOpen] = useState(false)
    const [ip1, setIp1] = useState('') || registro.ip_primario
    const [ip2, setIp2] = useState('') || registro.ip_secundario
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    
    return (
      <>
        <Button ml={2} leftIcon={<MdEdit />}  onClick={onOpen} bg='blue.400' color="white" variant='solid'>Permissão de IPs</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>
            Configure os IPs a serem permitidos<br />
            <span style={{color:"blue"}}>{registro.nome_empresa}</span>
            
            </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>IP Primário</FormLabel>
              <Input type='text' name='ip1' required minLength={7} onChange={(evt) => setIp1(evt.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>IP Secundário</FormLabel>
              <Input type='text' name='ip2' required minLength={7} onChange={(evt) => setIp2(evt.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{ mudarIPS(ip1, ip2, registro.id_clientes); onOpen()}} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalConfgIPS
