import axios  from 'axios';
import CONFIG from './index';

const serviceUrl = CONFIG.HOST_API
const Entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem?.numero_entidade

const config = {
  baseURL : serviceUrl,
  maxBodyLength : Infinity,
  insecureHTTPParser: true,
  httpAgent: true,
  headers: {
    Entidade,
    Authorization : 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhcHAiOnsiZG9ubyI6IjNlMWVmOGYwODM2MDZmZTU5M2RiMTY3YTVlMjg3OWQzZTgzYTFlNGQxNTNlMzgzNjQ3M2E2MmZjYjRjN2UzZDU4MmY5NmE2YTFiNWYifX0.u9R7DsHAGBbunFFUiHtsvgUS0h6zxtjD_NPm3np4A50f_MKb0VDOY0mG0o_na3cTuEJzK_k7uiCleB8HFXARsg',
    Auth : 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJhcHAiOnsiZG9ubyI6IjNlMWVmOGYwODM2MDZmZTU5M2RiMTY3YTVlMjg3OWQzZTgzYTFlNGQxNTNlMzgzNjQ3M2E2MmZjYjRjN2UzZDU4MmY5NmE2YTFiNWYifX0.u9R7DsHAGBbunFFUiHtsvgUS0h6zxtjD_NPm3np4A50f_MKb0VDOY0mG0o_na3cTuEJzK_k7uiCleB8HFXARsg'
    }
}

const instance = axios.create(config)

export default instance;