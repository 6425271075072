import {  Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption, Button,
  TableContainer, Heading, useToast, Center } from '@chakra-ui/react'  
  import { MdArrowBack, MdArrowForward, MdDone, MdDoneAll, MdError, MdOutlineFileDownload, MdOutlineRemoveDone, MdUpgrade} from "react-icons/md"
  import { ModalViewPagamento, SideBarMenu, NavBar, Loader, DrawerRelatorioReferencia, ModalRemoveRelatorios, BotaoTop } from '../Components/index'
import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import AXIOS from '../Config/axiosConfig'
import { useTranslation } from 'react-i18next'
import { MdHelp, MdReport } from 'react-icons/md'
import { BiSolidReport } from 'react-icons/bi'
import ReactPaginate from 'react-paginate';

function Relatorios() {

  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"  
  const navigate =  useNavigate()
  const [user, setUser] = useState('1')
  const [referencias, setReferencias] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  const {t, i18n} = useTranslation()
  
  async function axiosReferencias(){
    
    try {
      setLoader(false)
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/relatorios/entidade/${entidade}`)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem)  
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
  }

  useEffect(()=>{
    axiosReferencias(); 
const value = JSON.parse(loginValue)
if(value?.mensagem?.numero_entidade){
setUser(value.mensagem)
}else{
  navigate('/login')
}
},[])
  

      // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const itemsPerPage = 20
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = referenciasFilter.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    const excluirRef = async (ref) => {
      try {
            toast({
              title: t("Exclusão de relatorio"),
              description: t("Não podemos excluir o relatorio"),
              status: "error",
              duration: 5000,
              isClosable: true
            });
        const refs = referencias.filter(
          (r) => r.id_relatorio != ref.id_relatorio
        );
        
        const res = await AXIOS.delete(
          `/relatorios/${ref.id_relatorio}`
        );
        if (res.data.status == "sucesso") {
          setReferencias(refs);
          toast({
            title: t("Exclusão de relatorio"),
            description: t("Conseguimos excluir o relatorio com sucesso"),
            status: "success",
            duration: 5000,
            isClosable: true
          });
        } else {
          toast({
            title: t("Exclusão de relatorio"),
            description: t("Não conseguimos excluir o relatorio"),
            status: "error",
            duration: 5000,
            isClosable: true
          });
        }
  
        axiosReferencias();
      } catch (error) {
        navigate("/sem-comunicacao");
        toast({
          title: t("Alerta"),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: "error"
        });
      }
    };

    let permissao = user?.pagina_relatorio == "true";

    if(user?.pagina_relatorio) permissao = user?.pagina_relatorio == "true"
    else permissao = user.numero_entidade

return (
  <>
  <Flex color='white' bg="white" direction="row">
    <SideBarMenu />
    <BotaoTop/>
    <Box flex={1}>
      <NavBar titulo={t("Relatorios")} imagem={<BiSolidReport size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
      <Flex direction="column" justifyContent="space-between" mt={2} style={{minHeight:'90vh'}}>
      {permissao ? <Box px={10} color="black" mt={1}>
            {/* <Heading fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}></Heading> */}
          <Box bg="white" rounded={5} p={10} boxShadow="sm">
          <DrawerRelatorioReferencia axiosReferenciass={axiosReferencias} />
          <Button
                bg="blue.400"
                _hover={{ backgroundColor: "blue.600" }}
                ml={1}
                onClick={axiosReferencias}
                leftIcon={<MdUpgrade />}
                color="white"
              >
                {t("Actualizar lista")}
          </Button>
          {loader ?
          <TableContainer mt={5} bg="white">
            <Table variant='striped' colorScheme='gray' boxShadow="md">
              <TableCaption>
                <ReactPaginate
                     breakLabel="..."
                     nextLabel={<MdArrowForward/>}
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={5}
                     pageCount={pageCount}
                     breakClassName="list-group-item"
                     previousLabel={<MdArrowBack/>}
                     renderOnZeroPageCount={null}
                      />
              </TableCaption>
              <Thead p={10}>
                <Tr bg="gray.600">
                  <Th py={6} color="gray.300">{t("#")}</Th>
                  <Th py={6} color="gray.300">{t("Efeito")}</Th>
                  <Th py={6} color="gray.300">{t("Gerado em")}</Th>
                  <Th py={6} color="gray.300">{t("Ficheiro")}</Th>
                  <Th py={6} color="gray.300">{t("Download")}</Th>
                  <Th py={6} color="gray.300">{t("Acção")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map(el =>
                    <Tr color="blackAlpha.700">
                      <Td>{el.id_relatorio}</Td>
                      <Td>{el.para}</Td>
                      <Td>{new Date(el.gerado_quando).toLocaleDateString()}</Td>
                      <Td>{el.file_gerado}</Td>
                      <Td><a target='_blank' download={`${CONFIG.HOST_API}/reports/`+el.file_gerado} href={`${CONFIG.HOST_API}/reports/`+el.file_gerado}><MdOutlineFileDownload size={30} /></a></Td>
                      <Td>
                      <ModalRemoveRelatorios
                              registo={el}
                              excluirRef={excluirRef}
                            />
                      </Td>
                    </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          :
          <Center>
            {/* <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            /> */}
            <Loader />
          </Center>}
          </Box>
      </Box>
      :<Center width="100%">
        <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
            <MdError size={200} color="red"/>
            <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
            <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
        </Flex>
      </Center>}
      <Box mt={5} color="gray.700" textAlign="center">
            <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
            <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
        </Box>
      </Flex>
    </Box>
  </Flex>
</>
)
}

export default Relatorios