import React, {useState, useEffect} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal, Text, Divider, Box, Flex, Heading,
    ModalOverlay,
    ModalContent,
    Tooltip,
    ModalHeader,
    ModalBody,useToast,
    ModalCloseButton} from '@chakra-ui/react'
import {MdGridView, MdPassword, MdRefresh, MdRequestPage, MdRequestQuote, MdRestore} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'

function ModalMudarSenha ({texto, actualizar}) {
    const [open, setOpen] = useState(false)
    const [senha, setSenha] = useState('')
    const [resp, setResp] = useState([])
    const [repetirsenha, setRepetirsenha] = useState('')
    const {t, i18n} = useTranslation()
    const toast = useToast()
    const onOpen = () => {
            setOpen(!open)

    }


    // const respostas = () => {
    //   const parde = JSON.parse(texto.resposta)
    //   for (const iterator in parde) {
    //     const fn = resp.find( i => i == `${iterator} = ${parde[iterator]}`)      
    //     setResp(pv => [...pv, `${iterator} = ${parde[iterator]}`])
    //   }

    // }
  
    const reenviarRequest = async () => {

      toast({
        title: 'Reenviar requisição',
        description: "Á processar",
        status: 'loading',
        duration: 2500,
        isClosable: true,
      })

      try {

        const dadosT = {
          id_logreq: texto.id_logreq,
          id_evento: Number(texto.evento),
          entidade_aceite: texto.entidade_aceite,
          dados: JSON.parse(texto.requesicao)
        }
          
            const responseR = await AXIOS.post('/logs-requisicao/reenvio', dadosT);

            if(responseR.data.status == "sucesso"){
                setTimeout(()=>{
                  toast({
                    title: 'Reenviar requisição',
                    description: responseR.data.mensagem, 
                    status: 'success',
                    duration: 2500,
                    isClosable: true,
                  })
      
                },2800)

                actualizar()
                onOpen()
            }else {
              setTimeout(()=>{
                toast({
                  title: 'Reenviar requisição',
                  description: responseR.data.mensagem, 
                  status: 'error',
                  duration: 2500,
                  isClosable: true,
                })
            },2800)
            }
            
        }
        catch (error) {
          console.log("STATUS",error);
          setTimeout(()=>{
            toast({
              title: 'Reenviar requisição',
              description: error.message,
              status: 'error',
              duration: 2500,
              isClosable: true,
            })
        },2800)
        }
      }

      useEffect(()=>{
        // respostas()
    },[])
      
    return (
      <>
        <Button leftIcon={<MdRestore/>}  onClick={onOpen} bg='blue.400' color="white" variant='solid'>Ver</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="6xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Resposta da requisição | <span style={{fontWeight:900}}>{texto.statusCode}</span></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Heading>Resposta</Heading>
            <Text mb={5} fontSize={14}><Tooltip hasArrow label={texto.resposta} bg='blue.400'><span>{texto.resposta.substr(0,500)}</span></Tooltip></Text>
            <Divider />
            <Heading mt={5}>Requisição</Heading>
            <Text mt={5} fontWeight={900} fontSize={14}><Tooltip hasArrow label={texto.requesicao} bg='blue.400'><span>{texto.requesicao}</span></Tooltip></Text>
            <Box mt={10} bg="gray.100">
              <Flex justifyContent="space-between">
                  <Box p={5}>
                      <Text>ID LOG: <span style={{fontWeight:700}}>{texto.log_enviado}</span></Text>
                      <Text>URL: <span style={{fontWeight:700}}>{texto.url}</span></Text>
                      <Text>Estado da requisição: <span style={{fontWeight:700,color: texto.estado_do_resultado == "Resolvido" ? "green" : texto.estado_do_resultado == "Cancelado" ? "red" : "blue" }}>{texto.estado_do_resultado}</span></Text>
                      <Text>Escala em: <span style={{fontWeight:700}}>{texto.tempo} {texto.repetido_em}</span></Text>
                      <Text>Tentativas Feitas: <span style={{fontWeight:700}}>{texto.tentativas_requisicao}</span></Text>
                      <Text>Tipo de resposta: <span style={{fontWeight:700}}>{texto.tipo_resposta}</span></Text>
                  </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button ml={3} onClick={onOpen}>Sair</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
