import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Checkbox,
  Center,
  Spinner,
  useToast,
  Input,
  Heading
} from "@chakra-ui/react";
import {
  MdCached,
  MdClose,
  MdArrowBack,
  MdArrowForward,
  MdEdit,
  MdUpgrade,
  MdSupervisorAccount,
  MdError,
  MdSupervisedUserCircle
} from "react-icons/md";
import { FcProcess } from "react-icons/fc";
import { HiStatusOnline, HiStatusOffline } from "react-icons/hi";
import {
  BotaoTop,
  Drawer,
  DrawerAfiliados,
  Loader,
  ModalGerarFileRef,
  ModalRemoveRef,
  ModalReportReferencias,
  ModalReportReferenciasGerarXML,
  ModalViewAfiliado,
  ModalViewReference,
  NavBar,
  SideBarMenu
} from "../Components/index";

import AXIOS from '../Config/axiosConfig'
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CONFIG from "../Config";
import { useTranslation } from "react-i18next";
import { BsBank, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { RiPassExpiredFill } from "react-icons/ri";

function Referencias() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()
  const {id} = useParams();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState({});
  const [checkBox, setCheckBox] = useState([]);
  const [check, setcheck] = useState(false);
  const refREF = useRef(null);
  const [loader, setLoader] = useState(false);
  const toast = useToast();

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;

      const referencia = await AXIOS.get(
        `/afiliados/${id}/afiliados-identificacao/entidade/${entidade}/afiliados`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  const actualizarReferencia = async () => {
    try {
      setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;

      const referencia = await AXIOS.get(
        `/afiliados/entidade/${entidade}`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const excluirRef = async (ref) => {
    try {
    
      if((ref.estado_atm == "Á Processar") || (ref.estado_atm == "Activo")){
          
          toast({
            title: t("Exclusão de afiliado"),
            description: t("Não podemos excluir a sua referencia com este estado em processo ou activo"),
            status: "error",
            duration: 5000,
            isClosable: true
          });
          
          return
      }
      
      const refs = referencias.filter(
        (r) => r.id_afiliado != ref.id_afiliado
      );
      
      const res = await AXIOS.delete(
        `/afiliados/${ref.id_afiliado}`
      );
      if (res.data.status == "sucesso") {
        setReferencias(refs);
        toast({
          title: t("Exclusão de afiliado"),
          description: t("Conseguimos excluir com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Exclusão de afiliado"),
          description: res.data.mensagem,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };
  

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const selectdItem = (evt) => {
    const tipo = evt.target.value.toLocaleLowerCase();
    const modified = referencias.map((item) => {
      return {
        ...item,
        num_referencia: item.num_referencia,
        referencia_do_montante: item.referencia_do_montante,
        data_limite_pagamento: item.data_limite_pagamento,
        data_inicio_de_pagamento: item.data_inicio_de_pagamento,
        codigo_de_processamento:
          item.codigo_de_processamento == 80 ? "Activo" : "Inactivo",
        registo_produto: item.registo_produto
      };
    });
    const filtered = modified.filter(
      (item) =>
        JSON.stringify(item)
          .toLocaleLowerCase()
          .replaceAll("T", " ")
          .replaceAll("Z", "")
          .replaceAll("-", "/")
          .indexOf(tipo) > -1
    );
    setReferenciasFilter(filtered);
  };

  const activateEndpoint = async (id, valor) => {
    try {
      const values = {};
      values.activacao = valor;
      const res = await AXIOS.patch(`/afiliados/${id}`, {
        ...values
      });

      if (res.data.status === "sucesso") {
        toast({
          title: t("Ativação de afiliado"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Ativação de afiliado"),
          description: t(res.data.mensagem),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  let permissao = user?.pagina_afiliado == "true";

  if(user?.pagina_afiliado) permissao = user?.pagina_afiliado == "true"
  else permissao = user.numero_entidade

  return (
    <>
      <Flex color="white" bg="white" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenu/>
        <BotaoTop/>
        <Box flex={1}>
          <NavBar titulo={t(`Grupo de afiliados - ${currentItems[0]?.nome_identificacao || id}`)} imagem={<MdSupervisorAccount size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
          >
          {permissao ?   <Box px={10}>
              {/* <Text
                fontSize={50}
                color="blackAlpha.600"
                fontWeight="bold"
                mb={8}
              >
                {t("Minhas referências")}
              </Text> */}
              <Flex mt={5} alignItems="center" justifyContent="space-between">
                <Box>
                  {/* <DrawerAfiliados axiosReferencias={axiosReferencias} />{" "} */}
                  {/* {checkBox.length > 1 && (
                    <Button
                      ml={2}
                      mr={2}
                      leftIcon={<MdCached />}
                      onClick={encaminharVarios}
                      colorScheme="orange"
                      variant="solid"
                    >
                      {t("Gerar ficheiros")}
                    </Button>
                  )} */}
                  {/* <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarReferencia}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
                  </Button> */}
                  <Button
                    bg="orange.400"
                    _hover={{ backgroundColor: "orange.600" }}
                    ml={1}
                    as={Link}
                    to="/afliados-indetificacao"
                    leftIcon={<MdSupervisedUserCircle />}
                    color="white"
                  >
                    {t("Identificação de afiliados")}	
                  </Button>
                </Box>
                <Flex color="gray.500" alignItems="center">
                  <Text></Text>{" "}
                  <Input
                    w="sm"
                    type="search"
                    bg="white"
                    placeholder={`${t('Pesquise')} ...`}
                    ml={1}
                    onChange={selectdItem}
                  />
                </Flex>
              </Flex>

              {/* <Text mt={2} color="black" fontWeight="bold">
                {t("Itens selecionados")}: {checkBox.length}
              </Text> */}
              {loader ? (
                <TableContainer mt={5} bg="white" boxShadow="md">
                  <Table variant="striped" colorScheme="gray">
                    <TableCaption>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<MdArrowForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        breakClassName="list-group-item"
                        previousLabel={<MdArrowBack />}
                        renderOnZeroPageCount={null}
                      />
                    </TableCaption>
                    <Thead p={10}>
                      <Tr bg="gray.600">
                        {/*<Th py={6} color="gray.300">
                          <Checkbox
                            onChange={() => (evt) =>
                              evt.target.checked
                                ? setcheck(true)
                                : setcheck(false)}
                          />
                        </Th>*/}
                        <Th py={6} color="gray.300">
                          {t("#")}
                        </Th>
                        <Th colSpan={1} py={6} color="gray.300">
                          {t("Nome do afiliado")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("Criação")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("Activação")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("User Name")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("Acção")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentItems.map((el) => (
                        <Tr key={el.id_afiliado} color="blackAlpha.700">
                          <Td>
                            {el.id_afiliado}
                          </Td>
                          <Td fontWeight={700} >
                            { el.nome_afiliado}
                          </Td>                          
                          <Td>
                            {new Date(el.gerado_em_).toLocaleDateString()}{" "}
                            {new Date(el.gerado_em_).toLocaleTimeString()}
                          </Td>
                          <Td>
                              {el.activacao == "true" ? (
                                <BsToggleOn
                                  onClick={() =>
                                    activateEndpoint(
                                      el.id_afiliado,
                                      "false"
                                    )
                                  }
                                  size={30}
                                  cursor="pointer"
                                  color="green"
                                />
                              ) : (
                                <BsToggleOff
                                  onClick={() =>
                                    activateEndpoint(el.id_afiliado, "true")
                                  }
                                  size={30}
                                  cursor="pointer"
                                  color="red"
                                />
                              )}
                            </Td>
                          <Td fontWeight={700}>{el.user_name}</Td>
                          <Td>
                            <ModalViewAfiliado registo={el} />
                            <Button
                              size="sm"
                              ml={2}
                              leftIcon={<MdEdit />}
                              as={Link}
                              to={`/afiliado/${el.id_afiliado}`}
                              bg="green.500"
                              color="white"
                              variant="solid"
                            ></Button>
                            <ModalRemoveRef
                              registo={el}
                              excluirRef={excluirRef}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Center mt={10}>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default Referencias;
