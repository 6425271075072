import React, { useEffect, useState } from 'react'
import { MdAutoGraph, MdCorporateFare, MdEmail, MdFilePresent, MdHome, MdLogoDev, MdLogout, MdMonetizationOn, MdNetworkCheck, MdOutlineMiscellaneousServices, MdPeople, MdSupervisedUserCircle, MdTextsms, MdWebhook, MdWhatsapp, MdWifiProtectedSetup} from "react-icons/md"
import { Flex, Box, Divider, Heading, useToast } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import AXIOS from '../Config/axiosConfig'
import { VscReferences } from 'react-icons/vsc'
import { FaMoneyCheckAlt, FaSms, FaUserSecret } from 'react-icons/fa'
import { BiGitPullRequest } from 'react-icons/bi'

function SideBarMenu() {
  const navigate =  useNavigate()
  const [user, setUser] = useState('1')
  const toast = useToast()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  
  const clearLogout = async function(){
      try {
          const res =  await AXIOS.post(`/usuarios/logout`,{id_usuarios:user.id_usuarios})
        
          if(res.data.status == "sucesso"){
            document.cookie = CONFIG.COOKIE_SESSION.ADM + '=; Max-Age=0 ;path=/adm'
            navigate('/adm/login')
          }else{
            toast({
              title: 'Terminar sessão',
              description:  "Não coseguimos fazer login. Verifique sua internet",
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
      } catch (error) {
        toast({
          title: 'Alerta',
          description: "Não conseguimos ter comunicação! Verifique sua internet",
          status: 'error',
        })
      }  
      
      
    }

    useEffect(()=>{
      if(loginValue){
       const value = JSON.parse(loginValue)
       setUser(value.mensagem)
      }
    },[])

  return (
    <Box bg='#17181d' style={{minHeight:'100vh'}} color="gray.600" className='widthSId'>
        <Flex p={6} direction="column" width={300}>
        
            <Flex bg="gray.100" p={5} fontWeight={400} cursor="pointer"  direction="row" alignItems="center"><MdHome size={25}/><Link to="/adm">&nbsp;&nbsp;&nbsp;Dashboard</Link></Flex>
            <Divider my={5} borderColor="gray.700" />
            <Heading fontWeight={700}  my={2} size='sm' textTransform="uppercase" >Gestão</Heading>
            { user?.pagina_entidades == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={5} direction="row" alignItems="center"><MdCorporateFare size={25} /><Link to="/adm/entidades">&nbsp;&nbsp;&nbsp;Entidades</Link></Flex></>}
            { user?.pagina_usuarios == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={5} direction="row" alignItems="center"><MdPeople size={25} /><Link to="/adm/usuarios">&nbsp;&nbsp;&nbsp;Usuarios</Link></Flex></>}   
            { user?.pagina_comprovantes == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={5} direction="row" alignItems="center"><MdFilePresent size={25} /><Link to="/adm/ficheiros">&nbsp;&nbsp;&nbsp;Ficheiros</Link></Flex></>}
            { user?.pagina_fmovs == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={5} direction="row" alignItems="center"><VscReferences size={25} /><Link to="/adm/arquivos-fmovs">&nbsp;&nbsp;&nbsp;Arquivos de movimentos</Link></Flex></>}
            { user?.pagina_custo_uso == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdAutoGraph color="gray.300" size={25}/><Link to="/adm/custo-por-uso">&nbsp;&nbsp;&nbsp;Custo por uso</Link></Flex></>}
            <Divider my={5} borderColor="gray.700" />
            <Heading fontWeight={700} textTransform="uppercase" my={1} size='sm'>Comunicados</Heading>
            { user?.pagina_email == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdEmail size={25}/><Link to="/adm/email-marketing">&nbsp;&nbsp;&nbsp;E-mail marketing</Link></Flex></>}
            { user?.pagina_sms == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdTextsms size={25}/><Link to="/adm/sms-marketing">&nbsp;&nbsp;&nbsp;SMS marketing</Link></Flex></>}
            { user?.pagina_whatsapp == "true" && <> <Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdWhatsapp size={25}/><Link to="/adm/whatsapp-marketing">&nbsp;&nbsp;&nbsp;Whatsapp marketing</Link></Flex></>}
            <Divider my={5} borderColor="gray.700" />
            <Heading fontWeight={700} my={1} size='sm' textTransform="uppercase" >Configuração</Heading>
            { user?.pagina_afiliado == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdSupervisedUserCircle size={25}/><Link to="/adm/afiliados">&nbsp;&nbsp;&nbsp;Afiliados</Link></Flex></>}
            { user?.pagina_sms_notify == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><FaSms size={25}/><Link to="/adm/conf-sms">&nbsp;&nbsp;&nbsp;SMS</Link></Flex></>}
            { user?.pagina_sessao == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdWifiProtectedSetup size={25}/><Link to="/adm/conf-sessao">&nbsp;&nbsp;&nbsp;Sessão</Link></Flex></>}
            { user?.pagina_integracao == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdNetworkCheck size={25}/><Link to="/adm/conf-integracao">&nbsp;&nbsp;&nbsp;Integração</Link></Flex></>}
            { user?.pagina_webhooks == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdWebhook size={25}/><Link to="/adm/webhooks">&nbsp;&nbsp;&nbsp;Webhooks</Link></Flex></>}
            { user?.pagina_tipo_pagamentos == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><FaMoneyCheckAlt size={25}/><Link to="/adm/conf-pagamentos">&nbsp;&nbsp;&nbsp;Pagamentos</Link></Flex></>}
            { user?.pagina_servicos == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdOutlineMiscellaneousServices color="gray.300" size={25}/><Link to="/adm/servicos">&nbsp;&nbsp;&nbsp;Serviços</Link></Flex></>}
            { user?.pagina_transacoes_percentual == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdMonetizationOn color="gray.300" size={25}/><Link to="/adm/percentual">&nbsp;&nbsp;&nbsp;Transacções</Link></Flex></>}
            { user?.pagina_permissoes == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><FaUserSecret color="gray.300" size={25}/><Link to="/adm/permissoes">&nbsp;&nbsp;&nbsp;Permissões</Link></Flex></>}
            <Divider my={5} borderColor="gray.700" />
            <Heading fontWeight={700} my={1} size='sm' textTransform="uppercase" >Logs</Heading>
            { user?.pagina_requisicoes == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><BiGitPullRequest size={25}/><Link to="/adm/logs-requisicao">&nbsp;&nbsp;&nbsp;Requisição</Link></Flex></>}
            { user?.pagina_req_geral == "true" && <><Flex fontWeight={400} cursor="pointer" color="gray.300" mt={3} direction="row" alignItems="center"><MdLogoDev size={25}/><Link to="/adm/logs">&nbsp;&nbsp;&nbsp;Geral</Link></Flex></>}
            <Divider my={5} borderColor="gray.700" />
            <Heading fontWeight={700} my={1} size='sm' textTransform="uppercase" >Sessão</Heading>            
            <Flex fontWeight={400} cursor="pointer" color="gray.300" onClick={clearLogout} mt={3} direction="row" alignItems="center"><MdLogout size={25}/>&nbsp;&nbsp;&nbsp;Sair</Flex>
            {/* <Box width={300}></Box> */}
        </Flex>
  </Box>
  )
}

export default SideBarMenu
