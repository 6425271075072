import React, {useEffect, useState} from 'react'
import { Button, ModalFooter,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Heading, Card, CardBody, Flex} from '@chakra-ui/react'
import {MdFileDownload} from 'react-icons/md'
import AXIOS from '../Config/axiosConfig'
import { useTranslation } from 'react-i18next'

function ModalDownloadFiles ({registo}) {
    const [open, setOpen] = useState(false)
    const [files, setFiles] = useState([])
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    const downlodFile = async (idficheiros) => {
        await AXIOS.get(`/ficheiros/download/${idficheiros}`)        
        window.open(`/ficheiros/download/${idficheiros}`,'_blank')
    }
    
    // useEffect(()=>{
    //   (async ()=>{
    //       const res = await AXIOS.get(`/ficheiros/sequencia/${registo.sequencia}`)
    //       setFiles(res.data.mensagem)
    //   })()
    // },[])

    return (
      <>
        <Button ml={2} size="sm" leftIcon={<MdFileDownload />}  onClick={onOpen} bg='blue.400' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>Ficheiros para fazer download</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="row" justifyContent="space-around">
                  {files.map( el => 
                    <Card>
                      <CardBody p={10}>
                        <Heading>{el.tipo}</Heading>
                      </CardBody>
                      <Button onClick={()=>downlodFile(el.idficheiros)} leftIcon={<MdFileDownload/>} />
                    </Card>
                  )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button bg="red.400" color="white" ml={2} onClick={onOpen} >Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalDownloadFiles 
