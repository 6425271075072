import { useEffect, useRef, useState } from 'react'
import { MdClose, MdDelete, MdError, MdPeople, MdUpdate, MdUpgrade } from "react-icons/md"
import {  BotaoTop, DrawerProdutos, DrawerTipoProdutos, ModalMudarSenha, NavBar, NavBarAdm, SideBarMenu, SideBarMenuAdm } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Textarea, Select, Center, Spinner, useToast, Divider} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'


function EditarEntidades() {

    const [registo, setReferencias] = useState({})    
    const [produtosTipo, setProdutosTipo] = useState([])    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const REFForm = useRef(null)
    
    const toast = useToast()
    
    async function axiosReferencias(){
      setLoader(false)
      const referencia = await AXIOS.get(`/clientes/${id}`);
      const produto_tipo = await AXIOS.get(`/registro-produtos-cliente/cliente/${id}`)
      
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        setReferencias(referencia.data.mensagem[0])
      }
      
      if(produto_tipo.data.status === "erro")
        setProdutosTipo([])
      else{
        setProdutosTipo(produto_tipo.data.mensagem[0].registo)
      }
      
      setLoader(true)
      
      if(referencia.data.mensagem.length){
            console.log(REFForm.current)
      }else{
        navigate('/adm')
      }

          
  }    
  
  
  const formik = useFormik({
    initialValues:{
      nome_empresa: '',
      nif:'',
      contacto:'',
      email: '',
      senha: '',
      numero_entidade: '',
      num_padrao_referencias: '',
      responsavel: ''
    },
    onSubmit: async (values)=> {
      const res =  await AXIOS.patch(`/clientes/${id}`,values)
      
      if(res.data.status === "sucesso"){
        axiosReferencias()
        
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de entidade',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },2000)
      }else{
        toast({
          title: 'Actualização de entidade',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
      
    },
  })
  
  
  
  
  
    useEffect(()=>{
       
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/adm/login')
          }
          
          // if(id) navigate('/referencias')
    },[])
    
  const excluirTipoProd = async function(value){
      const res = await AXIOS.delete(`/registro-produtos-cliente/${value}`)

      toast({
        title: 'Exlusão de tipo de produtos',
        description: "Á carregar o pedido",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })
      
      if(res.data.status == "sucesso"){
        toast({
          title: 'Exlusão de tipo de produtos',
          description: res.data.mensagem,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }else{
        toast({
          title: 'Exlusão de tipo de produtos',
          description: res.data.mensagem,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
      
      axiosReferencias(); 
  }
      
  
  formik.initialValues.nome_empresa = registo.nome_empresa
  formik.initialValues.nif = registo.nif
  formik.initialValues.contacto = registo.contacto
  formik.initialValues.email = registo.email
  formik.initialValues.numero_entidade = registo.numero_entidade
  formik.initialValues.num_padrao_referencias = registo.num_padrao_referencias
  formik.initialValues.responsavel = registo.responsavel
  
  let permissao = user?.pagina_servicos == "true";

  if(user?.pagina_servicos) permissao = user?.pagina_servicos == "true"
  else permissao = user.todas_areas ? true : false;

  return (
    <>
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm/>
      <BotaoTop/>
      <Box flex={1}>
          <NavBarAdm/>
          {
          
          permissao

          ? 
          <Box px={10} color="black" mt={10}>
              <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={1}>Parametrizar serviços e produtos</Text>
              <Text fontSize={25} color="gray.900" fontWeight="bold" mb={8}>({registo.nome_empresa})</Text>
              <Button
                  bg="gray.600"
                  _hover={{ backgroundColor: "gray.700" }}
                  mt={3}
                  onClick={axiosReferencias}
                  leftIcon={<MdUpgrade />}
                  color="white"
                  mb={3}
                >
                  Actualizar lista
                </Button>
              {loader ?
              <Flex color="black" my={5} direction="column">
                <DrawerTipoProdutos axiosReferencias={axiosReferencias} entidade={registo.id_clientes} />
                
                  <Box mt={5}>
                      <Card>
                            <CardBody>
                              <Stack divider={<StackDivider />} spacing='4'>
                              {produtosTipo.map(rg => <Box>
                                  <Heading size='md' _hover={{color:"blue.500"}} cursor="pointer" textTransform='uppercase'>
                                      <DrawerProdutos produto={rg.id_tipo_produto_clientes} entidadeID={registo.numero_entidade} texto={rg.registo_produto} />
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    Clique na descrição para adicionar produtos.
                                  </Text>
                                  <MdDelete cursor="pointer" onClick={()=>excluirTipoProd(rg.id_tipo_produto_clientes)} size={45} color='red'/>
                                </Box>)}
                              </Stack>
                            </CardBody>
                          </Card>
                  </Box>
              </Flex>
              :
              <Center>
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              />
            </Center>}
          </Box>

          :
          
        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
      }
      </Box>
    </Flex>
  </>
  )
}

export default EditarEntidades