import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, RadioGroup, Alert, Heading,
  Stack,
  Radio,FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast, Tooltip, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {  MdAdd, MdFilePresent, MdFileUpload, MdPeople, MdSms, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { FaSms } from 'react-icons/fa'

export default function PlacementExample({axiosReferenciass}) {
    const [placement] = useState('right')
    const [referencias, setReferencias] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()

    const refRef = useRef(null)
    const refPag = useRef(null)
    
    
    async function axiosReferencias(){
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/clientes/sms-config-mensagens/entidade/${entidade}`)
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
      }
      
          
  }
  
    
    const formik = useFormik({
      initialValues:{
        mensagem:'',
        tipo_env: 'Referencias',
      },
      onSubmit: async (values)=> {
      
      
        const res =  await AXIOS.post(`/clientes/sms-config-mensagens`,
        {
          "env_entidade":user.numero_entidade,
          ...values
      }) 

        toast({
          title: 'Gerar template de SMS',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            setIsOpen(false)
          },1000)
          
          
          
          setTimeout(()=>{
            toast({
              title: 'Gerar template de SMS',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferenciass()
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar template de SMS',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        axiosReferencias() 
      },
    })
    const formikPag = useFormik({
      initialValues:{
        mensagem:'',
        tipo_env:'Pagamentos',
      },
      onSubmit: async (values)=> {
      
      
        const res =  await AXIOS.post(`/clientes/sms-config-mensagens`,
        {
          "env_entidade":user.numero_entidade,
          ...values,
      }) 
      

        toast({
          title: 'Gerar template de SMS',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 3000,
          isClosable: true,
        })
        
        if(res.data.status === "sucesso"){
          
          setTimeout(()=>{
            setIsOpen(false)
          },1000)
          
          
          
          setTimeout(()=>{
            toast({
              title: 'Gerar template de SMS',
              description: "Gerado com sucesso",
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferenciass()
          },3500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Gerar template de SMS',
              description: "Aconteceu, um problema",
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        axiosReferencias() 
      },
    })


    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      axiosReferencias();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }
      axiosReferencias()
      
  },[])

  if(referencias.length){
    for (const sms of referencias) {
        if(sms.tipo_env == "Pagamentos"){
          formikPag.initialValues.mensagem = sms.mensagem
        }
        
        if(sms.tipo_env == "Referencias"){
            formik.initialValues.mensagem = sms.mensagem
        }
    }
  }

  const calcularCaracteresRestantes = () =>{
    const total = 100;
    const restante = total - formik.values.mensagem?.length
    return restante
  }

  const calcularCaracteresRestantesPAG = () =>{
    const total = 100;
    const restante = total - formikPag.values.mensagem?.length
    return restante
  }

  const invocarGanchosRef = (valor) =>{
        refRef.current.value += " " + valor; 
        formik.initialValues.mensagem = refRef.current.value; 
  }

  const invocarGanchosPag = (valor) =>{
      refPag.current.value += " " + valor; 
      formikPag.initialValues.mensagem = refRef.current.value; 
  }
  
    return (
      <>
        <Button leftIcon={<FaSms/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          {t("Configurar mensagem")}
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Configuração de notificação de mensagens")}</DrawerHeader>
            <DrawerBody>

              <Tabs variant='enclosed'>
                <TabList>
                  <Tab>Referências</Tab>
                  <Tab>Pagamentos</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <form onSubmit={formik.handleSubmit}>
                    <Box>
                      <Heading fontSize={16}>Ganchos</Heading>
                      <Flex wrap="wrap">
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[expiracao]")} padding={1} backgroundColor="orange.500" mt={2} >[expiracao]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[producao]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[producao]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[referencia]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[referencia]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[entidade]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[entidade]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[minimo]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[minimo]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[maximo]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[maximo]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[campo1]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[campo1]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[campo2]")} padding={1} backgroundColor="orange.500" mt={2} ml={2}>[campo2]</Text>
                        <Text color="white" cursor="pointer" onClick={()=> invocarGanchosRef("[campo3]")} padding={1} backgroundColor="orange.500" ml={2} mt={2}>[campo3]</Text>
                      </Flex>
                    </Box>
                    <Box bg='white'>
                      <FormControl mt={4}>
                          <FormLabel>{t("Mensagem")}</FormLabel>
                            <Textarea ref={refRef} minLength={10} maxLength={100} rows={10} name='mensagem' onChange={formik.handleChange} value={formik.values.mensagem} />
                            <Text fontWeight={900} fontSize={15}>Caracteres restantes: {calcularCaracteresRestantes()}</Text>
                      </FormControl>
                      
                      <Button leftIcon={<MdSms/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Configurar")}</Button>
                  
                  </Box> 
                    </form>
                  </TabPanel>
                  <TabPanel>
                  <form onSubmit={formikPag.handleSubmit}>
                  <Box>
                      <Heading fontSize={16}>Ganchos</Heading>
                      <Flex wrap="wrap">
                        <Text color="white" onClick={()=> invocarGanchosPag("[data]")}  cursor="pointer" padding={1} backgroundColor="orange.500" mt={2}>[data]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[hora]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[hora]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[local]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[local]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[referencia]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[referencia]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[entidade]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[entidade]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[montante]")} cursor="pointer" padding={1} backgroundColor="orange.500" ml={2} mt={2}>[montante]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[campo1]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[campo1]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[campo2]")} cursor="pointer" padding={1} backgroundColor="orange.500" mt={2} ml={2}>[campo2]</Text>
                        <Text color="white" onClick={()=> invocarGanchosPag("[campo3]")} cursor="pointer" padding={1} backgroundColor="orange.500" ml={2} mt={2}>[campo3]</Text>
                      </Flex>
                    </Box>
                    <Box bg='white'>
                      <FormControl mt={4}>
                          <FormLabel>{t("Mensagem")}</FormLabel>
                            <Textarea ref={refPag} minLength={10} maxLength={100}  rows={10} name='mensagem' onChange={formikPag.handleChange} value={formikPag.values.mensagem} />
                            <Text fontWeight={900} fontSize={15}>Caracteres restantes: {calcularCaracteresRestantesPAG()}</Text>
                      </FormControl>
                      
                      <Button leftIcon={<MdSms/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Configurar")}</Button>
                  
                  </Box> 
                    </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>

          </DrawerContent>
        </Drawer>
      </>
    )
  }