import React, {useState, useEffect, useRef} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal, Flex,Divider,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Heading,
    HStack,
    PinInput,
    PinInputField,
    Alert,
    Text,
    Box,
    ModalCloseButton,
  useToast} from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import {MdDelete, MdEdit, MdMonetizationOn, MdMoney, MdMonitor, MdPassword} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalMudarSenha ({gravarValoresFixos, produto, tipoProduto, entidade}) {
    const [open, setOpen] = useState(false)
    const [referencias, setReferencias] = useState([])
    const [valor1, setValor1] = useState("")
    const [userCod, setUserCod] = useState({texto1:'',texto2:'',texto3:'',texto4:'',texto5:'',texto6:'',texto7:'',texto8:'',texto9:'',texto10:''})
    const {t, i18n} = useTranslation()
    const toast = useToast()


    const onOpen = () => {
            setOpen(!open)
    }

    // fixo1.current.setAttribute("value","registo.nome_empresa")
    // montante1.current.setAttribute("value","333")

    async function axiosReferencias(){
      const referencia = await AXIOS.get(`/produtos-valores-fixos/entidade/${entidade}`);
      
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length)
          setReferencias(referencia.data.mensagem)
        else
        setReferencias([])
      }
          
  }  

  const excluirFixo = async function(id_fixo){
    try {
      const res = await AXIOS.delete(`/produtos-valores-fixos/${id_fixo}`)

      toast({
        title: 'Exlusão de valor fixo',
        description: "Á carregar o pedido",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })
      
      setTimeout(function(){
        if(res.data.status == "sucesso"){
          toast({
            title: 'Exlusão de valor fixo',
            description: res.data.mensagem,
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
        }else{
          toast({
            title: 'Exlusão de valor fixo',
            description: res.data.mensagem,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
        
        axiosReferencias(); 

      }, 1500)

    } catch (error) {
      toast({
        title: 'Exlusão de valor fixo',
        description: "Aconteceu algo! Tente de novo.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

    
    
    useEffect(()=>{

      axiosReferencias()
      
    },[])
    
    
    return (
      <>
        <MdMonetizationOn size={25} onClick={onOpen} color='green' cursor="pointer" />
  
        <Modal isOpen={open} onClose={onOpen} size="2xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Alteração do produto</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Heading my={3} size="md">{produto.produto}</Heading>
            <Heading color="blue.500" my={3} size="sm">{tipoProduto}</Heading>
            {(produto.montantes_fixo == "SIM" && tipoProduto == "Pagamento/Carregamento") && <> 
            <Divider my={5} />
            <Flex justifyContent="space-between" mt={5}>
                  <FormControl>
                    <FormLabel>Descrição</FormLabel>
                    <Input type='text' maxLength={20} value={valor1} onChange={(evt) => setValor1(v => (evt.target.value))} />
                  </FormControl>
            </Flex>    
            <HStack>
                    <FormLabel>Montante</FormLabel>
                    <PinInput size="md" errorBorderColor = "red.500" name='Montante_Pago'>
                      <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto1:evt.target.value}) ) }/>
                      <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto2:evt.target.value}) ) }/>
                      <br/>
                      <span style={{color:"#000", fontSize:35}}>.</span>
                      <br/>
                      <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto3:evt.target.value}) ) }/>
                      <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto4:evt.target.value}) ) }/>
                      <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto5:evt.target.value}) ) }/>
                      <br/>
                      <span style={{color:"#000", fontSize:35}}>.</span>
                      <br/>
                      <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto6:evt.target.value}) ) }/>
                      <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto7:evt.target.value}) ) }/>
                      <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto8:evt.target.value}) ) }/>
                      <br/>
                      <span style={{color:"#000", fontSize:35}}>,</span>
                      <br/>
                      <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto9:evt.target.value}) ) }/>
                      <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto10:evt.target.value}) ) }/>
                    </PinInput>
              </HStack>

              <Divider my={5}/>
              {referencias.map(el => 
                <Alert display="flex" bg="white" borderLeft="5px solid" borderColor="blue.400" justifyContent="space-between" mt={2}>
                  <Heading size="sm">{el.descricao}</Heading>
                  <Box display="flex" justifyContent="space-between">
                    <Text>{el.montante_fixo}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <MdDelete size={27} cursor="pointer" color='red'  onClick={() => excluirFixo(el.id_fixo)}/>
                  </Box>

                </Alert>
              )}

            </>
          }
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{ gravarValoresFixos(produto,valor1, userCod)}} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
