import { Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Button, Center, Spinner, useToast } from '@chakra-ui/react'
  import AXIOS from '../../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import { MdArrowBack, MdArrowForward, MdEdit, MdUpgrade, MdLock, MdLockOpen, MdError } from "react-icons/md"
import { BotaoTop, DrawerEntidades,  DrawerUsuarios,  ModalRemoveEntidade, ModalViewEntidadesAdm, NavBarAdm, SideBarMenuAdm } from '../../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'
import { FaUserSecret } from 'react-icons/fa'


function Usuarios() {

  const [referencias, setReferencias] = useState([])    
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState({})
  const [checkBox, setCheckBox] = useState([])
  const [check, setcheck] = useState(false)
  const refREF = useRef(null)
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  
  async function axiosReferencias(){
    
    const referencia = await AXIOS.get(`/usuarios`)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem)
    }
    
    setLoader(true)
        
}

const actualizarUsuarios = async ()=>{

  setLoader(false)
    
  const referencia = await AXIOS.get(`/usuarios`)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
    setReferencias(referencia.data.mensagem)
    setReferenciasFilter(referencia.data.mensagem)
  }
  
  setLoader(true)
  console.log(referencia.data)
}

const excluirUsuarios = async (ref)=>{
    const refs = referencias.filter(r =>r.id_usuarios != ref.id_usuarios)
    const res = await AXIOS.delete(`/usuarios/${ref.id_usuarios}`)

    toast({
      title: 'Exclusão de usuario',
      description: "Á carregar",
      status: 'loading',
      duration: 4000,
      isClosable: true,
    })

    if(res.data.status == "sucesso"){

      setTimeout(()=>{
      axiosReferencias()
        toast({
          title: 'Exclusão de usuario',
          description: res.data.mensagem,
          status: 'info',
          duration: 5000,
          isClosable: true,
        })
      },5000)
    }else{
      setTimeout(()=>{
        toast({
          title: 'Exclusão de usuario',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },5000)
    }
}


  useEffect(()=>{
     
      axiosReferencias(); 
        
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
          navigate('/adm/login')
        }

  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let permissao = user?.pagina_usuarios == "true";

  if(user?.pagina_usuarios) permissao = user?.pagina_usuarios == "true"
  else permissao = user.todas_areas ? true : false;
  
return (
<>
<Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
          {
           permissao
          ? 
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Meus usuarios</Text>
          <DrawerUsuarios axiosReferencias={axiosReferencias}  />
          <Button onClick={actualizarUsuarios} bg="gray.600" color="white" 
                    _hover={{ backgroundColor: "gray.700" }} ml={2} leftIcon={<MdUpgrade/>}>Actualizar lista</Button>
        {loader ?
        <TableContainer my={5} bg="white">
          <Table variant='striped' colorScheme='gray'>
            <TableCaption>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
            </TableCaption>
            <Thead p={10}>
              <Tr bg="gray.600">
                <Th  py={6} color="gray.300">Nome do usuario</Th>
                <Th  py={6} color="gray.300">Como</Th>
                <Th  py={6} color="gray.300">Email</Th>
                <Th  py={6} color="gray.300">Cadastrado em</Th>
                <Th  py={6} color="gray.300">Actualizado em</Th>
                <Th  py={6} color="gray.300">Acção</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map(el =>
                  <Tr key={el.id_usuarios} color="blackAlpha.700">
                    <Td fontWeight={700}>{el.nome_usuario}</Td>
                    <Td>{el.tipo_nome_usuario}</Td>
                    <Td>{el.email_}</Td>
                    <Td>{new Date(el.cadastrado_em).toLocaleDateString()} {new Date(el.cadastrado_em).toLocaleTimeString()}</Td>
                    <Td>{new Date(el.actualizado_em).toLocaleDateString()} {new Date(el.actualizado_em).toLocaleTimeString()}</Td>
                    <Td>
                        <Button ml={2} leftIcon={<FaUserSecret />} as={Link} to={`/adm/permissao/${el.id_usuarios}`} bg='gray.900' color="white" variant='solid'></Button>                          
                        <Button ml={2} leftIcon={<MdEdit />} as={Link} to={`/adm/usuarios/${el.id_usuarios}`} bg='green.500' color="white" variant='solid'></Button>                          
                        <ModalRemoveEntidade registo={el} excluirRef={excluirUsuarios}/>
                        
                    </Td>
                  </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        :
        <Center>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>}
          </Box>
          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Usuarios