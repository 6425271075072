import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const doc = new jsPDF();

const download = async (capturedData, filename) => {
    console.log(capturedData); //example- this line of code gives below commented data in console 
    /*<div className="meme-items" id="capture">
        <p className="bottom-text">world</p>
        <p className="top-text">Hello</p>
        <img className="meme-img" src="https://i.imgflip.com/1o00in.jpg" alt="Broken Meme Image">
      </div>*/

    const canvas = await html2canvas(capturedData);
    const imgUrl = canvas.toDataURL("image/png", 1.0 );

    if(window.navigator.msSaveBlob) {// this will only true in IE browser
        ieDownload(canvas);
    }else{
        basicBrowserDownload(imgUrl, filename);
    }

    // doc.text(capturedData.textContent, 10, 10);
    // doc.save("a4.pdf");
}



function basicBrowserDownload(imgUrl, filename) {
    const a = window.document.createElement('a');
    a.style = 'display:none';
    a.download = 'intelize'+filename+'.png';
    a.href = imgUrl;
    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    a.remove();
}

function ieDownload(canvas) {
    window.navigator.msSaveBlob(canvas.msToBlob(), 'Meme.png');
}

export default download;
