import { Flex, Box, Text, Heading, Center, Table,  Tr, Tbody, Td,
  Thead,
  Th,
  Button
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BotaoTop, Loader, NavBarAdm, SideBarMenuAdm } from '../../Components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { MdError } from 'react-icons/md'


function Pagamentos() {

    const {t, i18n} = useTranslation()    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState('1')
    const {id} = useParams(); 
    
    const fetcher = async (url) =>{
      const {data} = await AXIOS.get(url) 
      
      return data
    }

    const { data, error, isLoading } = useSWR(`/dashboard/entidade/${id}`, fetcher)
  

    useEffect(()=>{
      
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/login')
        }
    },[])

    if (isLoading){ 
      return <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
              <Center mt={10}> 
                <Loader /> 
              </Center>
            </Flex>
    }

let permissao = user?.pagina_pagamento == "true";

if(user?.pagina_pagamento) permissao = user?.pagina_pagamento == "true"
else permissao = user.todas_areas ? true : false;
    
  return (
  <>
    
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm />
      <BotaoTop/>
      <Box flex={1}>
      <NavBarAdm/>
      {
         
         permissao

          ? 
      <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
          
          
          <Box px={5} w="100%">
              

              <Box mt={5} rounded={5} flex={1} shadow="md"  className="donut" bg="#111" py={5} px={5} direction="column">
                  <Chart options={{
                          chart: {
                            id: "basic-bar",
                            stacked: true,
                          },
                          xaxis: {
                            categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]
                          },
                          stroke:{
                            curve: "smooth"
                          }
                        }} series={[
                            {
                              name:"Quantidade",
                              data: data.mensagem.somatorioMensais.quantidade
                            },
                            {
                              name:"Montante",
                              data: data.mensagem.somatorioMensais.soma
                            }
                        ]
                        } type="area"  width="100%" height={400}/>
                  </Box>
                  
              
              <Flex color="gray.500" py={3} mt={5} justifyContent="space-between" flexDir="column">
                    
                <Flex flex={0} className="donut" shadow="md" rounded={5}  bg="#111" p={5} direction="column" >
                  <Table variant='unstyled' colorScheme='gray'>
                  <Thead>
                      <Tr>
                        <Th>Periodo</Th>
                        <Th>Data e hora</Th>
                        <Th>Montante</Th>
                        <Th>Quantidade de pagamentos</Th>
                      </Tr>
                    </Thead>
                    <Tbody color="white">
                      {data.mensagem.ultimosPeridos.map((el,i) =>
                          <Tr key={i} color="white">
                            <Td onClick={() => window.location.href = `/adm/periodo-contabilistico/${id}`} fontWeight={700} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>{el.Identificacao_Log_EGR}</Td>
                            <Td>{el.data_periodo} até 20:00</Td>
                            <Td color="whatsapp.300" fontWeight={700}>AKZ {Intl.NumberFormat("PT-br").format(el.somatorio)}</Td>
                            <Td fontWeight={700}>{el.quantidade}</Td>
                          </Tr>
                      ).slice(0,10)}
                    </Tbody>
                  </Table>
                      <Button as={Link} to={`/adm/periodo-contabilistico/${id}`} bg="blue.500" alignSelf="flex-end" color="white" w="25%" mt={4}>Todos os periodos contabilisticos</Button>
                </Flex>

                <Flex mt={10} flex={1} width="100%" className="donut" rounded={5}  bg="#111" p={5} direction="column">
                  <Table variant='unstyled' colorScheme='gray'>
                    <Thead>
                      <Tr>
                        <Th>Referência</Th>
                        <Th>Montante</Th>
                        <Th>data e hora</Th>
                        <Th>Periodo</Th>
                        <Th>Transação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.mensagem.ultimosPagamentos.map((el,i) =>
                          <Tr key={i} color="white">
                            <Td>{el.referencia_do_servico}</Td>
                            <Td color="whatsapp.300" fontWeight={700}>AKZ {Intl.NumberFormat("PT-br").format(el.montante_da_operacao)}</Td>
                            <Td>{new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleDateString()} {new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleTimeString()}</Td>
                            <Td>{el.Identificacao_Log_EGR}</Td>
                            <Td>{el.Identificacao_Log_EGR + el.numero_Log_EGR}</Td>
                          </Tr>
                      ).slice(0,10)}
                    </Tbody>
                  </Table>
                      <Button as={Link} to={`/adm/pagamentos/${id}`} alignSelf="flex-end" bg="blue.500" color="white" w="25%" mt={4}>Todos os pagamentos</Button>
                </Flex>

              </Flex>
            </Box>
       
      </Flex>

      :
                
      <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
        <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
        <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
        <MdError color='red' size={200} />
      </Box>
      }
      </Box>
    </Flex>
  </>
  )
}

export default Pagamentos