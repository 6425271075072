import { Flex, Box, Text, Center, Heading, useToast, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Button} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader, ModalConfgIPS, ModalRemetenteSMSAdm, BotaoTop } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import ReactPaginate from 'react-paginate'
import { MdArrowBack, MdArrowForward, MdEdit, MdError, MdUpgrade } from 'react-icons/md'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast();
  
  async function axiosReferencias(){
    setLoader(false)
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/clientes/`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }
        
    console.log(referencia.data.mensagem)
    setLoader(true)
        
}

  useEffect(()=>{
     
    axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 10
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const activateSMS = async (id, valor) => {

    try {
      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/sms-conf/${id}`, {
        ...values
      });

      toast({
        title: "Ativação da integração via SMS",
        description: "Á aguardar",
        status: "loading",
        duration: 4000,
        isClosable: true
      });

      if (res.data.status === "sucesso") {
        setTimeout(()=>{
          toast({
            title: "Ativação da integração via SMS",
            description: res.data.mensagem,
            status: valor == "false" ? "warning" : "success",
            duration: 2000,
            isClosable: true
          });
          axiosReferencias();
        },5000)
      } else {
        setTimeout(()=>{
          toast({
            title: "Ativação da integração via SMS",
            description: res.data.mensagem,
            status: "error",
            duration: 5000,
            isClosable: true
          });
        },5000)
      }
      
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  let permissao = user?.pagina_sms_notify == "true";

  if(user?.pagina_sms_notify) permissao = user?.pagina_sms_notify == "true"
  else permissao = user.todas_areas ? true : false;
  
return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
      {
          permissao

          ?

          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Configuração de notificações por SMS</Text>
          <Button
            bg="gray.600"
            _hover={{ backgroundColor: "gray.700" }}
            mt={3}
            onClick={axiosReferencias}
            leftIcon={<MdUpgrade />}
            color="white"
            mb={2}
          >
            Actualizar lista
          </Button>
          <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
        {
        loader
        ?
        <Box>
          <Box shadow="sm" rounded={5} bg="white" p={10}>
            <Heading mb={10} fontSize={20}>SMS</Heading>
            
            {currentItems.map((el) => ( <Flex key={el.id_clientes} mt={2} justifyContent="space-between" alignItems="center">
              <Text width="25%" fontWeight={600}>{el.nome_empresa}</Text>              
              <ModalRemetenteSMSAdm axiosReferenciass={axiosReferencias}  entidade={el.numero_entidade}/>
              {el.servico_mensagens == "true" ? (
                  <BsToggleOn width="25%"
                    onClick={() =>
                      activateSMS(el.id_clientes, "false")
                    }
                    size={30}
                    cursor="pointer"
                    color="green"
                  />
                ) : (
                  <BsToggleOff width="25%"
                    onClick={() =>
                      activateSMS(el.id_clientes, "true")
                    }
                    size={30}
                    cursor="pointer"
                    color="red"
                  />
                )}
            </Flex>
            ))}
          </Box>
          
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
        </Box>
        
        :   

          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
          }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes