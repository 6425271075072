import { Box, Flex, Wrap, WrapItem, Avatar, useToast, } from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import { MdLogout } from 'react-icons/md'
import { useNavigate, Link } from 'react-router-dom'
import CONFIG from '../Config'
import AXIOS from '../Config/axiosConfig'

function NavBar() {
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState('1')
    const toast = useToast()
    
    const clearLogout = async function(){
      try {
          const res =  await AXIOS.post(`/usuarios/logout`,{id_usuarios:user.id_usuarios})
        
          if(res.data.status == "sucesso"){
            document.cookie = CONFIG.COOKIE_SESSION.ADM + '=; Max-Age=0 ;path=/adm'
            navigate('/adm/login')
          }else{
            toast({
              title: 'Terminar sessão',
              description:  "Não coseguimos fazer login. Verifique sua internet",
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
      } catch (error) {
        toast({
          title: 'Alerta',
          description: "Não conseguimos ter comunicação! Verifique sua internet",
          status: 'error',
        })
      }  
      
      
    }
useEffect(()=>{
    if(loginValue){
     const value = JSON.parse(loginValue)
     setUser(value.mensagem)
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
},[])
  return (
    <Flex justifyContent="space-between" alignItems="center"  bg="blue.900" p={3}>
        <Box fontWeight={900} fontSize={35} color="white">PORTAL LOGO</Box>
        <Flex justifyContent="space-around" alignItems="center" color="white">
          <><b>{user?.nome_usuario}</b> &nbsp;&nbsp;&nbsp;</>  
                  <Wrap mx={5} cursor="pointer">
                    <WrapItem>
                        <Avatar name={user?.nome_usuario} />
                    </WrapItem>
                  </Wrap>
            <MdLogout cursor="pointer" onClick={clearLogout} size={25}/>
        </Flex>
    </Flex>
  )
}

export default NavBar
