import {
  Flex,
  Box,
  Text,
  Center,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Td,
  Tbody,
  Tr,
  Th,
  Thead,
  TableCaption,
  TableContainer,
  useToast,
  Button,
  Tooltip,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Input
} from "@chakra-ui/react";
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from "react";
import {
  SideBarMenu,
  NavBar,
  DrawerEndpoints,
  ModalRemoveEndpoint,
  Loader,
  ModalVerRespostaEndpint,
  BotaoTop,
  SideBarMenuAdm,
  NavBarAdm,
  DrawerEndpointsAdm
} from "../../Components/index";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Pagination from "react-paginate";
import CONFIG from "../../Config";
import {
  MdArrowBack,
  MdArrowForward,
  MdClose,
  MdCloudDone,
  MdCloudOff,
  MdDoneOutline,
  MdEdit,
  MdError,
  MdLogoDev,
  MdUpgrade,
  MdWebhook
} from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function Webhooks() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')
  const {t, i18n} = useTranslation()  
  const toast = useToast();

  const navigate = useNavigate();
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState({})
  const [loader, setLoader] = useState(false);

  async function axiosReferencias() {
    try {
      
      const referencia = await AXIOS.get(
        `/endpoints/?limite=${quantidade}&pagina=${pagina}`
      );
      

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }
      
      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  async function actualizarEndpints() {
    try {
      setLoader(false);
      
      const referencia = await AXIOS.get(
        `/endpoints/?limite=${quantidade}&pagina=${pagina}`
      );
      

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }
      
      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  useEffect(() => {
    axiosReferencias();         
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
          navigate('/adm/login')
        }

  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const buscarComPaginacao  = async function(){
    try {
      setLoader(false)
      const referencia = await AXIOS.get(`/endpoints/?limite=${quantidade}&pagina=${pagina}`)
      console.log(referencia.data)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
  }

  const excluirEndpoint = async (ref) => {
    try {
      const refs = referencias.filter((r) => r.id_endpoint != ref.id_endpoint);
      const res = await AXIOS.delete(
        `/endpoints/${ref.id_endpoint}`
      );
      if (res.data.status == "sucesso") {
        setReferencias(refs);
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Conseguimos com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Não conseguimos excluir"),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const activateEndpoint = async (id, valor) => {
    try {
      const values = {};
      values.activo = valor;
      const res = await AXIOS.patch(`/endpoints/${id}`, {
        ...values
      });

      if (res.data.status === "sucesso") {
        toast({
          title: t("Ativação de endpoint"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Ativação de endpoint"),
          description: t(res.data.mensagem),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const selectdItem = (evt) =>{
    const tipo = evt.target.value.toLocaleLowerCase()
    const modified = referencias.map(item => {
      
      return {
        ...item,
        "num_referencia": item.num_referencia,
        "referencia_do_montante": item.referencia_do_montante,
        "data_limite_pagamento": item.data_limite_pagamento,
        "data_inicio_de_pagamento": item.data_inicio_de_pagamento,
        "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
        "registo_produto": item.registo_produto
      }
      
    
    })
    const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
    setReferenciasFilter(filtered)
    
}

  let permissao = user?.pagina_webhooks == "true";

  if(user?.pagina_webhooks) permissao = user?.pagina_webhooks == "true"
  else permissao = user.todas_areas ? true : false;

  return (
    <>
      <Flex color='white' bg="gray.300" direction="row">
        <SideBarMenuAdm />
        <BotaoTop/>
        <Box flex={1}>
          <NavBarAdm  titulo={t("Webhooks")} imagem={<MdWebhook size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"} />
          {
            permissao
            
            ?
            <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
            mt={5}
          >
            <Box px={10} color="black" mt={10}>
              {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold">
                Webhooks
              </Text> */}

              <DrawerEndpointsAdm axiosReferencias={axiosReferencias} />
              <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarEndpints}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
                  </Button>
                  <Button
                    bg="orange.500"
                    _hover={{ backgroundColor: "orange.600" }}
                    ml={1}
                    as={Link}
                    to="/adm/webhooks-logs"
                    leftIcon={<MdLogoDev />}
                    color="white"
                  >
                    {t("Logs")}
                  </Button>
                  <Flex mt={5} width="50%" color="gray.600" alignItems="center">
                  <Input
                    w="xs"
                    bg="white"
                    type="search"
                    placeholder={`${t("Pesquise")} ...`}
                    onChange={selectdItem}
                  />
                  <NumberInput
                    name="pagina"
                    onChange={(valueString) => setPagina(parseInt(valueString))}
                    value={pagina}
                    max={paginas}
                    min={1}
                    width={120}
                    ml={2}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Select
                    ml={1}
                    width={200}
                    name="quantidade"
                    onChange={(e) => setQuantidade(e.target.value)}
                    value={quantidade}
                  >
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                  </Select>
                  <Button
                    ml={1}
                    onClick={buscarComPaginacao}
                    colorScheme="orange"
                  >
                    Buscar
                  </Button>
                </Flex>
              {loader ? (
                      <TableContainer my={5} bg="white">
                      <Table variant='striped' colorScheme='gray'>
                          <TableCaption>
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel={<MdArrowForward />}
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              breakClassName="list-group-item"
                              previousLabel={<MdArrowBack />}
                              renderOnZeroPageCount={null}
                            />
                          </TableCaption>
                          <Thead p={10}>
                            <Tr bg="gray.600">
                              <Th py={6} color="gray.300">
                                ID
                              </Th>
                              <Th py={6} color="gray.300">
                                ENTIDADE
                              </Th>
                              <Th py={6} color="gray.300">
                                SERVIÇO
                              </Th>
                              <Th py={6} color="gray.300">
                                URL
                              </Th>
                              <Th py={6} color="gray.300">
                                {t("Evento")}
                              </Th>
                              <Th py={6} color="gray.300">
                                {t("Activação")}
                              </Th>
                              <Th py={6} color="gray.300">
                                {t("Verificar SSL")}
                              </Th>
                              <Th py={6} color="gray.300">
                                {t("Criado em")}
                              </Th>
                              <Th py={6} color="gray.300">
                                {t("Acção")}
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {currentItems.map((el) => (
                              <Tr key={el.id_endpoint} color="blackAlpha.700">
                                <Td>{el.id_endpoint}</Td>
                                <Td fontWeight={900}>{el.entidade_aceite}</Td>
                                <Td color={el.para_servico == "REFERENCIAS" ? "blue.600":  "orange.600"} fontWeight={600}>{el.para_servico}</Td>
                                <Td><Tooltip hasArrow label={el.url} bg='blue.400'><span>{el.url.substr(0, 25)} ...</span></Tooltip></Td>
                                <Td>{el.desc_evento || "Todos"}</Td>
                                <Td>
                                  {el.activo == "true" ? (
                                    <BsToggleOn
                                      onClick={() =>
                                        activateEndpoint(
                                          el.id_endpoint,
                                          "false"
                                        )
                                      }
                                      size={30}
                                      cursor="pointer"
                                      color="green"
                                    />
                                  ) : (
                                    <BsToggleOff
                                      onClick={() =>
                                        activateEndpoint(el.id_endpoint, "true")
                                      }
                                      size={30}
                                      cursor="pointer"
                                      color="red"
                                    />
                                  )}
                                </Td>
                                <Td>
                                  {el.verificar_ssl == "true" ? (
                                    <MdDoneOutline size={20} color="green" />
                                  ) : (
                                    <MdClose size={20} color="red" />
                                  )}
                                </Td>
                                <Td>
                                  {new Date(el.criacao_em).toLocaleDateString()}{" "}
                                  {new Date(el.criacao_em).toLocaleTimeString()}
                                </Td>
                                <Td>
                                  <ModalRemoveEndpoint
                                    registo={el}
                                    excluirRef={excluirEndpoint}
                                  />
                                  <Button
                                    size="sm"
                                    ml={2}
                                    leftIcon={<MdEdit />}
                                    as={Link}
                                    to={`/adm/endpoint/${el.id_endpoint}`}
                                    bg="green.500"
                                    color="white"
                                    variant="solid"
                                  ></Button>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            {/* <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box> */}
          </Flex>
          :
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
          }
        </Box>
      </Flex>
    </>
  );
}

export default Webhooks;
