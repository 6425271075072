import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, Divider, FormLabel, Input, Box, Text, Select, Heading, Flex, useToast, Alert } from '@chakra-ui/react'
import {  MdAdd, MdCancel, MdDelete, MdEdit, MdEditNotifications, MdMonitor, MdPeople, MdPlusOne, MdProductionQuantityLimits, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { ModalAlterarPag, ModalRemoveTipoProduto } from '.'

export default function PlacementExample({produto, entidades, axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [referencias, setReferencias] = useState([])
    const [user, setUser] = useState('1')
    const [desablitarAdd, setDesablitarAdd] = useState(false)
    const [atualizador, setAtualizador] = useState(false)
    const [produtoSelecionado, setProdutoSelecionado] = useState({})
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
  const formik = useFormik({
    initialValues:{
      valor_fixo:'',
      percentual: '',
      minimo: '',
      maximo: '',
      forma_de_uso: 'Percentual',
      entidade:''
    },
    onSubmit: async (values)=> {

      try {

            if((values.valor_fixo) && (values.percentual) && (values.minimo) && (values.maximo) && (values.entidade)){
              const res =  await AXIOS.patch(`/clientes/percentual/${produto.id_por_uso}`,values)

            toast({
              title: 'Adicionar custo por uso',
              description: "Á carregar o registro",
              status: 'loading',
              duration: 4000,
              isClosable: true,
            })
            
            if(res.data.status === "sucesso"){
              axiosReferencias()
              setTimeout(()=>{            
                setIsOpen(false)
                formik.resetForm()
              },1000)        
              
              setTimeout(()=>{
                toast({
                  title: 'Adicionar custo por uso',
                  description: res.data.mensagem,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                })
              },5000)

            }else{
              setTimeout(()=>{
                toast({
                  title: 'Adicionar custo por uso',
                  description: res.data.mensagem,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                })
              },5000)
            }

          }
      
      } catch (error) {
        toast({
          title: 'Adicionar de custo por uso',
          description: "Aconteceu algo! Tente de novo",
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
      
    },

  })
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      if(loginValue){
       const value = JSON.parse(loginValue)
       setUser(value.mensagem)
      }
      
      
  },[])
    return (
      <>
        <Button colorScheme='red' onClick={()=>setIsOpen(true)} leftIcon={<MdMonitor/>}>Adicionar</Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Adicionar entidade para uso especifico")}</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl mt={2}>
                        <FormLabel>{t("Valor Fixo")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='number' name='valor_fixo' required onChange={formik.handleChange} value={formik.values.valor_fixo} />
                         </Flex>
                    </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>{t("Percentual")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='number' name='percentual' required onChange={formik.handleChange} value={formik.values.percentual} />
                         </Flex>
                    </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>{t("Valor Minimo")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='number' name='minimo' required onChange={formik.handleChange} value={formik.values.minimo} />
                         </Flex>
                    </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>{t("Valor Maximo")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='number' name='maximo' required onChange={formik.handleChange} value={formik.values.maximo} />
                         </Flex>
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>{t("Forma de uso")}</FormLabel>
                        <Select required name='forma_de_uso' onChange={formik.handleChange} value={formik.values.forma_de_uso}>
                          <option value="Percentual">Percentual</option>
                          <option value="Valor Fixo">Valor Fixo</option>
                        </Select>
                      </FormControl>
                      <FormControl mt={2}>
                        <FormLabel>{t("Entidade")}</FormLabel>
                        <Select required name='entidade' onChange={formik.handleChange} value={formik.values.entidade}>
                          <option value="">Escolha a entidade</option>
                          {entidades.map(el => <option value={el.numero_entidade}>{el.nome_empresa}</option>)}
                        </Select>
                      </FormControl>
                    <Button bg="red.700" color="white" mr={5} type='submit' mt={5}>{t("Adicionar")}</Button>                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }