import {
  Flex,
  Box,
  Text,
  Center,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Td,
  Tbody,
  Tr,
  Th,
  Thead,
  TableCaption,
  TableContainer,
  useToast,
  Button,
  Tooltip,
  Heading
} from "@chakra-ui/react";
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from "react";
import {
  SideBarMenu,
  NavBar,
  ModalRemoveEndpoint,
  Loader,
  BotaoTop,
  ModalVerRespostaEndpintRetry
} from "../Components/index";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Pagination from "react-paginate";
import CONFIG from "../Config";
import {
  MdArrowBack,
  MdArrowForward,
  MdClose,
  MdDone,
  MdDoneOutline,
  MdEdit,
  MdError,
  MdUpgrade,
  MdWebhook
} from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function Webhooks() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);

  const [referencias2, setReferencias2] = useState([]);
  const [referenciasFilter2, setReferenciasFilter2] = useState([]);
  const {t, i18n} = useTranslation()

  const navigate = useNavigate();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState("1");
  const toast = useToast();
  const [loader, setLoader] = useState(false);

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      const referencia = await AXIOS.get(
        `/retry-endpoints/entidade/${entidade}`
      );
      const referencia2 = await AXIOS.get(
        `/logs-requisicao/entidade/${entidade}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }

      if (referencia2.data.status === "erro") setReferencias2([]);
      else {
        setReferencias2(referencia2.data.mensagem);
        setReferenciasFilter2(referencia2.data.mensagem);
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  async function actualizarEndpints() {
    try {
      setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      const referencia = await AXIOS.get(
        `/endpoints/entidade/${entidade}`
      );
      const referencia2 = await AXIOS.get(
        `/logs-requisicao/entidade/${entidade}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }

      if (referencia2.data.status === "erro") setReferencias2([]);
      else {
        setReferencias2(referencia2.data.mensagem);
        setReferenciasFilter2(referencia2.data.mensagem);
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };


  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset2, setItemOffset2] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage2 = 20;
  const endOffset2 = itemOffset2 + itemsPerPage2;
  console.log(`Loading items from ${itemOffset2} to ${endOffset2}`);
  const currentItems2 = referenciasFilter2.slice(itemOffset2, endOffset2);
  const pageCount2 = Math.ceil(referenciasFilter2.length / itemsPerPage2);

  // Invoke when user click to request another page.
  const handlePageClick2 = (event) => {
    const newOffset2 =
      (event.selected2 * itemsPerPage2) % referenciasFilter2.length;
    console.log(
      `User requested page number ${event.selected2}, which is offset ${newOffset2}`
    );
    setItemOffset2(newOffset2);
  };

  const excluirEndpoint = async (ref) => {
    try {
      const refs = referencias.filter((r) => r.id_endpoint != ref.id_endpoint);
      const res = await AXIOS.delete(
        `/endpoints/${ref.id_endpoint}`
      );
      if (res.data.status == "sucesso") {
        setReferencias(refs);
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Conseguimos com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Não conseguimos excluir"),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const activateEndpoint = async (id, valor) => {
    try {
      const values = {};
      values.activo = valor;
      const res = await AXIOS.patch(`/endpoints/${id}`, {
        ...values
      });

      if (res.data.status === "sucesso") {
        toast({
          title: t("Ativação de endpoint"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Ativação de endpoint"),
          description: t(res.data.mensagem),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  let permissao = user?.pagina_webwook == "true";

  if(user?.pagina_webwook) permissao = user?.pagina_webwook == "true"
  else permissao = user.numero_entidade

  return (
    user?.habilitar_area_tecnica == "true"  ? <>
      <Flex color="white" bg="white" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenu />
        <BotaoTop/>
        <Box flex={1}>
          <NavBar  titulo={t("Falhas de requisições")} imagem={<MdWebhook size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"} />
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
            mt={5}
          >
             {permissao ? <Box px={10} color="black" mt={10}>
              {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold">
                Webhooks
              </Text> */}
              <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    
                    onClick={actualizarEndpints}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
              </Button>
              <Button ml={1} as={Link} to="/webhooks" leftIcon={<MdDone/>} colorScheme="orange">Endpoints</Button>
              {loader ? (
                <Box isLazy bg="white" p={5} mt={5}>
                  <TableContainer mt={3} bg="white" boxShadow="md">
                    <Table variant="striped" colorScheme="gray">
                      <TableCaption>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<MdArrowForward />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          breakClassName="list-group-item"
                          previousLabel={<MdArrowBack />}
                          renderOnZeroPageCount={null}
                        />
                      </TableCaption>
                      <Thead p={10}>
                        <Tr bg="gray.600">
                          <Th py={3} color="gray.300">
                            ID Retry
                          </Th>
                          <Th py={3} color="gray.300">
                            ID Requisição
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Resultado")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Sessão executada")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Iniciado em")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Repitidas em")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Acção")}
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentItems.map((el) => (
                          <Tr key={el.id_automatico_requisicoes} color="blackAlpha.700">
                            <Td>{el.id_automatico_requisicoes}</Td>
                            <Td>{el.log_enviado}</Td>
                            <Td fontWeight={700} color={el.estado_do_resultado == "Resolvido" ? "green.500" : el.estado_do_resultado == "Cancelado" ? "red.500" : "blue.500" }>{el.estado_do_resultado}</Td>
                            <Td>{el.tempo} {el.repetido_em} </Td>
                            <Td>
                              {new Date(el.logo_gerado_em).toLocaleDateString()}{" "}
                              {new Date(el.logo_gerado_em).toLocaleTimeString()}
                            </Td>
                            <Td>{el.tentativas_requisicao}x</Td>
                            <Td>
                              <ModalVerRespostaEndpintRetry actualizar={axiosReferencias} texto={el}/>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
    :
    navigate("/404")
  );
}

export default Webhooks;
