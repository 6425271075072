import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, Heading, DrawerHeader, Switch, Tag, DrawerOverlay,DrawerContent,Button, Stack, AbsoluteCenter, Divider ,FormControl, FormLabel, Radio, RadioGroup, Input, Box, Text, Select, Textarea, Flex, useToast, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {  MdAdd, MdBuild, MdPermIdentity, MdWebhook} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import axios from 'axios'
import qs from 'qs'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function PlacementExample({axiosReferencias}) { 
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [showAnyEvents, setShowAnyEvents] = useState(false)
    const [user, setUser] = useState('1')
    const [eventos, setEVentos] = useState([])
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const refEvento =  useRef();
    const {t, i18n} = useTranslation()
    const navigate =  useNavigate()
    const [tipoPrimavera, setTipoPrimavera] = useState('Profissional')
    const [gpo, setGPO] = useState("REFERENCIAS")
    
    
    const eventosEfetchAsync = async () =>{
      try {
        const res = await AXIOS.get(`/eventos`)
        if (res.data.status == "sucesso"){
            setEVentos(res.data.mensagem)
        }else {
          setEVentos([])
        }
      }catch (error) {
        navigate('/sem-comunicacao')
      }
        
    }

    const formik = useFormik({
      initialValues:{
        entidade_aceite:'',
        url:'',
        activo: 'true',
        evento: '',
        verificar_ssl: 'true',
        bearer_token: '',
        para_servico: gpo,
        gpo_eventos:''
      },
      onSubmit: async (values)=> {

        try {

        formik.initialValues = String(values.url).trim()
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
        const findIdEvent = eventos.find(i => i.desc_evento == values.evento)
        
        values.para_servico =  gpo
        values.entidade_aceite = entidade
        values.url = String(values.url).trim()
        values.evento = gpo != "GPO" ? findIdEvent?.id_evento ? findIdEvent?.id_evento : 0 : 8
        values.activo = gpo != "GPO" ? values.activo ? "true" : "false" : "false"
        values.verificar_ssl = values.verificar_ssl ? "true" : "false"
        
      toast({
          title: 'Criação de endpoints',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 2000,
          isClosable: true,
        })
        
        const res =  await AXIOS.post(`/endpoints`,values)       
        

        if(res.data.status === "sucesso"){
                    
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de endpoints',
              description: res.data.mensagem,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            axiosReferencias()
          },2500)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de endpoints',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })

        },2500)
        }

        }catch (error) {
          navigate('/sem-comunicacao')
        }
      },
    })

    const formikPRIMAVERA = useFormik({
      initialValues:{
        entidade_aceite:'',
        url:'',
        activo: 'true',
        evento: '',
        verificar_ssl: 'true',
        bearer_token: '',
        host_primavera:'',
        username_primavera:'',
        password_primavera:'',
        instance_primavera:'Default',
        company_primavera:'',
        line_primavera: tipoPrimavera,
        tipo_endpoint:"PRIMAVERA",
        campo_codigo_cliente_primavera: "opcional_1"
      },
      onSubmit: async (values)=> {

        try {
          
        formikPRIMAVERA.initialValues = String(values.url).trim()
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
        const findIdEvent = eventos.find(i => i.desc_evento == values.evento)
        
        values.entidade_aceite = entidade
        values.line_primavera = tipoPrimavera
        values.url = String(values.url).trim()
        values.evento = findIdEvent?.id_evento ? findIdEvent?.id_evento : 0 
        values.activo = values.activo ? "true" : "false"
        values.verificar_ssl = values.verificar_ssl ? "true" : "false"
        values.host_primavera = values.host_primavera + ":2018/webapi/"
        
        if((values.bearer_token == null) || (values.bearer_token == "")){
            toast({
              title: 'Criação de endpoints',
              description: "Faça autorização para ter o acesso á requisição",
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
        }
        
      
        
        const res =  await AXIOS.post(`/endpoints`,values)
        
        toast({
          title: 'Criação de endpoints',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 5000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){
                    
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Criação de endpoints',
              description: res.data.mensagem,
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            axiosReferencias()
          },2000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Criação de endpoints',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })

        },5000)
        }
        
      }catch (error) {
        navigate('/sem-comunicacao')
      }

      },
    })

    const pedirTokenPrimavera = async () => {

      toast({
        title: 'Autorização no PRIMAVERA',
        description: "Á processar",
        status: 'loading',
        duration: 2500,
        isClosable: true,
      })

      try {
          const hostPRIMAVERA = formikPRIMAVERA.values.host_primavera

          const data =  qs.stringify({
            'username': formikPRIMAVERA.values.username_primavera,
            'password': formikPRIMAVERA.values.password_primavera,
            'instance': formikPRIMAVERA.values.instance_primavera,
            'grant_type': 'password',
            'company': formikPRIMAVERA.values.company_primavera,
            // 'line': formikPRIMAVERA.values.line_primavera, 
            'sessionkey': "1"
          })

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${hostPRIMAVERA}:2018/Webapi/token`,
            headers: { 
              'Content-Type': 'application/x-www-form-urlencoded',
              'scheme': 'http',
            },
            data : data
          };

          
            const responseR = await axios.request(config);
            console.log("STATUS",responseR.data);

            if(responseR.data.access_token){
                setTimeout(()=>{
                  toast({
                    title: 'Autorização no PRIMAVERA',
                    description: "Pedido de autorização aceite", 
                    status: 'success',
                    duration: 2500,
                    isClosable: true,
                  })

                  formikPRIMAVERA.handleChange({target:{name:"bearer_token", value:"Bearer " + responseR.data.access_token}, type:"text"})
      
                },2800)
            }
            
        }
        catch (error) {
          console.log("STATUS",error);
          setTimeout(()=>{
            toast({
              title: 'Autorização no PRIMAVERA',
              description: error.message,
              status: 'error',
              duration: 2500,
              isClosable: true,
            })
            formikPRIMAVERA.handleChange({target:{name:"bearer_token", value:"Bearer " + error.message}, type:"text"})
        },2800)
        }
      }
    
    
    const mudarEventos = (vl)=>{
        setShowAnyEvents(!vl)
    }
    
    
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      axiosReferencias();
      eventosEfetchAsync();
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          Endpoints
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Criar endpoints")}</DrawerHeader>
            <DrawerBody>
             
              <Tabs variant='soft-rounded' mt={5} colorScheme='orange'>
                <TabList>
                  <Tab>Normal</Tab>
                  <Tab _selected={{ color: 'white', bg: 'blue.400' }}>WebAPI PRIMAVERA v10</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                  <RadioGroup my={6} onChange={setGPO} value={gpo}>
                      <Stack direction='row' justifyContent="center">
                        <Radio value="REFERENCIAS">Pagamento por referência</Radio>
                        <Radio value="GPO">Gateway de pagamentos online (GPO)</Radio>
                      </Stack>
                    </RadioGroup>
                    <form onSubmit={formik.handleSubmit}>
                        <Box bg='white'>
                        <FormControl>
                              <FormLabel>URL</FormLabel>
                              <Flex direction="row" alignItems="center">
                                <Input type='url' required name='url' onChange={formik.handleChange} value={formik.values.url} />
                              </Flex>
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel htmlFor='activo' mb='0'>
                              {t("Activo?")}
                            </FormLabel>
                            <Switch id='activo' name='activo' onChange={formik.handleChange} value={formik.values.activo} defaultChecked={true} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                            <FormLabel htmlFor='evento' mb='0'>
                              {t("Qualquer evento?")}
                            </FormLabel>
                            <Switch id='evento' onChange={(evt) => mudarEventos(evt.target.checked)} ref={refEvento} defaultChecked={true} value="0" />
                          </FormControl>
                          {showAnyEvents && <FormControl mt={2}>
                              <FormLabel>{t("Tipo de evento para Webhook")}</FormLabel>

                              {gpo == "REFERENCIAS" ? <Flex direction="row" alignItems="center">
                                <Input type='text' list='eventos' name='evento' onChange={formik.handleChange} value={formik.values.evento} />
                                <datalist id='eventos'>
                                  <option value={"Qualquer"}>{t("Qualquer")}</option>
                                  {eventos.map(fl => <option title={fl.id_evento} value={fl.desc_evento}>{fl.desc_evento}</option>)}
                                </datalist>
                              </Flex>

                              :
                              
                              <Flex direction="row" alignItems="center">
                                <Input type='text' list='gpo_eventos' required name='gpo_eventos' onChange={formik.handleChange} value={formik.values.gpo_eventos} />
                                <datalist id='gpo_eventos'>
                                    <option value={"PAID"}>Pagamentos Pago</option>
                                    <option value={"REJECTED"}>Pagamento Rejeitado</option>
                                    <option value={"REFUNDED"}>Devolução de pagamento</option>
                                </datalist>
                              </Flex>}
                          </FormControl>}
                          <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                            <FormLabel htmlFor='ssl_verificar' mb='0'>
                              {t("Verificar certificado SSL?")}
                            </FormLabel>
                            <Switch id='ssl_verificar'  name='verificar_ssl' onChange={formik.handleChange} value={formik.values.verificar_ssl} defaultChecked={true}  />
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' flexDir="column" >
                                <FormLabel htmlFor='ssl_verificar' mb='0'>
                                  {t("Autorização Bearer Token")}
                                </FormLabel>
                                <Textarea name='bearer_token' style={{height:200}} onChange={formik.handleChange} value={formik.values.bearer_token} />
                          </FormControl>
                          <Button leftIcon={<MdWebhook/>} onChange={formik.handleChange} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Criar")}</Button>
                  
                        </Box> 
                    </form>
                  </TabPanel>
                  <TabPanel>
                      <form onSubmit={formikPRIMAVERA.handleSubmit}>
                          <FormControl>
                              <FormLabel>Host</FormLabel>
                              <Flex direction="row" alignItems="center">
                                <Input type='url' required name='host_primavera' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.host_primavera} />
                                <Tag size="lg" variant='solid' p={1} colorScheme='blue'>
                                  :2018/WEBAPI
                                </Tag>
                              </Flex>
                          </FormControl>
                          <FormControl mt={2}>
                            <FormLabel>Campo do codigo do cliente PRIMAVERA</FormLabel>
                              <Select required name='campo_codigo_cliente_primavera' onChange={formik.handleChange} value={formik.values.campo_codigo_cliente_primavera}>
                                <option selected value="opcional_1">Campo 1</option>
                                <option value="opcional_2">Campo 2</option>
                                <option value="opcional_3">Campo 3</option>
                              </Select>
                          </FormControl>
                          <Box position='relative' padding='10'>
                            <Divider />
                            <AbsoluteCenter bg='white' px='4'>
                                Autorização ou actualização
                            </AbsoluteCenter>
                          </Box>
                          <Flex>
                            <FormControl mt={3}>
                                <FormLabel>Usuario</FormLabel>
                                <Flex direction="row" alignItems="center">
                                  <Input type='text' required name='username_primavera' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.username_primavera} />
                                </Flex>
                            </FormControl>
                            <FormControl mt={3} ml={3}>
                                <FormLabel>Senha</FormLabel>
                                <Flex direction="row" alignItems="center">
                                  <Input type='password' required name='password_primavera' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.password_primavera} />
                                </Flex>
                            </FormControl>
                          </Flex>
                          <Flex>
                            <FormControl mt={3}>
                                <FormLabel>Empresa</FormLabel>
                                <Flex direction="row" alignItems="center">
                                  <Input type='text' required name='company_primavera' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.company_primavera} />
                                </Flex>
                            </FormControl>
                            <FormControl mt={3} ml={3}>
                                <FormLabel>Instancia</FormLabel>
                                <Flex direction="row" alignItems="center">
                                  <Input type='text' required name='instance_primavera' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.instance_primavera} />
                                </Flex>
                            </FormControl>
                          </Flex>
                          <FormControl mt={3}>
                              <FormLabel>Linha</FormLabel>
                              <RadioGroup onChange={setTipoPrimavera} name='line_primavera' value={tipoPrimavera}>
                                <Stack direction='row'>
                                  <Radio value='Executive'>Executive</Radio>
                                  <Radio defaultChecked value='Profissional'>Profissional</Radio>
                                </Stack>
                              </RadioGroup>
                          </FormControl>
                          <Button type='button' onClick={pedirTokenPrimavera} mt={3} leftIcon={<MdPermIdentity />} colorScheme='orange' variant='solid'>
                            Pedir permissão
                          </Button>

                          <Box position='relative' padding='10'>
                            <Divider />
                            <AbsoluteCenter bg='white' px='4'>
                              Envio de dados
                            </AbsoluteCenter>
                          </Box>
                        <Box bg='gray.200' p={3}>
                        <FormControl>
                              <FormLabel>Endpoint</FormLabel>
                              <Flex direction="row" alignItems="center">
                                <Input type='url' required name='url' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.url} />
                              </Flex>
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                            <FormLabel htmlFor='activo' mb='0'>
                              {t("Activo?")}
                            </FormLabel>
                            <Switch id='activo' name='activo' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.activo} defaultChecked={true} /> 
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                            <FormLabel htmlFor='evento' mb='0'>
                              {t("Qualquer evento?")}
                            </FormLabel>
                            <Switch id='evento' onChange={(evt) => mudarEventos(evt.target.checked)} ref={refEvento} defaultChecked={true} value="0" />
                          </FormControl>
                          {showAnyEvents && <FormControl mt={2}>
                              <FormLabel>{t("Tipo de evento para Webhook")}</FormLabel>
                              <Flex direction="row" alignItems="center">
                                <Input type='text' list='eventos' name='evento' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.evento} />
                                <datalist id='eventos'>
                                  <option value={"Qualquer"}>{t("Qualquer")}</option>
                                  {eventos.map(fl => <option title={fl.id_evento} value={fl.desc_evento}>{fl.desc_evento}</option>)}
                                </datalist>
                              </Flex>
                          </FormControl>}
                          <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                            <FormLabel htmlFor='ssl_verificar' mb='0'>
                              {t("Verificar certificado SSL?")}
                            </FormLabel>
                            <Switch id='ssl_verificar'  name='verificar_ssl' onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.verificar_ssl} defaultChecked={true}  />
                          </FormControl>
                          <FormControl mt={2} justifyContent="space-between" display='flex' flexDir="column" >
                                <FormLabel htmlFor='ssl_verificar' mb='0'>
                                  {t("Autorização Bearer Token")}
                                </FormLabel>
                                <Textarea bg="gray.300" required readOnly name='bearer_token' style={{height:200}} onChange={formikPRIMAVERA.handleChange} value={formikPRIMAVERA.values.bearer_token} />
                          </FormControl>
                          <Button leftIcon={<MdWebhook/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Criar")}</Button>
                  
                        </Box> 
                      </form>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }