import React, {useState} from 'react'
import { Button,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader, CardBody, Box, Heading, Text, Stack, StackDivider, Flex, Tag, Avatar, TagLabel } from '@chakra-ui/react'
import { MdClose, MdSearch } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    return (
      <>
        <Button onClick={onOpen} leftIcon={<MdSearch />} bg='blue.500' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="4xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>Mais informações da entidade</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Card>
                  <CardHeader>
                    <Heading size='md'>ENTIDADE: {registo.numero_entidade}</Heading>
                    <Text>{registo.nome_empresa}</Text>         
                      <Tag mt={6} size='md' colorScheme='blue' borderRadius='full'>
                        <Avatar
                          size='sm'
                          name={registo.nome_usuario}
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel>{registo.nome_usuario}</TagLabel>
                      </Tag>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Criando em
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.criando_em ? new Date(registo.criando_em).toLocaleDateString() + " " + new Date(registo.criando_em).toLocaleTimeString(): <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Actualizado em
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.actualizado_em ? new Date(registo.actualizado_em).toLocaleDateString() + " " + new Date(registo.actualizado_em).toLocaleTimeString(): <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Responsavel
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.responsavel ?                                
                                <Tag size='md' colorScheme='blue' borderRadius='full'>
                                  <Avatar
                                    src={`/images/${registo.logo}`}
                                    size='sm'
                                    name={registo.responsavel}
                                    ml={-1}
                                    mr={2}
                                  />
                                <TagLabel>{registo.responsavel}</TagLabel>
                                </Tag>
                              : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Activação
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.bloqueio == 1 ? <span style={{color:"green", fontWeight:900}}>Activo</span> : <span style={{color:"red", fontWeight:900}}>Inactivo</span>}
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Terminal
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.contacto ? registo.contacto : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                             Email
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.email ? registo.email : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" wrap="wrap" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              NIF
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.nif ? registo.nif : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                             ULTIMO LOGIN
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.ultimo_login ? new Date(registo.ultimo_login).toLocaleDateString() + " " + new Date(registo.ultimo_login).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" wrap="wrap" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              TAMANHO DAS REFERENCIAS
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.num_padrao_referencias ? registo.num_padrao_referencias : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center">
                          <Button colorScheme='orange' as={Link} to={`/adm/servicos/${registo.id_clientes}`}>Parametrização de produto</Button>
                          <Button colorScheme='orange' ml={2} as={Link} to={`/adm/definicoes/${registo.id_clientes}`}>Definições do sistema</Button>
                          {/* {registo.gpo_numero_POS && <Button colorScheme='orange' ml={2} as={Link} to={`/adm/pos-configuracoes/${registo.gpo_comerciante_hash}`}>Configuração de terminal (POS)</Button>} */}
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Button onClick={() => window.location = `/adm/referencias/${registo.numero_entidade}`}>Referências geradas</Button>
                          </Box>
                          <Box>
                            <Button onClick={() => window.location = `/adm/pagamentos/${registo.numero_entidade}`}>Pagamentos</Button> /&nbsp;
                            <Button onClick={() => window.location = `/adm/pagamentos-nao-processados/${registo.numero_entidade}`}>Não processados</Button>
                          </Box>
                          <Box>
                            <Button onClick={() => window.location = `/adm/transacoes/${registo.numero_entidade}`}>Transações feitas</Button>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center">
                          <Box>
                            <Button onClick={() => window.location = `/adm/periodo-contabilistico/${registo.numero_entidade}`}>Periodo contabilistico</Button>
                          </Box>
                          <Box ml={5}>
                            <Button onClick={() => window.location = `/adm/relatorios/${registo.numero_entidade}`}>Relatórios</Button>
                          </Box>
                      </Flex>
                    </Stack>
                  </CardBody>
                </Card>
                <Card mt={5}>
                  {registo.gpo_numero_POS && <CardBody>
                  
                    <Heading size='md' mb={5}>Configurações do GPO</Heading> 
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                            <Button colorScheme='orange' as={Link} to={`/adm/pos-configuracoes/${registo.gpo_comerciante_hash}`}>Configuração de terminal (POS)</Button>
                        </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Button onClick={() => window.location = `/adm/transacoes-gpo/${registo.gpo_comerciante_hash}`}>Transações feitas</Button>
                          </Box>
                      </Flex>
                    </Stack>
                  </CardBody>
                  }
                </Card>
                  
                
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
