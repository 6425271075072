import React, {useState} from 'react'
import { Button,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader, CardBody, Box, Heading, Text, Stack, StackDivider, Flex } from '@chakra-ui/react'
import { MdClose, MdSearch } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    return (
      <>
        <Button onClick={onOpen} size="sm" leftIcon={<MdSearch />} bg='blue.500' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="2xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>{t("Mais informações do afiliado")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Card>
                  <CardHeader>
                    <Heading size='md'>ID: {registo.id_afiliado}</Heading>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Username")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {t(registo.user_name)}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Nome completo")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.nome_afiliado}
                            </Text>
                          </Box>
                      </Flex>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Pertende á entidade")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.numero_entidade}
                        </Text>
                      </Box>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Seu email")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.email_afiliado ? registo.email_afiliado : <MdClose size={20} color='red'/> }
                        </Text>
                      </Box>
                      
                    </Stack>
                  </CardBody>
                </Card>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
