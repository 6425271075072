import { useEffect, useRef, useState } from 'react'
import { MdClose, MdDoneOutline, MdError, MdWebhook } from "react-icons/md"
import {BsToggleOn, BsToggleOff} from 'react-icons/bs'
import {  Loader, ModalRemoveEndpoint, NavBar, SideBarMenu } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Center, useToast, Switch, Textarea, Select, Radio, RadioGroup} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'
import { useTranslation } from 'react-i18next'
import { BotaoTop, NavBarAdm, SideBarMenuAdm } from '../../Components'


function Referencias() {

    const [registo, setReferencias] = useState({})    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const toast = useToast()    
    const [eventos, setEVentos] = useState([])
    const [showAnyEvents, setShowAnyEvents] = useState(false)
    const refEvento =  useRef();
    const {t, i18n} = useTranslation()
    const [gpo, setGPO] = useState("REFERENCIAS") 
    
    async function axiosReferencias(){
    
    try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/endpoints/${id}`);
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length){
                setReferencias(referencia.data.mensagem[0])
                setGPO(referencia.data.mensagem[0].para_servico)
        }else{
          setReferencias([])
        }
      }
      
      const res = await AXIOS.get(`/eventos`)
        if (res.data.status == "sucesso"){
            setEVentos(res.data.mensagem)
        }
        
        setLoader(true)
      
      }catch (error) {
        navigate('/sem-comunicacao')
    }
          
  }    
  
  const mudarEventos = (vl)=>{
  try {
    setShowAnyEvents(!vl)
  }catch (e){
    toast({
      title: 'Alerta',
      description: "Não conseguimos ter comunicação! Verifique sua internet",
      status: 'error',
    })
    }
}
  

  const formik = useFormik({
    initialValues:{
      entidade_aceite:'',
      url:'',
      activo: 'true',
      evento: '',
      verificar_ssl: 'true',
      bearer_token: '',
      conta_primavera: '',
      serie_primavera: '',
      tipo_documento_primavera: '',
      tipo_movimento_primavera: '',
      username_primavera: '',
      password_primavera: '',
      company_primavera: '',
      cambio_primavera: '',
      tipo_resposta: '',
      para_servico: gpo,
      gpo_eventos:''

    },
    onSubmit: async (values)=> {
    try{
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const findIdEvent = eventos.find(i => i.desc_evento == values.evento)
      
      values.entidade_aceite = entidade
      values.para_servico =  gpo
      values.evento = findIdEvent?.id_evento ? findIdEvent?.id_evento : 0 
      values.activo = values.activo ? "true" : "false"
      values.verificar_ssl = values.verificar_ssl ? "true" : "false"
      values.comerciante_gpo = registo.comerciante_gpo 
      
      // alert(JSON.stringify(values))
      const res =  await AXIOS.patch(`/endpoints/${registo.id_endpoint}`,{...values})
      
      if(res.data.status === "sucesso"){
        toast({
          title: t('Actualização de endpoints'),
          description: t(res.data.mensagem),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        
      }else{
        toast({
          title: t('Actualização de endpoints'),
          description: t(res.data.mensagem),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      navigate('/adm/webhooks')
      axiosReferencias(); 
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
      
    },
  })

  const excluirEndpoint = async (ref) => {
    try {

      const res = await AXIOS.delete(
        `/endpoints/${ref.id_endpoint}`
      );
      if (res.data.status == "sucesso") {
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Conseguimos com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Exclusão de endpoint"),
          description: t("Não conseguimos excluir"),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      navigate("/adm/webhooks")
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };
  
  
    useEffect(()=>{
      axiosReferencias();       
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
          navigate('/adm/login')
        }        
      
          
    },[])
    
    
      formik.initialValues.activo = registo.activo == "true" ? true : false
      formik.initialValues.entidade_aceite = registo.entidade_aceite
      formik.initialValues.evento = registo.desc_evento == null ? "Qualquer" : registo.desc_evento
      formik.initialValues.url =  registo.url
      formik.initialValues.bearer_token =  registo.bearer_token
      formik.initialValues.conta_primavera =  registo.conta_primavera
      formik.initialValues.serie_primavera =  registo.serie_primavera
      formik.initialValues.tipo_documento_primavera =  registo.tipo_documento_primavera
      formik.initialValues.tipo_movimento_primavera =  registo.tipo_movimento_primavera
      formik.initialValues.username_primavera =  registo.username_primavera
      formik.initialValues.password_primavera =  registo.password_primavera
      formik.initialValues.company_primavera =  registo.company_primavera
      formik.initialValues.cambio_primavera =  registo.cambio_primavera
      formik.initialValues.tipo_resposta =  registo.tipo_resposta
      formik.initialValues.para_servico =  registo.para_servico
      formik.initialValues.gpo_eventos =  registo.gpo_eventos
      formik.initialValues.verificar_ssl =  registo.verificar_ssl == "true" ? true : false
      
      
      let permissao = user?.pagina_webhooks == "true";

      if(user?.pagina_webhooks) permissao = user?.pagina_webhooks == "true"
      else permissao = user.todas_areas ? true : false;
      
      
  return (
  
  <>
    <Flex color='white' bg="gray.300" direction="row">
      <SideBarMenuAdm/>
      <BotaoTop/>
      <Box flex={1}>
          <NavBarAdm titulo={t("Modificar endpoint")} imagem={<MdWebhook size={200}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          {
            permissao
            
            ?
          <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
              <Box px={10} color="black" mt={10}>
                {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>{t("Modificar endpoint")}</Text> */}
                {loader ?
                <Flex color="black">
                    {registo?.id_endpoint && <Card flex={1} boxShadow="md">
                          <CardHeader>
                            {/* <Heading size='md'>#: {registo.id_endpoint}</Heading> */}
                            <Heading fontSize={13} size='lg'>{registo.url}</Heading>
                          </CardHeader>
                        
                          <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Activo")}
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                      {registo.activo == "true" ? <BsToggleOn size={40} color='green' /> : <BsToggleOff size={40} color='red' />}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                     {t("Evento")}
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                      {registo.desc_evento || "Todos"}
                                    </Text>
                                  </Box>
                              </Flex>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("SSL verificado")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.verificar_ssl == 'true' ? <MdDoneOutline size={40} color='green' /> : <MdClose size={40} color='red' />}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Tipo de resposta")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.tipo_resposta}
                                </Text>
                              </Box>
                              <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Criado em")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {new Date(registo.criacao_em).toLocaleDateString()} {new Date(registo.criacao_em).toLocaleTimeString()}
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Finalidade")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.tipo_endpoint}
                                  </Text>
                                </Box>
                              </Flex>
                              <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Bearer Token")}
                                  </Heading>
                                  <Text pt='2' wordBreak="break-word" fontSize='small'>
                                    {registo.bearer_token}
                                  </Text>
                                </Box>
                              </Flex>
                              {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") &&<Heading mt={5} fontSize="xl">Definições <span style={{color:"#00C"}}>PRIMAVERA V10</span></Heading>}
                              {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <Flex mt={2} direction="row" alignItems="center" justifyContent="space-between">
                                <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Conta")}
                                    </Heading>
                                    <Text pt='2' wordBreak="break-word" fontSize='small'>
                                      {registo.conta_primavera}
                                    </Text>
                                  </Box>
                                </Flex>
                                <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Serie")}
                                    </Heading>
                                    <Text pt='2' wordBreak="break-word" fontSize='small'>
                                      {registo.serie_primavera}
                                    </Text>
                                  </Box>
                                </Flex>
                              </Flex>}
                              {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <Flex mt={2} direction="row" alignItems="center" justifyContent="space-between">
                               <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Tipo Documento")}
                                    </Heading>
                                    <Text pt='2' wordBreak="break-word" fontSize='small'>
                                      {registo.tipo_documento_primavera}
                                    </Text>
                                  </Box>
                                </Flex>
                                <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Tipo Movimento")}
                                    </Heading>
                                    <Text pt='2' wordBreak="break-word" fontSize='  small'>
                                      {registo.tipo_movimento_primavera}
                                    </Text>
                                  </Box>
                                </Flex>
                                <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Cambio")}
                                    </Heading>
                                    <Text pt='2' wordBreak="break-word" fontSize='  small'>
                                      {registo.cambio_primavera}
                                    </Text>
                                  </Box>
                                </Flex>
                              </Flex>}
                            </Stack>
                          </CardBody>
                    </Card>}
                    {registo?.id_endpoint && <Box flex={1} px={10}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box bg='white' p={10} rounded={5} boxShadow="md">
                        
                      <RadioGroup my={6} onChange={setGPO} value={gpo}>
                        <Stack direction='row' justifyContent="center">
                          <Radio value="REFERENCIAS">Pagamento por referência</Radio>
                          <Radio value="GPO">Gateway de pagamentos online (GPO)</Radio>
                        </Stack>
                      </RadioGroup>

                       <FormControl>
                            <FormLabel>URL</FormLabel>
                            <Flex direction="row" alignItems="center">
                              <Input type='url' name='url' onChange={formik.handleChange} value={formik.values.url} />
                             </Flex>
                        </FormControl>
                        <FormControl mt={2} justifyContent="space-between" display='flex' alignItems='center'>
                          <FormLabel htmlFor='activo' mb='0'>
                            {t("Activo?")}
                          </FormLabel>
                          <Switch id='activo' name='activo' onChange={formik.handleChange} value={formik.values.activo} defaultChecked={formik.values.activo} /> 
                        </FormControl>
                        <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                          <FormLabel htmlFor='evento' mb='0'>
                            {t("Qualquer evento?")}
                          </FormLabel>
                          <Switch id='evento' onChange={(evt) => mudarEventos(evt.target.checked)} ref={refEvento} defaultChecked={registo.desc_evento == null ? true : false} />
                        </FormControl>
                        {(showAnyEvents || (registo.desc_evento == null ? false : true )) && <FormControl mt={2}>
                            <FormLabel>{t("Tipo de evento para Webhook")}</FormLabel>
                            {/* <Flex direction="row" alignItems="center">
                              <Input type='text' list='eventos' name='evento' onChange={formik.handleChange} value={formik.values.evento} />
                              <datalist id='eventos'>
                                <option value={"Qualquer"}>Qualquer</option>
                                {eventos.map(fl => <option title={fl.id_evento} value={fl.desc_evento}>{fl.desc_evento}</option>)}
                              </datalist>
                             </Flex> */}
                             {gpo == "REFERENCIAS" ? <Flex direction="row" alignItems="center">
                                <Input type='text' list='eventos' name='evento' onChange={formik.handleChange} value={formik.values.evento} />
                                <datalist id='eventos'>
                                  <option value={"Qualquer"}>{t("Qualquer")}</option>
                                  {eventos.map(fl => <option title={fl.id_evento} value={fl.desc_evento}>{fl.desc_evento}</option>)}
                                </datalist>
                              </Flex>

                              :
                              
                              <Flex direction="row" alignItems="center">
                                <Input type='text' list='gpo_eventos' required name='gpo_eventos' onChange={formik.handleChange} value={formik.values.gpo_eventos} />
                                <datalist id='gpo_eventos'>
                                    <option value={"PAID"}>Pagamentos Pago</option>
                                    <option value={"REJECTED"}>Pagamento Rejeitado</option>
                                    <option value={"REFUNDED"}>Devolução de pagamento</option>
                                </datalist>
                              </Flex>}
                        </FormControl>}
                        {(registo.tipo_endpoint) && (registo.tipo_endpoint !== "PRIMAVERA") && <FormControl mt={2}>
                          <FormLabel>{t("Tipo de resposta")}</FormLabel>
                          <Select required name='tipo_resposta' onChange={formik.handleChange} value={formik.values.tipo_resposta}>
                            <option value="Completa">{t("Completa")}</option>
                            <option value="Resumida">{t("Resumida")}</option>
                          </Select>
                        </FormControl>}
                        <FormControl mt={2} justifyContent="space-between"  display='flex' alignItems='center'>
                          <FormLabel htmlFor='ssl_verificar' mb='0'>
                            {t("Verificar certificado SSL?")}
                          </FormLabel>
                          <Switch id='ssl_verificar'  name='verificar_ssl' onChange={formik.handleChange} value={formik.values.verificar_ssl} defaultChecked={formik.values.verificar_ssl}  />
                        </FormControl>
                        <FormControl mt={2} justifyContent="space-between" display='flex' flexDir="column" >
                          <FormLabel mb='0'>
                            {t("Autorização Bearer Token")}
                          </FormLabel>
                          <Textarea name='bearer_token' onChange={formik.handleChange} value={formik.values.bearer_token} />
                        </FormControl>
                        {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") &&<Heading mt={5} fontSize="xl">Definições <span style={{color:"#00C"}}>PRIMAVERA V10</span></Heading>}
                        <Flex mt={2}>
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2}>
                            <FormLabel>Username</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='username_primavera' onChange={formik.handleChange} value={formik.values.username_primavera} />
                              </Flex>
                          </FormControl>}
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2} ml={3}>
                            <FormLabel>Password</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='password' required name='password_primavera' onChange={formik.handleChange} value={formik.values.password_primavera} />
                              </Flex>
                          </FormControl>}
                        </Flex>
                        <Flex mt={2}>
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2}>
                            <FormLabel>Conta</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='conta_primavera' onChange={formik.handleChange} value={formik.values.conta_primavera} />
                              </Flex>
                          </FormControl>}
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2} ml={3}>
                            <FormLabel>Serie</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='serie_primavera' onChange={formik.handleChange} value={formik.values.serie_primavera} />
                              </Flex>
                          </FormControl>}
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2} ml={3}>
                            <FormLabel>Tipo Documento</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='tipo_documento_primavera' onChange={formik.handleChange} value={formik.values.tipo_documento_primavera} />
                              </Flex>
                          </FormControl>}
                        </Flex>
                        <Flex mt={2}>
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2}>
                            <FormLabel>Tipo Movimento</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='tipo_movimento_primavera' onChange={formik.handleChange} value={formik.values.tipo_movimento_primavera} />
                              </Flex>
                          </FormControl>}
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2} ml={3}>
                            <FormLabel>Empresa</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='text' required name='company_primavera' onChange={formik.handleChange} value={formik.values.company_primavera} />
                              </Flex>
                          </FormControl>}
                          {(registo.tipo_endpoint) && (registo.tipo_endpoint == "PRIMAVERA") && <FormControl mt={2} ml={3}>
                            <FormLabel>Cambio</FormLabel>
                              <Flex direction="row" alignItems="center">
                              <Input type='number' required name='cambio_primavera' onChange={formik.handleChange} value={formik.values.cambio_primavera} />
                              </Flex>
                          </FormControl>}
                        </Flex>
                        <Flex alignItems="center">
                        <Button leftIcon={<MdWebhook/>} size="sm" onChange={formik.handleChange} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={0}>{t("Actualizar")}</Button>
                          <ModalRemoveEndpoint
                            registo={registo}
                            excluirRef={excluirEndpoint}
                          />
                        </Flex>
                     
                      </Box> 
                    </form>
                    </Box>}
                    {(registo.length == false) && <Center>
                        <Heading textAlign="center" fontWeight={300} fontSize={20}>{t("Endpoint invalido! Por vafor verifique")}</Heading>
                    </Center>}
                </Flex>
                :
                <Center>
                {/* <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                /> */}
                <Loader />
              </Center>}
            </Box>
            {/* <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box> */}
        </Flex>
          :
        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default Referencias