import { useEffect, useRef, useState } from 'react'
import { MdAccessible, MdAccountBox, MdClose, MdDoneOutline, MdError, MdOutlineWifiTetheringErrorRounded, MdPassword, MdSave, MdSupervisorAccount, MdWebhook } from "react-icons/md"
import {BsToggleOn, BsToggleOff} from 'react-icons/bs'
import {  Loader, ModalMudarSenha, NavBar, SideBarMenu } from '../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Center, useToast, Switch, Textarea, Select} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'


function Referencias() {

    const [registo, setReferencias] = useState({})    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const toast = useToast()    
    const [eventos, setEVentos] = useState([])
    const [showAnyEvents, setShowAnyEvents] = useState(false)
    const refEvento =  useRef();
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
    
    try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/afiliados/${id}/entidade/${entidade}`);
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length){
            if(referencia.data.mensagem[0].numero_entidade == entidade)
                setReferencias(referencia.data.mensagem[0])
            else
                setReferencias([])
        }else{
          setReferencias([])
        }
      }
      
      const res = await AXIOS.get(`/eventos`)
        if (res.data.status == "sucesso"){
            setEVentos(res.data.mensagem)
        }
        
        setLoader(true)
      
      }catch (error) {
        navigate('/sem-comunicacao')
    }
          
  }    
  
  const mudarSenha = async (user_pass, repetirsenha)=>{
  
    if(user_pass == repetirsenha){
      const res =  await AXIOS.patch(`/afiliados/${registo.id_afiliado}`,{user_pass})
      
      toast({
        title: 'Actualização de senha', 
        description: "Á processar",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })

      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          axiosReferencias()
        },2000)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },2000)
      }
    }else{
      toast({
        title: 'Actualização de senha',
        description: "Senhas devem ser iguais",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const formik = useFormik({
    initialValues:{
      activacao:'true',
      nome_afiliado:'',
      email_afiliado: '',
      user_name: ''
    },
    onSubmit: async (values)=> {
    try{

      values.activacao = values.activacao ? "true" : "false"
      
      const res =  await AXIOS.patch(`/afiliados/${registo.id_afiliado}`,{...values})
      
      if(res.data.status === "sucesso"){
        toast({
          title: t('Actualização de afiliados'),
          description: t(res.data.mensagem),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        
      }else{
        toast({
          title: t('Actualização de afiliados'),
          description: t(res.data.mensagem),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      navigate('/afiliados')
      axiosReferencias(); 
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
      
    },
  })
  
  
    useEffect(()=>{
      axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
          
      
          
    },[])
    
    
      formik.initialValues.activacao = registo.activacao == "true" ? true : false
      formik.initialValues.nome_afiliado = registo.nome_afiliado
      formik.initialValues.email_afiliado =  registo.email_afiliado
      formik.initialValues.user_name =  registo.user_name
      
      
      let permissao = user?.pagina_afiliado == "true";

      if(user?.pagina_afiliado) permissao = user?.pagina_afiliado == "true"
      else permissao = user.numero_entidade      
      
      
  return (
  
  <>
    <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
      <SideBarMenu/>
      <Box flex={1}>
          <NavBar titulo={t("Modificar afiliado")} imagem={<MdSupervisorAccount size={200}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
              {permissao ?   <Box px={10} color="black" mt={10}>
                {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>{t("Modificar endpoint")}</Text> */}
                {loader ?
                <Flex color="black">
                    {registo?.id_afiliado && <Card flex={1} boxShadow="md">
                          <CardHeader>
                          </CardHeader>
                        
                          <CardBody>
                            <Stack divider={<StackDivider />} spacing='4'>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                  <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                      {t("Activo")}
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                      {registo.activacao == "true" ? <BsToggleOn size={40} color='green' /> : <BsToggleOff size={40} color='red' />}
                                    </Text>
                                  </Box>
                              </Flex>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Email")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.email_afiliado ? registo.email_afiliado : <MdClose size={40} color='red' />}
                                </Text>
                              </Box>                              
                              <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Nome completo")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.nome_afiliado}
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("user name")}
                                  </Heading>
                                  <Text pt='2' wordBreak="break-word" fontSize='small'>
                                    {registo.user_name}
                                  </Text>
                                </Box>
                              </Flex>
                              <Flex mb={5} direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Criado em")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {new Date(registo.gerado_em_).toLocaleDateString()} {new Date(registo.gerado_em_).toLocaleTimeString()}
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Actualizado em")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {new Date(registo.actualizado_em_).toLocaleDateString()} {new Date(registo.actualizado_em_).toLocaleTimeString()}
                                  </Text>
                                </Box>
                              </Flex>
                            </Stack>
                          <ModalMudarSenha mudarSenha={mudarSenha} />
                          <Button as={Link} to={`/afiliado/permissoes/${registo.id_afiliado}`} leftIcon={<MdAccountBox/>} onChange={formik.handleChange} bg="orange.400" _hover={{backgroundColor:"orange.600"}} color="white" ml={2}  mt={0}>{t("Permissões")}</Button>
                          </CardBody>
                    </Card>}
                    {registo?.id_afiliado && <Box flex={1} px={10}>
                    <form onSubmit={formik.handleSubmit}>
                      <Box bg='white' p={10} rounded={5} boxShadow="md">
                       <FormControl>
                            <FormLabel>Nome completo</FormLabel>
                            <Flex direction="row" alignItems="center">
                              <Input type='text' name='nome_afiliado' onChange={formik.handleChange} value={formik.values.nome_afiliado} />
                             </Flex>
                        </FormControl>
                        <FormControl mt={4} justifyContent="space-between" display='flex' alignItems='center'>
                          <FormLabel htmlFor='activacao' mb='0'>
                            {t("Activo?")}
                          </FormLabel>
                          <Switch id='activacao' name='activacao' onChange={formik.handleChange} value={formik.values.activacao} defaultChecked={formik.values.activacao} /> 
                        </FormControl>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <FormControl mt={2}>
                              <FormLabel>Username</FormLabel>
                              <Input type='text' name='user_name' onChange={formik.handleChange} required value={formik.values.user_name} />
                          </FormControl>
                          <FormControl mt={2} ml={2}>
                              <FormLabel>Email</FormLabel>
                              <Input type='email' name='email_afiliado'  onChange={formik.handleChange} value={formik.values.email_afiliado} />
                          </FormControl>
                        </Flex>
                        <Button display="block" leftIcon={<MdSave/>} onChange={formik.handleChange} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Actualizar")}</Button>
                     
                    </Box> 
                  </form>
                    </Box>}
                    {(registo.length == false) && <Center width="100%">
                      <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                          <MdError size={200} color="red"/>
                          <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Afiliados invalido")}</Heading>
                          <Text>{t("Desculpamos pelo transtorno causado, não conseguimos ter informações dos seus dados.")}</Text>
                          
                          <Text as={Link} mt={10} to="/afiliados" color='blue.500'>{t("Voltar")}</Text>
                      </Flex>
                    </Center>}
                </Flex>
                :
                <Center>
                {/* <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                /> */}
                <Loader />
              </Center>}
              </Box>
              :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </>
  )
}

export default Referencias