import { Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Button, Center, Spinner, useToast, Avatar, Tag, TagLabel } from '@chakra-ui/react'
  import AXIOS from '../../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import {  MdArrowBack, MdArrowForward, MdEdit, MdUpgrade, MdLock, MdLockOpen, MdError } from "react-icons/md"
import { BotaoTop, DrawerEntidades, ModalRemoveEntidade, ModalRemoveRef, ModalViewEntidadesAdm,  NavBarAdm, SideBarMenuAdm } from '../../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'

function Entidades() {

  const [referencias, setReferencias] = useState([])    
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState({})
  const [checkBox, setCheckBox] = useState([])
  const [check, setcheck] = useState(false)
  const refREF = useRef(null)
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  
  async function axiosReferencias(){
    
    const referencia = await AXIOS.get(`/clientes`)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem)
    }
    
    setLoader(true)
        
}

const actualizarEntidades = async ()=>{

  setLoader(false)
    
  const referencia = await AXIOS.get(`/clientes`)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
    setReferencias(referencia.data.mensagem)
    setReferenciasFilter(referencia.data.mensagem)
  }
  
  setLoader(true)
  console.log(referencia.data)
}

const excluirEntidade = async (ref)=>{
    const refs = referencias.filter(r =>r.id_clientes != ref.id_clientes)
    const res = await AXIOS.delete(`/clientes/${ref.id_clientes}`)
    if(res.data.status == "sucesso"){
      setTimeout(()=>{
        axiosReferencias()
        toast({
          title: 'Exclusão de entidade',
          description: res.data.mensagem,
          status: 'info',
          duration: 5000,
          isClosable: true,
        })
      },2000)
    }else{
      setTimeout(()=>{
        toast({
          title: 'Exclusão de entidade',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },2000)
    }
}

const bloqueiarDesbloquear = async (vl) => {
      toast({
        title: 'Acesso de entidades',
        description: "Á carregar",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })

      if(vl.bloqueio == 1){
        const res = await AXIOS.patch(`/clientes/bloquear/${vl.id_clientes}`)
        if(res.data.status == "erro"){

          setTimeout(()=>{
          toast({
            title: 'Acesso de entidades',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },2000)
        }else{

          setTimeout(()=>{
          toast({
            title: 'Acesso de entidades',
            description: res.data.mensagem,
            status: 'warning',
            duration: 5000,
            isClosable: true,
          })
        },2000)
        }
      }else{
        const res = await AXIOS.patch(`/clientes/desbloquear/${vl.id_clientes}`)
        if(res.data.status == "erro"){
          setTimeout(()=>{
          toast({
            title: 'Acesso de entidades',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },2000)
        }else{
          setTimeout(()=>{
          toast({
            title: 'Acesso de entidades',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },2000)
        }
      }
      
      axiosReferencias()
}

  useEffect(()=>{
     
      axiosReferencias(); 
        
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
          navigate('/adm/login')
        }
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  let permissao = user?.pagina_entidades == "true";

  if(user?.pagina_entidades) permissao = user?.pagina_entidades == "true"
  else permissao = user.todas_areas ? true : false;
  
return (
<>
<Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>{

        permissao

        ? 
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Minhas entidades</Text>
          <DrawerEntidades axiosReferencias={axiosReferencias}  />
          <Button onClick={actualizarEntidades} bg="gray.600"
                    _hover={{ backgroundColor: "gray.700" }} color="white" ml={2} leftIcon={<MdUpgrade/>}>Actualizar lista</Button>
        {loader ?
        <TableContainer my={5} bg="white">
          <Table variant='striped' colorScheme='gray'>
            <TableCaption>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
            </TableCaption>
            <Thead p={10}>
              <Tr bg="gray.600">
                <Th py={6} color="gray.300">Entidade</Th>
                {/* <Th py={6} color="gray.300">NIF</Th> */}
                <Th py={6} color="gray.300">N. de entidade</Th>
                {/* <Th py={6} color="gray.300">C. para referências</Th> */}
                <Th py={6} color="gray.300">Email</Th>
                <Th py={6} color="gray.300">Responsavel</Th>
                <Th py={6} color="gray.300">Acesso</Th>
                <Th py={6} color="gray.300">Acção</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map(el =>
                  <Tr key={el.id_clientes} color="blackAlpha.700">
                    <Td _hover={{color:"blue.500"}} fontWeight={900}><Link to={`/adm/dashboard/${el.numero_entidade}`}>{el.nome_empresa}</Link></Td>
                    {/* <Td>{el.nif}</Td> */}
                    <Td>{el.numero_entidade}</Td>
                    {/* <Td>{el.num_padrao_referencias}</Td> */}
                    <Td>{el.email}</Td>
                    <Td>
                      
                      <Tag size='lg' colorScheme='blue' borderRadius='full'>
                        <Avatar
                          src={`/images/${el.logo}`}
                          size='md'
                          name={el.responsavel}
                          ml={-1}
                          mr={2}
                        />
                        <TagLabel fontSize={14}>{el.responsavel}</TagLabel>
                      </Tag>
                      </Td>
                    <Td>{el.bloqueio == 1 ? <span style={{color:"green", fontWeight:900}}>Activo</span> : <span style={{color:"red", fontWeight:900}}>Inactivo</span>}</Td>
                    <Td>
                        <ModalViewEntidadesAdm registo={el}/>
                        <Button ml={2} leftIcon={<MdEdit />} as={Link} to={`/adm/entidades/${el.id_clientes}`} bg='green.500' color="white" variant='solid'></Button>  
                        {[1].includes(user.id_tipo_usuario)

                        ? <>                       
                        <ModalRemoveEntidade registo={el} excluirRef={excluirEntidade}/>
                        <Button bg="orange.500" color="white" onClick={() => bloqueiarDesbloquear(el)} ml={2} leftIcon={ el.bloqueio == 0 ? <MdLock/> : <MdLockOpen/>}></Button>
                        </>: null}
                    </Td>
                  </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        :
        <Center>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>}
          </Box>
        :
          
        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
      }
    </Box>
  </Flex>
</>
)
}

export default Entidades