import {
  Flex,
  Box,
  Text,
  Heading,
  Center,
  useToast,
  Button,
  Input
} from "@chakra-ui/react";
import { SideBarMenu, NavBar, Loader, BotaoTop } from "../Components/index";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AXIOS from '../Config/axiosConfig'
import { MdCached, MdCopyAll, MdError, MdUpdate } from "react-icons/md";
import CONFIG from "../Config";
import { useTranslation } from "react-i18next";
import { AiFillApi } from "react-icons/ai";

function TokenAPI() {
  const [referencias, setReferencias] = useState([]);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState("1");
  const [loader, setLoader] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const toast = useToast();
  const refSecret = useRef();

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      const referencia = await AXIOS.get(
        `/credencial-clientes/entidade/${entidade}`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        if (referencia.data.mensagem.length) {
          setReferencias(referencia.data.mensagem[0]);
          setSecretKey(referencia.data.mensagem[0].secret_api);
        } else {
          setReferencias([]);
        }
      }

      console.log(referencia.data.mensagem);
      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  const copiarToken = (value) => {
    try {
      navigator.clipboard.writeText(value);
      toast({
        title: t("Token"),
        description: t("Token copiado com sucesso"),
        status: "success",
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  const gerarToken = async () => {
    try {
      const dados = {
        entidade: user.numero_entidade,
        secret_api: secretKey
      };

      const res = await AXIOS.post(
        `/credencial-clientes`,
        dados
      );
      console.log(res.data);
      if (res.data.status == "sucesso") {
        setReferencias({ token_api: res.data.mensagem.token });
        toast({
          title: t("Token gerado"),
          description: t("Token gerado com sucesso"),
          status: "success",
          duration: 3000,
          isClosable: true
        });
        axiosReferencias();
      } else {
        toast({
          title: t("Token gerado"),
          description: t(res.data.mensagem),
          status: "error",
          duration: 3000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  const gerarSecretKey = () => {
    const secret = btoa(Math.random() + "" + Math.random());
    refSecret.current.value = secret;
    setSecretKey(secret);
  };

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);
          
  let permissao = user?.pagina_token == "true";

  if(user?.pagina_token) permissao = user?.pagina_token == "true"
  else permissao = user.numero_entidade

  return (
    user?.habilitar_area_tecnica == "true"  ?  <>
      <Flex color="white" bg="white" direction="row">
        <SideBarMenu />
        <BotaoTop/>
        <Box flex={1}>
          <NavBar titulo={t("Gestão de tokens")} imagem={<AiFillApi size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          <Flex mt={5}
            direction="column"
            justifyContent="space-between"
            style={{ height: "90vh" }}
          >
            {permissao ?  <Box px={10} color="black" mt={10}>
              {/* <Text
                fontSize={50}
                color="blackAlpha.600"
                fontWeight="bold"
                mb={10}
              >
                {t("Gestão de tokens")}
              </Text> */}
              {loader ? (
                <Flex color="black" bg="white" rounded={5} p={10} boxShadow="md">
                  <Flex
                    direction="column"
                    justifyContent="space-between"
                    style={{ width: "100vh" }}
                  >
                    <Heading color="gray.600" fontSize={20} mb={2}>
                      {t("Token API")}
                    </Heading>
                    <Box
                      style={{ width: 700 }}
                      border="0.4px solid #eee"
                      p={3}
                      rounded={10}
                    >
                      {referencias.token_api}
                    </Box>
                    <Flex>
                      <Button
                        mr={2}
                        bg="blue.400"
                        _hover={{ backgroundColor: "blue.600" }}
                        onClick={gerarToken}
                        leftIcon={<MdCached />}
                        mt={2}
                        color="white"
                      >
                        {t("Gerar")}
                      </Button>
                      {referencias.token_api && (
                        <Button
                          leftIcon={<MdCopyAll />}
                          onClick={() => copiarToken(referencias.token_api)}
                          mt={2}
                          bg="green.500"
                          color="white"
                          w={100}
                        >
                          {t("Copiar")}
                        </Button>
                      )}
                    </Flex>
                  </Flex>
                  <Flex direction="column">
                    <Heading color="gray.600" fontSize={20} mb={2}>
                      {t("Chave de segredo")}
                    </Heading>
                    <Input
                      onChange={(evt) => setSecretKey(evt.target.value)}
                      type="text"
                      name="secretKey"
                      value={secretKey}
                      minLength={25}
                      ref={refSecret}
                      width={400}
                    />
                    <Button
                      bg="blue.400"
                      _hover={{ backgroundColor: "blue.600" }}
                      color="white"
                      onClick={gerarSecretKey}
                      leftIcon={<MdUpdate />}
                      mt={2}
                    >
                      {t("Gerar")}
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
    :
    navigate("/404")
  );
}

export default TokenAPI;
