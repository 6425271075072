import { Flex, Box, Text, Heading} from '@chakra-ui/react'  
import { SideBarMenu, NavBar, BotaoTop } from '../Components/index'
import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { SiDocsdotrs } from 'react-icons/si'
import { RiComputerLine } from 'react-icons/ri'



function Docs() {
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"  
  const navigate =  useNavigate()
  const [user, setUser] = useState('1')
  const {t, i18n} = useTranslation()

  useEffect(()=>{
      
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
          navigate('/login')
        }
  },[])
  
return (
  user?.habilitar_area_docs == "true"  ?  <>
    <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
      <SideBarMenu/>
      <BotaoTop/>
      <Box flex={1}>
        <NavBar  titulo={t("Documentação")} imagem={<RiComputerLine size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
        <Flex mt={5} direction="column" justifyContent="space-between" p={10} style={{minHeight:'90vh'}}>
            <Box px={10} color="black" mt={10} flex={1} boxShadow="md">
            {/* <Heading fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>{t("Documentação")}</Heading> */}
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            <Box bg="white" rounded={5} p={10}>          
            <Heading fontSize={20} color="blackAlpha.800" fontWeight="bold" mb={1}>{t("Referências")}</Heading>
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            <Heading fontSize={20} color="blackAlpha.800" fontWeight="bold" mb={1}>{t("Pagamentos")}</Heading>
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            <Heading fontSize={20} color="blackAlpha.800" fontWeight="bold" mb={1}>{t("Ficheiros")}</Heading>
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            <Heading fontSize={20} color="blackAlpha.800" fontWeight="bold" mb={1}>{t("API WebService")}</Heading>
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            <Heading fontSize={20} color="blackAlpha.800" fontWeight="bold" mb={1}>{t("Tokens")}</Heading>
            <Text mb={10} textAlign="justify">                  
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi
            </Text>
            </Box>
          </Box>
          <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>   
      </Box>
    </Flex>
  </>
  :
  navigate("/404")
)
}

export default Docs