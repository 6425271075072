import {
  Flex,
  Box,
  Text,
  Center,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Button,
  useToast,
  Divider,
} from "@chakra-ui/react";
import AXIOS from "../../Config/axiosConfig";
import { useEffect, useState } from "react";
import {
  SideBarMenuAdm,
  NavBarAdm,
  Loader,
  BotaoTop,
} from "../../Components/index";
import { useNavigate, useParams } from "react-router-dom";
import CONFIG from "../../Config";
import { MdError } from "react-icons/md";
import Contabilistico from './../PeriodoContabiblistico';
import { useTranslation } from "react-i18next";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

function Definicoes() {
  const [referencias, setReferencias] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.ADM))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.ADM))
        .split(CONFIG.COOKIE_SESSION.ADM)[1]
    : "{}";
  const [user, setUser] = useState("1");
  const [loader, setLoader] = useState(false);
  const {t, i18n} = useTranslation()  
  const toast = useToast();

  async function axiosReferencias() {
    const {
      mensagem: { empresa_contratante: empresa },
    } = JSON.parse(loginValue);
    const referencia = await AXIOS.get(`/clientes/${id}`, { headers: { empresa } });
    

    if (referencia.data.status === "erro") setReferencias([]);
    else {
      setReferencias(referencia.data.mensagem[0]);
    }

    setLoader(true);
  }
  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.tipo_usuario) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  const activateDefinicaoDoc = async (valor) => {
    try {

      toast({
        title: t("Ativação de definição"),
        description: "Executando pedido",
        status: "loading",
        duration: 500,
        isClosable: true
      });

      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/definicoes-area-doc/${id}`, {
        ...values
      });
      

      if (res.data.status === "sucesso") {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "success",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      } else {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const activateDefinicaoTecnica = async (valor) => {
    try {

      toast({
        title: t("Ativação de definição"),
        description: "Executando pedido",
        status: "loading",
        duration: 500,
        isClosable: true
      });

      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/definicoes-area-tecnica/${id}`, {
        ...values
      });


      if (res.data.status === "sucesso") {
        setTimeout(() =>
        toast({
          title: t("Ativação de definição"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 2000,
          isClosable: true
        })
      ,1000)
      } else {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };
  const activateDefinicaoPRT = async (valor) => {
    try {

      toast({
        title: t("Ativação de definição"),
        description: "Executando pedido",
        status: "loading",
        duration: 500,
        isClosable: true
      });

      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/definicoes-coluna-prt-pagamentos/${id}`, {
        ...values
      });


      if (res.data.status === "sucesso") {
        setTimeout(() =>
        toast({
          title: t("Ativação de definição"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 2000,
          isClosable: true
        })
      ,1000)
      } else {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };
  const activateDefinicaoMFT = async (valor) => {
    try {

      toast({
        title: t("Ativação de definição"),
        description: "Executando pedido",
        status: "loading",
        duration: 500,
        isClosable: true
      });

      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/definicoes-coluna-mft-pagamentos/${id}`, {
        ...values
      });


      if (res.data.status === "sucesso") {
        setTimeout(() =>
        toast({
          title: t("Ativação de definição"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 2000,
          isClosable: true
        })
      ,1000)
      } else {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };
  const activateDefinicaoConcliacao = async (valor) => {
    try {

      toast({
        title: t("Ativação de definição"),
        description: "Executando pedido",
        status: "loading",
        duration: 500,
        isClosable: true
      });

      const values = {};
      values.estado = valor;
      const res = await AXIOS.patch(`/clientes/definicoes-coluna-conciliacao-pagamentos/${id}`, {
        ...values
      });


      if (res.data.status === "sucesso") {
        setTimeout(() =>
        toast({
          title: t("Ativação de definição"),
          description: t(res.data.mensagem),
          status: "success",
          duration: 2000,
          isClosable: true
        })
      ,1000)
      } else {
        setTimeout(() =>
          toast({
            title: t("Ativação de definição"),
            description: t(res.data.mensagem),
            status: "error",
            duration: 2000,
            isClosable: true
          })
        ,1000)
      }
      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };


  let permissao = user?.pagina_entidades == "true";

  if (user?.pagina_entidades) permissao = user?.pagina_entidades == "true";
  else permissao = user.todas_areas ? true : false;;

  return (
    <>
      <Flex color="white" bg="gray.300" direction="row">
        <SideBarMenuAdm />
        <BotaoTop />
        <Box flex={1}>
          <NavBarAdm />
          {permissao ? (
            <Box px={10} color="black" mt={10}>
              <Text
                fontSize={50}
                color="blackAlpha.600"
                fontWeight="bold"
                mb={10}
              >
                Definicões de exibição
              </Text>
              {loader ? (
                <Box shadow="sm" rounded={5} p={0}>
                  <Text
                    fontSize={30}
                    color="gray.600"
                    fontWeight="bold"
                    mb={5}
                  >
                    Paginas
                  </Text>

                  <SimpleGrid
                    spacing={4}
                    templateColumns="repeat(auto-fill, minmax(30%, 1fr))"
                  >
                    <Card>
                      <CardHeader>
                        <Heading size="md"> Gestão de pagamentos e referências</Heading>
                      </CardHeader>
                      <CardBody>
                        <Text>
                          Esta area o cliente define e faz a gestão core da plataforma.
                           Pagamentos, referencias, periodos contabilistico e relatorios
                        </Text>
                      </CardBody>
                      <CardFooter>
                        
                          <BsToggleOn
                            
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                      </CardFooter>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Heading size="md"> Consulta de documentação </Heading>
                      </CardHeader>
                      <CardBody>
                        <Text>
                          Manual aprensatado para tecnicos. Nessa area disponiliza-se conceitos e directrizes para integração com os nossos serviços
                        </Text>
                      </CardBody>
                      <CardFooter>
                      {referencias.habilitar_area_docs == "true" ? (
                          <BsToggleOn
                            onClick={() =>
                              activateDefinicaoDoc(
                                "false"
                              )
                            }
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                        ) : (
                          <BsToggleOff
                            onClick={() =>
                              activateDefinicaoDoc("true")
                            }
                            size={50}
                            cursor="pointer"
                            color="red"
                          />
                        )}
                      </CardFooter>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Heading size="md"> Configurações tecnicas</Heading>
                      </CardHeader>
                      <CardBody>
                        <Text>
                          Voltado para desenvolvimento e integrações. O cliente fará parametrização dos seus serviços com o nosso 
                        </Text>
                      </CardBody>
                      <CardFooter>
                      {referencias.habilitar_area_tecnica == "true" ? (
                          <BsToggleOn
                            onClick={() =>
                              activateDefinicaoTecnica(
                                "false"
                              )
                            }
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                        ) : (
                          <BsToggleOff
                            onClick={() =>
                              activateDefinicaoTecnica("true")
                            }
                            size={50}
                            cursor="pointer"
                            color="red"
                          />
                        )}
                      </CardFooter>
                    </Card>
                  </SimpleGrid>

                  <Divider mt={5} mb={5}/>

                  <Text
                    fontSize={30}
                    color="gray.600"
                    fontWeight="bold"
                    mb={5}
                  >Colunas de tabela</Text>

                  <SimpleGrid
                    spacing={4}
                    templateColumns="repeat(auto-fill, minmax(30%, 1fr))"
                  >
                    <Card>
                      <CardHeader>
                        <Heading size="md">Coluna de Notificação do PRT</Heading>
                      </CardHeader>
                      <CardFooter>
                      {referencias.habilitar_area_coluna_prt_tabela_pagamentos == "true" ? (
                          <BsToggleOn
                            onClick={() =>
                              activateDefinicaoPRT(
                                "false"
                              )
                            }
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                        ) : (
                          <BsToggleOff
                            onClick={() =>
                              activateDefinicaoPRT("true")
                            }
                            size={50}
                            cursor="pointer"
                            color="red"
                          />
                        )}
                      </CardFooter>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Heading size="md">Coluna de conciliação MFT (FMOV) </Heading>
                      </CardHeader>
                      <CardFooter>
                      {referencias.habilitar_area_coluna_mft_tabela_pagamentos == "true" ? (
                          <BsToggleOn
                            onClick={() =>
                              activateDefinicaoMFT(
                                "false"
                              )
                            }
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                        ) : (
                          <BsToggleOff
                            onClick={() =>
                              activateDefinicaoMFT("true")
                            }
                            size={50}
                            cursor="pointer"
                            color="red"
                          />
                        )}
                      </CardFooter>
                    </Card>
                    <Card>
                      <CardHeader>
                        <Heading size="md">Concluna estatus conclianção FMOV</Heading>
                      </CardHeader>
                      <CardFooter>
                      {referencias.habilitar_area_coluna_concliacao_tabela_pagamentos == "true" ? (
                          <BsToggleOn
                            onClick={() =>
                              activateDefinicaoConcliacao(
                                "false"
                              )
                            }
                            size={50}
                            cursor="pointer"
                            color="green"
                          />
                        ) : (
                          <BsToggleOff
                            onClick={() =>
                              activateDefinicaoConcliacao("true")
                            }
                            size={50}
                            cursor="pointer"
                            color="red"
                          />
                        )}
                      </CardFooter>
                    </Card>
                  </SimpleGrid>

                </Box>
              ) : (
                <Center>
                  <Loader />
                </Center>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              height="100vh"
              justifyContent="center"
              alignItems="center"
              px={10}
              color="black"
              mt={10}
            >
              <Text fontSize={34} fontWeight={700}>
                Não têm permissão para essa página
              </Text>
              <Text fontSize={18}>
                Contacte ao administrador para possiveis resoluções
              </Text>
              <MdError color="red" size={200} />
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
}

export default Definicoes;
