import { Box, Flex, Input, Wrap, WrapItem, Avatar, Menu, Text, MenuButton, MenuGroup, MenuItem, MenuList, MenuDivider, Badge, useToast } from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import { MdLogout, MdNotifications } from 'react-icons/md'
import { BsGlobeEuropeAfrica } from 'react-icons/bs'
import { useNavigate, Link } from 'react-router-dom'
import CONFIG from '../Config'
import FUNDO from '../Assets/images/19381165_6128479.jpg'
import AXIOS from '../Config/axiosConfig'
import { useTranslation } from 'react-i18next'

function NavBar({titulo, dizeres, imagem}) {
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState('1')
    const [notificacoes, setNotificacoes] = useState({lidas:[], naoLidas: []})
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    const clearLogout = async function(){
      try {
          const res =  await AXIOS.post(`/clientes/logout`,{numero_entidade:user.numero_entidade})
        
          if(res.data.status == "sucesso"){
            document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0'
            navigate('/login')
          }else{
            toast({
              title: t('Terminar sessão'),
              description:  t("Não coseguimos fazer login. Verifique sua internet"),
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
      } catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
      }  
      
      
    }
    
    const axiosGet = async () => {
      
      try {
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem?.numero_entidade
        const getItensLidas = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}/lidas`)
        const getItensNaoLidas = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}/nao-lidas`)
        
          if(getItensLidas.data.status === "erro")
              setNotificacoes({lidas:[], naoLidas: []})
          else{
              setNotificacoes(prev => ({...prev, lidas: getItensLidas.data.mensagem}))
          }
            
          if(getItensNaoLidas.data.status === "erro")
              setNotificacoes({lidas:[], naoLidas: []})
          else{
              setNotificacoes(prev => ({...prev, naoLidas: getItensNaoLidas.data.mensagem}))
          }
              
          
        }catch (error) {
            toast({
              title: t('Alerta'),
              description: t("Não conseguimos ter comunicação! Verifique sua internet"),
              status: 'error',
            })
        }
    }
    
    useEffect(()=>{
      axiosGet()
        if(loginValue){
         const value = JSON.parse(loginValue)
         setUser(value.mensagem)
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
},[])
  return (
    <Box borderBottom="5px solid" borderColor="#ea7f07" width="100%" bg="blue.600" shadow="base" bgImage={FUNDO} bgPosition="center" bgRepeat="no-repeat" bgSize="cover">
      <Flex justifyContent="space-between" alignItems="center"  p={3} bgPosition="center" bgSize="cover">
        <Box fontSize={16} color="white"><b>{user?.nome_afiliado ? user?.nome_afiliado : user?.responsavel}</b>  ({user?.nome_empresa}) &nbsp;&nbsp;&nbsp;</Box>
        <Box display="flex">
            {/* <Input flex={1} width="lg" type='search' rounded="full" placeholder={t('Pesquise aqui suas duvidas')} bg="whiteAlpha.900" /> */}
        </Box>
        <Flex justifyContent="space-around" alignItems="center" color="white">
            <><MdNotifications size={25} cursor="pointer" /><Badge colorScheme='red'>{notificacoes.naoLidas.length}</Badge></>            
            <Menu colorScheme='gray'>
              <MenuButton  ml={5}>
                <BsGlobeEuropeAfrica size={20} />
              </MenuButton>
              <MenuList color="gray.900">
                <MenuItem onClick={()=> i18n.changeLanguage('pt')} as='a' href='#'>{t("Português")}</MenuItem>
                <MenuItem onClick={()=> i18n.changeLanguage('en')} as='a' href='#'>{t("Inglês")}</MenuItem>
                <MenuItem onClick={()=> i18n.changeLanguage('es')} as='a' href='#'>{t("Espanhol")}</MenuItem>
                <MenuItem onClick={()=> i18n.changeLanguage('fr')} as='a' href='#'>{t("Françês")}</MenuItem>
                <MenuItem onClick={()=> i18n.changeLanguage('ar')} as='a' href='#'>{t("Ârabe")}</MenuItem>
                <MenuItem onClick={()=> i18n.changeLanguage('it')} as='a' href='#'>{t("Italiano")}</MenuItem>
              </MenuList>
            </Menu>
            <Menu colorScheme='gray'>
              <MenuButton>
                  <Wrap mx={5} cursor="pointer">
                    <WrapItem>
                        <Avatar name={user?.responsavel} src={`${CONFIG.HOST_API}/images/${user.logo}`}/>
                    </WrapItem>
                  </Wrap>
              </MenuButton>
              <MenuList color="gray.900">
                <MenuGroup title={t('Perfil')}>
                  <MenuItem as={Link} to="/minha-conta">{t("Minha conta")}</MenuItem>
                  <MenuItem as={Link} to="/pagamentos">{t("Pagamentos")}</MenuItem>
                  <MenuItem as={Link} to="/notificacoes">{t("Notificações")}</MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title={t('Ajuda')}>
                  <MenuItem as={Link} to="/docs">{t("Documentação")}</MenuItem>
                  <MenuItem as={Link} to="/faq">{t("FAQ")}</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <MdLogout cursor="pointer" onClick={clearLogout} size={25}/>
        </Flex>
    </Flex>
    {titulo && <Flex  mt={5} justifyContent="center">
      <Box p={3} width="30%">
          <Text fontSize={45} fontWeight={600}>{titulo}</Text>
          <Text fontSize={16}>{dizeres}</Text>
      </Box>
      <Box p={3} width="30%">
          {imagem}
      </Box>
    </Flex>}
    
    </Box>
  )
}

export default NavBar
