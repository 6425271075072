import { useEffect, useRef, useState } from 'react'
import { MdClose, MdError, MdPeople,} from "react-icons/md"
import {  BotaoTop, ModalMudarSenha, NavBarAdm, SideBarMenuAdm } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Select, Center, Spinner, useToast} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'

function EditarUsuarios() {

    const [registo, setReferencias] = useState({})       
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({})
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const REFForm = useRef(null)
    
    const toast = useToast()
    
    async function axiosReferencias(){
      const referencia = await AXIOS.get(`/usuarios/${id}`);
      
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        setReferencias(referencia.data.mensagem[0])
      }
      
      setLoader(true)
      
      if(referencia.data.mensagem.length){
            console.log(REFForm.current)
      }else{
        navigate('/adm')
      }
          
  }    
  
  
  const formik = useFormik({
    initialValues:{
      nome_usuario:'',
      tipo_usuario:'',
      email_: '',
      senha_: '',
    },
    onSubmit: async (values)=> {
      const res =  await AXIOS.patch(`/usuarios/${registo.id_usuarios}`,values)
      
      if(res.data.status === "sucesso"){
        axiosReferencias()
        
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de usuario',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },2000)
      }else{
        toast({
          title: 'Actualização de usuario',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
      
    },
  })
  
  
  const mudarSenha = async (senha_, repetirsenha)=>{
  
    if(senha_ == repetirsenha){
      const res =  await AXIOS.patch(`/usuarios/${registo.id_usuarios}`,{senha_})
      
      toast({
        title: 'Actualização de senha',
        description: "Á processar",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })

      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          axiosReferencias()
        },2000)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },2000)
      }
    }else{
      toast({
        title: 'Actualização de senha',
        description: "Senhas devem ser iguais",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
  
  
  
  
    useEffect(()=>{
       
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/adm/login')
          }
          
          // if(id) navigate('/referencias')
    },[])
    
    
  
  formik.initialValues.nome_usuario = registo.nome_usuario
  formik.initialValues.tipo_usuario = registo.tipo_usuario
  formik.initialValues.contacto = registo.contacto
  formik.initialValues.email_ = registo.email_

  let permissao = user?.pagina_usuarios == "true";

  if(user?.pagina_usuarios) permissao = user?.pagina_usuarios == "true"
  else permissao = user.todas_areas ? true : false;
  
  return (
    <>
    <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>{
        
          permissao

          ?

          <Box px={10} color="black" mt={10}>
            <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Modificar usuarios</Text>
            {loader ?
            <Flex color="black" direction="column">
            
                <Flex mt={5}>
                  {registo?.id_usuarios && <Card flex={1}>
                        <CardHeader>
                          <Heading size='md'>#: {registo.id_usuarios}</Heading>
                          <Heading size='sm'>{registo.nome_usuario}</Heading>
                        </CardHeader>
                      
                        <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Criado em
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.cadastrado_em ? new Date(registo.cadastrado_em).toLocaleDateString() + " " + new Date(registo.cadastrado_em).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Actualizado em
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.actualizado_em ? new Date(registo.actualizado_em).toLocaleDateString() + " " + new Date(registo.actualizado_em).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Como
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.tipo_nome_usuario ? registo.tipo_nome_usuario : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                               Email
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.email_ ? registo.email_ : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Box>
                              <ModalMudarSenha mudarSenha={mudarSenha} />
                            </Box>
                        </Stack>
                        </CardBody>
                  </Card>}
                  {registo?.id_usuarios && <Box flex={1} px={10}>
                  <form onSubmit={formik.handleSubmit}>
                  <Box bg='white' p={10} rounded={5} shadow="sm">
                   <FormControl>
                        <FormLabel>Nome do usuario</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='nome_usuario' onChange={formik.handleChange} value={formik.values.nome_usuario} />
                         </Flex>
                    </FormControl>
                      <FormControl mt={2}>
                          <FormLabel>Email</FormLabel>
                          <Input type='email' name='email_'  required onChange={formik.handleChange} value={formik.values.email_} />
                      </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>Como</FormLabel>
                        <Select placeholder='Selecione tipo de usuario' required name='tipo_usuario' value={formik.values.tipo_usuario} onChange={formik.handleChange}>
                          <option value={1}>Administrador</option>                       
                          <option value={2}>Gerente</option>                     
                          <option value={3}>Comercial</option>                     
                          <option value={4}>COntablidade</option>                     
                          <option value={5}>Financeiro</option>                     
                      </Select>
                    </FormControl>
                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Actualizar</Button>
                 
                </Box> 
              </form>
                  </Box>}
                  {(registo?.tipo_usuario == false) && <Center>
                      <Heading textAlign="center" fontWeight={300} fontSize={20}>Este usuario invalida! Por vafor selecione um usuario valido</Heading>
                  </Center>}
                </Flex>
            </Flex>
            :
            <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>}
          </Box>
          
          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default EditarUsuarios