import { Flex, Box, Text, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer, Center, Spinner, useToast, Tag, Button } from '@chakra-ui/react'
  import AXIOS from '../../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import { MdCached, MdClose, MdArrowBack, MdArrowForward, MdEdit, MdUpgrade, MdLock, MdLockOpen, MdError } from "react-icons/md"
import { BotaoTop, Drawer, DrawerEntidades, ModalGerarFileRef, ModalRemoveEntidade, ModalRemoveRef, ModalViewEntidadesAdm, ModalViewLogsAdm, ModalViewReference, NavBar, NavBarAdm, SideBarMenu, SideBarMenuAdm } from '../../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import CONFIG from '../../Config'


function Logs() {

  const [referencias, setReferencias] = useState([])    
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState({})
  const [checkBox, setCheckBox] = useState([])
  const [check, setcheck] = useState(false)
  const refREF = useRef(null)
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  
  async function axiosReferencias(){
    
    const referencia = await AXIOS.get(`/logs/entidades/todas`)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem)
      console.log(referencia.data.mensagem)
    }
    
    setLoader(true)
        
}

async function actualizarLogs(){
    
  setLoader(false)
  const referencia = await AXIOS.get(`/logs/entidades/todas`)
  if(referencia.data.status === "erro")
      setReferencias([])
  else{
    setReferencias(referencia.data.mensagem)
    setReferenciasFilter(referencia.data.mensagem)
    console.log(referencia.data.mensagem)
  }
  
  setLoader(true)
      
}


  useEffect(()=>{
     
      axiosReferencias(); 
        
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
          navigate('/adm/login')
        }
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const mudarTagEventoHTTP = (evento) => {

    switch (evento) {
      case "GET":
        return <Tag colorScheme='blue'>{evento}</Tag>
      case "POST":
        return <Tag colorScheme='orange'>{evento}</Tag>
      case "PATCH":
        return <Tag colorScheme='green'>{evento}</Tag>
      case "DELETE":
        return <Tag colorScheme='red'>{evento}</Tag>
    
      default:
          return <Tag colorScheme='black'>{evento}</Tag>
    }
  }

        
let permissao = user?.pagina_req_geral == "true";

if(user?.pagina_req_geral) permissao = user?.pagina_req_geral == "true"
else permissao = user.todas_areas ? true : false;
  
return (
<>
<Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
      {
          
          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Logs do sistema</Text>
          <Button
            bg="gray.600"
            _hover={{ backgroundColor: "gray.700" }}
            ml={1}
            onClick={actualizarLogs}
            leftIcon={<MdUpgrade />}
            color="white"
          >
            Actualizar lista
          </Button>
        {loader ?
        <TableContainer my={5} bg="white">
          <Table  variant='striped' colorScheme='gray'>
            <TableCaption>
              <ReactPaginate
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
            </TableCaption>
            <Thead p={10}>
              <Tr bg="gray.600">
                <Th py={6} color="gray.300">Evento</Th>
                <Th py={6} color="gray.300">Descrição</Th>
                <Th py={6} color="gray.300">Tipo</Th>
                <Th py={6} color="gray.300">Tabela</Th>
                <Th py={6} color="gray.300">IP</Th>
                {/* <Th py={6} color="gray.300">Entidade</Th> */}
                {/* <Th py={6} color="gray.300">Rota API</Th> */}
                {/* <Th py={6} color="gray.300">Informação</Th> */}
                <Th py={6} color="gray.300">Tempo ocorrido</Th>
                <Th py={6} color="gray.300">DETALHES</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map(el =>
                  <Tr key={el.id_logs} color="blackAlpha.700">
                    <Td>
                      {mudarTagEventoHTTP(el.verbo_rota_API)}
                    </Td>
                    <Td>{el.descricao}</Td>
                    <Td>{el.tipo}</Td>
                    <Td>{el.tabela}</Td>
                    <Td>{el.ip}</Td>
                    {/* <Td>{el.entidade}</Td> */}
                    {/* <Td>{el.rota_API}</Td> */}
                    {/* <Td>{el.informacao.substr(0,50)} ...</Td> */}
                    <Td>{new Date(el.data_hora).toLocaleDateString()} {new Date(el.data_hora).toLocaleTimeString()}</Td>
                    <Td><ModalViewLogsAdm registo={el}/></Td>
                  </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
        :
        <Center>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>}
        </Box>
        
        :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Logs