import React, {useState} from 'react'
import { Button,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader, CardBody, Box, Heading, Text, Stack, StackDivider, Flex, Tag, Avatar, TagLabel } from '@chakra-ui/react'
import { MdClose, MdSearch } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }

    const mudarTagEventoHTTP = (evento) => {

      switch (evento) {
        case "GET":
          return <Tag colorScheme='blue'>{evento}</Tag>
        case "POST":
          return <Tag colorScheme='orange'>{evento}</Tag>
        case "PATCH":
          return <Tag colorScheme='green'>{evento}</Tag>
        case "DELETE":
          return <Tag colorScheme='red'>{evento}</Tag>
      
        default:
            return <Tag colorScheme='black'>{evento}</Tag>
      }
    }
    
    return (
      <>
        <Button onClick={onOpen} leftIcon={<MdSearch />} bg='blue.500' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="4xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>Mais informações dos logs gerais</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Card>
                  <CardHeader>
                    <Heading size='md'>ENTIDADE: {registo.entidade}</Heading>
                    <Text>{registo.nome_empresa}</Text>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Criando em
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.criando_em ? new Date(registo.criando_em).toLocaleDateString() + " " + new Date(registo.criando_em).toLocaleTimeString(): <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Actualizado em
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.actualizado_em ? new Date(registo.actualizado_em).toLocaleDateString() + " " + new Date(registo.actualizado_em).toLocaleTimeString(): <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Responsavel
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.responsavel ?                                
                                <Tag size='md' colorScheme='blue' borderRadius='full'>
                                  <Avatar
                                    src={`/images/${registo.logo}`}
                                    size='sm'
                                    name={registo.responsavel}
                                    ml={-1}
                                    mr={2}
                                  />
                                <TagLabel>{registo.responsavel}</TagLabel>
                                </Tag>
                              : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              Activação
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.bloqueio == 1 ? "activo" : "Inactivo" }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              ROTA USADA
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.rota_API ? registo.rota_API : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                             VERBO HTTP
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.verbo_rota_API ? mudarTagEventoHTTP(registo.verbo_rota_API) : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" wrap="wrap" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              DESCRIÇÃO
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.descricao ? registo.descricao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                             TIPO DE EVENTO
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.tipo ?registo.tipo : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" wrap="wrap" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              INFORMAÇÃO ENVIDA OU RECEBIDA
                            </Heading>
                            <Text py={5} px={2} mt={2} w="100%" bg="gray.100" wordBreak="break-word" fontSize='x-small'>
                              {registo.informacao ? registo.informacao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                    </Stack>
                  </CardBody>
                </Card>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
