import { Flex, Box, Text, Center, Button, useToast, FormControl, FormLabel, Input, Alert } from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader, BotaoTop } from '../../Components/index'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react'
import { useFormik } from 'formik'
import { MdClose, MdDelete, MdEmail, MdError, MdSend } from 'react-icons/md'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [emails, setEmails] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast()

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
        console.log(editorRef.current.getContent());
    }
  };
  
  async function axiosReferencias(){
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/clientes`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
      setReferencias(referencia.data.mensagem)
      setReferenciasFilter(referencia.data.mensagem)
    }
    
    setLoader(true)
        
}
  useEffect(()=>{
     
    axiosReferencias(); 
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const formik = useFormik({
    initialValues:{
      assunto:'',
      email:'',
      html:''
    },
    onSubmit: async (values)=> {
      
      if(!values.assunto){
        toast({
          title: 'Alerta',
          description: "Assunto invalido",
          status: 'error',
          duration: 5000,
          isClosable: true,

        })      
        return;
      }

      if(emails.length < 1){
        toast({
          title: 'Alerta',
          description: "Endereço de E-mail vazio",
          status: 'error',
          duration: 5000,
          isClosable: true,

        })      
        return;
      }
      
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
        values.html = editorRef.current.getContent();
      }else{

        toast({
          title: 'Alerta',
          description: "Sem corpo para o E-mail", 
          status: 'error',
          duration: 5000,
          isClosable: true,

        })      
        return;

      }
      
      values.email = emails
      
      const res =  await AXIOS.post(`/clientes/comunicar-por-email`,{...values})

      toast({
        title: 'Comunicado por email',
        description: "Á carregar o envio",
        status: 'success',
        duration: 4000,
        isClosable: true,
      })
      
      if(res.data.status === "sucesso"){

        setTimeout(()=>{
          toast({
            title: 'Comunicado por email',
            description: res.data.mensagem,
            status: 'success',
            duration: 2000,
            isClosable: true,
          })

          axiosReferencias(); 
          setEmails([])
          formik.resetForm()
          editorRef.current.setContent('')

        },5000)
        
      }else{
        setTimeout(()=>{
          toast({
            title: 'Comunicado por email',
            description: res.data.mensagem,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        },5000)
      }

      
    },
  })

  const addEmail = function(){

    if(formik.values.email === "@Todos"){
      setEmails(referencias.map( el => el.email))
    }

    if(!formik.values.email){
      toast({
        title: 'Alerta',
        description: "Email invalido",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      
      return;
    }

    const find = emails.find(el => el == formik.values.email)
  
    if(find){
        return
    }else{
      setEmails(prev => [...prev, formik.values.email])
      formik.values.email = ''
    }
  }
  


const removeEmail = async (ref) => {
  const filtered =  emails.filter(el => el != ref);
  setEmails(filtered)
}

let permissao = user?.pagina_email == "true";

if(user?.pagina_email) permissao = user?.pagina_email == "true"
else permissao = user.todas_areas ? true : false;

return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>{
          
          permissao

          ?

          <Box px={10} color="black" mt={5}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={5}>Comunicar por emails</Text>
        {
        loader
        ?
        <Box shadow="sm" rounded={5} bg="white" p={10} >
            <form onSubmit={formik.handleSubmit}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Box bg='white' w="50%" mb={5}>                      
                      <Flex direction="row" alignItems="center">
                      <FormControl w="45%">
                          <FormLabel>Cliente</FormLabel>
                          <Flex direction="row" alignItems="center">
                            <Button onClick={()=> setEmails([])} colorScheme='red' leftIcon={<MdDelete/>} />
                            <Button ml={2} onClick={addEmail} colorScheme='facebook' leftIcon={<MdEmail/>} />
                            <Input ml={2} type='email' name='email' list='clientes'
                                onChange={formik.handleChange}
                              value={formik.values.email} />
                            <datalist id='clientes'>
                            <option value={"@Todos"}>Todos os clientes</option>
                            {referencias.map(el => <option value={el.email}>{el.nome} {el.sobrenome}</option>)}                        
                            </datalist>
                          </Flex>
                      </FormControl>
                        <FormControl w="45%" ml={2}>
                          <FormLabel>Assunto</FormLabel>
                          <Input type='text' name='assunto' list='montante_pago' required
                              onChange={formik.handleChange}
                            value={formik.values.assunto} />
                          </FormControl>
                      </Flex>
                      </Box>
                      <Button leftIcon={<MdSend/>} type='submit' colorScheme='facebook' mb={5}>Enviar aos clientes</Button>
                  </Flex>
                </form>
                {emails.length ? <Alert flexWrap="wrap" status='info' p={5} my={5}>
                {emails.slice(0,10).map((email) => 
                <>
                  <Flex mb={2}  mr={2} wrap="wrap"  justifyContent="space-between" align="center" bg="blue.200" p={2}>
                    {email} <MdClose cursor="pointer" onClick={() => removeEmail(email)} size={20}/>
                  </Flex>
                </>)}
                {emails.length > 10 ? `Tem mais ${emails.length - 10} clientes ...` : null}
                </Alert>
                : null}
          <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue="<p>Este é o conteudo inicial no editor.</p>"
              init={{
              ui_mode: 'split',
              min_height: 500,
              min_width:100,
              // width: 500,
              height: 500,
              menubar: true,
              language: 'pt_PT',
              hidden_input: false,
              plugins: [
                'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount','autosave','osave',
                "advlist", "autolink", "lists", "link", "image", "charmap", "print", "preview", "anchor",
                          "searchreplace", "visualblocks", "code", "fullscreen",
                          "insertdatetime", "media", "table", "paste", "code", "help", "wordcount"
              ],
              toolbar: 'undo redo | casechange blocks | bold italic backcolor | link image | styles | restoredraft | autosave |' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
          />
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
          </Box>
          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes