import React, { useEffect, useState } from 'react'
import { MdHome, MdLogout, MdEmojiPeople, MdNotifications,  MdHelp, MdWebhook, MdSupervisedUserCircle, MdPayments, MdReport, MdFilePresent} from "react-icons/md"
import { AiFillApi } from 'react-icons/ai'
import { BsBank } from "react-icons/bs"
import { TbPigMoney, TbPigOff } from "react-icons/tb"
import { SiDocsdotrs } from "react-icons/si"
import { RiComputerLine } from "react-icons/ri"
import { Flex, Box, Divider, Heading, Badge, useToast, Center, Image } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import FUNDO from '../Assets/images/3464470.jpg'
import LOGO from '../Assets/images/logo/logo-black-removebg-preview.png'
import AXIOS from '../Config/axiosConfig'
import { useTranslation } from 'react-i18next'
import { BiSolidReport } from 'react-icons/bi'
import { FaSms } from 'react-icons/fa'
import { GiReceiveMoney } from 'react-icons/gi'

function SideBarMenu() {
    const navigate =  useNavigate()
    const [user, setUser] = useState('1')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const [notificacoes, setNotificacoes] = useState({lidas:[], naoLidas: []})
    const {t, i18n} = useTranslation()
    
    const axiosGet = async () => {
      
      try {
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem?.numero_entidade
        const getItensLidas = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}/lidas`)
        const getItensNaoLidas = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}/nao-lidas`)
        
          if(getItensLidas.data.status === "erro")
              setNotificacoes({lidas:[], naoLidas: []})
          else{
              setNotificacoes(prev => ({...prev, lidas: getItensLidas.data.mensagem}))
          }
            
          if(getItensNaoLidas.data.status === "erro")
              setNotificacoes({lidas:[], naoLidas: []})
          else{
              setNotificacoes(prev => ({...prev, naoLidas: getItensNaoLidas.data.mensagem}))
          }
              
          
        }catch (error) {
            toast({
              title: t('Alerta'),
              description: t("Não conseguimos ter comunicação! Verifique sua internet"),
              status: 'error',
            })
        }
    }
    
    const clearLogout = async function(){
      try {
          const res =  await AXIOS.post(`/clientes/logout`,{numero_entidade:user.numero_entidade})
        
          if(res.data.status == "sucesso"){
            document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0'
            navigate('/login')
          }else{
            toast({
              title: t('Terminar sessão'),
              description:  t("Não coseguimos fazer login. Verifique sua internet"),
              status: 'error',
              duration: 4000,
              isClosable: true,
            })
          }
      } catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
      }  
      
      
    }
      
      useEffect(()=>{
        axiosGet()
        if(loginValue){
         const value = JSON.parse(loginValue)
         setUser(value.mensagem)
        }
      },[])
      
    
  return (
    <Box bg='white' style={{minHeight:'100vh'}} color="gray.300" border="1px solid #eee"  className='widthSId' p={5} bgPosition="center" bgSize="cover" >
        <Center mb={0}><Image src={LOGO} width={200} /></Center>
        <Flex p={6} direction="column">
            <Flex bg="#ea7f07" p={5} fontWeight={400} cursor="pointer" color="white" direction="row" alignItems="center"><MdHome size={25}/><Link to="/">&nbsp;&nbsp;&nbsp;{t("Painel")}</Link></Flex>
            <Divider my={5} borderColor="gray.300" />
            <Heading fontWeight={700} textTransform="uppercase"  my={2} size='sm'>{t((user?.servico_pagamento_por_sector == "false") || (user?.servico_gpo == "false") ? "Gestão" : "Serviço referÊncia / Online")}</Heading>
            
            {user?.servico_pagamento_por_sector == "true" && <>
              <Flex fontWeight={user?.pagina_referencia == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_referencia == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><BsBank size={25} /><Link to="/referencias">&nbsp;&nbsp;&nbsp;{t("Referências")}</Link></Flex>
              <Flex fontWeight={user?.pagina_pagamento == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_pagamento == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><TbPigMoney size={25} /><Link to="/pagamentos">&nbsp;&nbsp;&nbsp;{t("Pagamentos")}</Link></Flex>
              <Flex fontWeight={user?.pagina_pagamento == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_pagamento == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><TbPigOff size={25} /><Link to="/pagamentos-nao-processado">&nbsp;&nbsp;&nbsp;{t("Pagamentos Incompletos")}</Link></Flex>
              <Flex fontWeight={user?.pagina_contabilistico == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_contabilistico == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><GiReceiveMoney size={25} /><Link to="/periodo-contabilistico">&nbsp;&nbsp;&nbsp;{t("Periodo Contabilistico")}</Link></Flex>
              <Flex fontWeight={user?.pagina_relatorio == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_relatorio == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><BiSolidReport size={25} /><Link to="/relatorios">&nbsp;&nbsp;&nbsp;{t("Relatórios")}</Link></Flex>
            </>}

            {user?.servico_gpo == "true" && <>
              <Flex fontWeight={user?.pagina_pagamento == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_pagamento == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><MdPayments size={25} /><Link to="/transacoes-online">&nbsp;&nbsp;&nbsp;{t("Pagamentos Online")}</Link></Flex>
              <Flex fontWeight={user?.pagina_relatorio == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_relatorio == "true" ? "gray.800" :"gray.500"} mt={5} direction="row" alignItems="center"><MdFilePresent size={25} /><Link to="/relatorios">&nbsp;&nbsp;&nbsp;{t("Relatórios Online")}</Link></Flex>
            </>}

            { user?.habilitar_area_docs == "true" && <> 
              <Divider my={5} borderColor="gray.300" />
              <Heading fontWeight={700} textTransform="uppercase" my={1} size='sm'>{t("Documentação")}</Heading>
              <Flex fontWeight={user?.pagina_servico_api == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_servico_api == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><SiDocsdotrs size={25}/><Link to="/servico-api">&nbsp;&nbsp;&nbsp;{t("Serviço API")}</Link></Flex>
              <Flex fontWeight={user?.pagina_faq == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_faq == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><MdHelp size={25}/><Link to="/faq">&nbsp;&nbsp;&nbsp;FAQ</Link></Flex>
              </>
            }
              
              <Divider my={5} borderColor="gray.300" />
              <Heading fontWeight={700} textTransform="uppercase" my={1} size='sm'>{t("Configuração")}</Heading>
              <Flex fontWeight={user?.pagina_afiliado == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_afiliado == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><MdSupervisedUserCircle size={25}/><Link to="/afiliados">&nbsp;&nbsp;&nbsp;Afiliados</Link></Flex>
            { user?.habilitar_area_tecnica == "true" && <>
              <Flex fontWeight={user?.pagina_sms == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_sms == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><FaSms size={25}/><Link to="/sms">&nbsp;&nbsp;&nbsp;{t("Notificação SMS")}</Link></Flex>
              <Flex fontWeight={user?.pagina_token == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_token == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><AiFillApi size={25}/><Link to="/tokens">&nbsp;&nbsp;&nbsp;Token API</Link></Flex>
              <Flex fontWeight={user?.pagina_webwook == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_webwook == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><MdWebhook size={25}/><Link to="/webhooks">&nbsp;&nbsp;&nbsp;Webhooks</Link></Flex>
            </>
            }

            <Flex fontWeight={user?.pagina_notificacao == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_notificacao == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><MdNotifications size={25}/><Link to="/notificacoes">&nbsp;&nbsp;&nbsp;{t("Notificações")}</Link><Badge ml={2} colorScheme='red'>{notificacoes.naoLidas.length}</Badge></Flex>
            <Flex fontWeight={user?.pagina_perfil == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_perfil == "true" ? "gray.800" :"gray.500"} mt={3} direction="row" alignItems="center"><MdEmojiPeople size={25}/><Link to="/minha-conta">&nbsp;&nbsp;&nbsp;{t("Perfil")}</Link></Flex>
            <Flex fontWeight={user?.pagina_sair == "true" ? 700 : 400} cursor="pointer" color={user?.pagina_sair == "true" ? "gray.800" :"gray.500"} onClick={clearLogout} mt={3} direction="row" alignItems="center"><MdLogout size={25}/>&nbsp;&nbsp;&nbsp;{t("Sair")}</Flex>
            {/* <Box width={300}></Box> */}
        </Flex>
  </Box>
  )
}

export default SideBarMenu
