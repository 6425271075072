import { Flex, Box, Center, Input, Text, Button, useToast,
  Image,Radio, RadioGroup, Stack } from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useRef, useState } from 'react'
import {useFormik} from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import LOGO from '../Assets/images/logo/logoi.png'
import { useTranslation } from 'react-i18next'

function Login() {

    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [loader, setLoader] = useState(false)
    const [texto, setTexto] = useState({bg:"error", texto:"Senha fraca"})
    const navigate =  useNavigate()
    const toast = useToast()
    const [user, setUser] = useState('1')
    const {t, i18n} = useTranslation()
    
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
    
    function StrengthChecker(PasswordParameter) {
    
      try {
        if(strongPassword.test(PasswordParameter)) {
          setTexto({bg:"success", texto:"Senha forte"})
        } else if(mediumPassword.test(PasswordParameter)) {
          setTexto({bg:"warning", texto:"Senha rasoável"})
        } else {
          setTexto({bg:"error", texto:"Senha fraca"})
        }
        
      }catch(e){
        toast({
          title: 'Alerta',
          description: "Não conseguimos ter comunicação! Verifique sua internet",
          status: 'error',
        })
      }
    }
  
  
    const formik = useFormik({
      initialValues:{
        email:'',
        canal:'E-mail'
      },
      onSubmit: async (values)=> {
        
        try {
        if(values.email){
        
            
            const res =  await AXIOS.post(`/clientes/codigo-seguranca/recuperar`,values)
            console.log(res.data)
            
            if(res.data.status === "sucesso"){
            
              toast({
                title: t('Verificação de usuário'),
                description: t(res.data.mensagem),
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
              
              var now = new Date();
              now.setTime(res.data.info.tempo_de_vida_codigo_seguranca);              
              document.cookie = CONFIG.COOKIE_SESSION.SECURITY_CODE_ENTITY + `${res.data.info.entidade}:${res.data.info.tempo_de_vida_codigo_seguranca}` + ';expires='+now.toGMTString()+';path=/';
              
              setTimeout(() => {
                navigate('/codigo-de-seguranca')
              }, 5000)
              
          }else{
            toast({
              title: t('Verificação de usuário'),
              description:  t(res.data.mensagem),
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          }         
                
        }else{
          toast({
            title: t('Aviso'),
            description:  t("Campo de E-mail vazio!"),
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
        
          }catch (error) {
            toast({
              title: t('Alerta'),
              description: t("Não conseguimos ter comunicação! Verifique sua internet"),
              status: 'error',
            })
        }
        
      },

    })
    
    useEffect(()=>{
    
      if(loginValue){
        const value = JSON.parse(loginValue)
          if(value?.mensagem?.numero_entidade){
             navigate('/')
          }
          
        }
        
    },[])
    
  return (
    <Flex flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" style={{minHeight:'100vh'}} direction="column" >
        <Box w="50%" bg="white" h="100%">
          <Center>
              <form onSubmit={formik.handleSubmit}>
              <Box color="gray.500" bg='transparent' p={10}>
                  <Center mb={1}><Image src={LOGO} width={200} /></Center>
                  <Text fontSize={40} fontWeight={700} color="blue.500" textAlign="center" >{t("Redefina sua senha")}</Text>
                  <Text mb={1} p={5} textAlign="center">                    
                      {t("Soubemos que você perdeu sua senha da INTELIZE PAGAMENTOS. Desculpe por isso!")}<br/>
                      {t("Mas não se preocupe! Você pode usar o código a seguir para redefinir sua senha")}. <b>{t("Mas se sua conta estiver bloqueada deve entrar em contacto com o administrador")}</b>
                  </Text>
                  
                  <Flex direction="column" alignItems="center">
                      {formik.values.canal == "E-mail"
                       ? <Input type='email' rounded={0} placeholder="Digite seu e-mail" _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="70%" borderLeftWidth={5} name='email'  required bg="gray.100"
                        onChange={evt => {formik.handleChange(evt);StrengthChecker(evt.target.value)}}
                       value={formik.values.email} mb={5} />
                      :
                      <Input type='tel' rounded={0} placeholder="Contacto associado" _placeholder={{color:"gray.300"}} borderLeftColor="blue.500" p={6} w="70%" borderLeftWidth={5} name='email'  required bg="gray.100"
                        onChange={evt => {formik.handleChange(evt);StrengthChecker(evt.target.value)}}
                       value={formik.values.email} mb={5} />
                       }
                       
                       <Flex justifyContent="space-between" textAlign="left" width="70%">
                         <Text>{t("Deseja receber com")}:</Text>
                         <RadioGroup  name='canal' defaultValue={formik.values.canal} mb={5}>
                          <Stack direction='row'>
                            <Radio onChange={formik.handleChange} name='canal' value='E-mail'>E-mail</Radio>
                            <Radio onChange={formik.handleChange} name='canal' value='SMS'>SMS</Radio>
                            {/* <Radio onChange={formik.handleChange} name='canal' value='Whatsapp'>Whatsapp</Radio> */}
                          </Stack>
                        </RadioGroup>
                      </Flex>
                      
                    <Button bg='blue.400' rounded={0} w="70%" _hover={{backgroundColor:"blue.600"}} color="white" type='submit' mb={5} p={6}>{t("Enviar")}</Button>
                    <Link to="/login" color='blue.500'>{t("Voltar")}</Link>
                    <Text color="red.600" mt={10}>{t("Se você não usar o código em 10 minutos, ele expirará. Terá que obter uma nova senha")}</Text>
                  </Flex>
                  <Flex mt={10} justifyContent="center" color="blue.500">
                    <ul>
                      <li onClick={()=> i18n.changeLanguage('pt')}  style={{cursor:"pointer"}}>{t("Português")}</li>
                      <li onClick={()=> i18n.changeLanguage('fr')}  style={{cursor:"pointer"}}>{t("Françês")}</li>
                      <li onClick={()=> i18n.changeLanguage('en')}  style={{cursor:"pointer"}}>{t("Inglês")}</li>
                      <li onClick={()=> i18n.changeLanguage('es')}  style={{cursor:"pointer"}}>{t("Espanhol")}</li>
                      <li onClick={()=> i18n.changeLanguage('ar')}  style={{cursor:"pointer"}}>{t("Ârabe")}</li>
                      <li onClick={()=> i18n.changeLanguage('it')}  style={{cursor:"pointer"}}>{t("Italiano")}</li>
                    </ul>
                  </Flex>
              </Box> 
              </form>
          </Center>
        </Box>
        <Box mt={5}>
              <Text mt={70}><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
    </Flex>
  )
}

export default Login