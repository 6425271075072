import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react'
import router from './Routes';
import { RouterProvider } from 'react-router-dom';
import AXIOS from './Config/axiosConfig'
import CONFIG from './Config'
import "./Config/i18next"



async function verificar_comunicacao(){
  try {
      const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
      const value = JSON.parse(loginValue)
      let id, token, numero_entidade;
      
      if(value?.mensagem?.numero_entidade){
        id = value?.mensagem?.id_clientes
        token = value?.mensagem?.endereco_mac_unico
        numero_entidade = value?.mensagem?.numero_entidade
      }
      
      const verificar_user = await AXIOS.get(`/clientes/${id}`)
      
      
      if(token){
      
          if(verificar_user.data.mensagem[0]?.endereco_mac_unico != token){
    
            const res =  await AXIOS.post(`/clientes/logout`,{numero_entidade, tipo: "LOGOUTFORCE"})
            
            if(res.data.status == "sucesso"){
              document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0'
              console.log('Token invalido!')
              window.location.href = '/login'
            }
            
          }
          
          
          if(verificar_user.data.mensagem[0]?.bloqueio == "0"){
              const res =  await AXIOS.post(`/clientes/logout`,{numero_entidade, tipo: "LOGOUTFORCEBLOCK"})
              
              if(res.data.status == "sucesso"){
                document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0'
                console.log('User Bloqueado!')
                window.location.href = '/login'
              }
          }
      }
      
  } catch (error) {
     window.location.href = '/sem-comunicacao'
  }        
             
  }
  
//   setInterval(async ()=>{
//     await verificar_comunicacao()
// }, 60000)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
        <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>
  
);


