import {
  Flex,
  Box,
  Text,
  Center,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Td,
  Tbody,
  Tr,
  Th,
  Thead,
  TableCaption,
  TableContainer,
  useToast,
  Button,
  Tooltip,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Input
} from "@chakra-ui/react";
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from "react";
import {
  SideBarMenu,
  NavBar,
  DrawerEndpoints,
  ModalRemoveEndpoint,
  Loader,
  ModalVerRespostaEndpint,
  BotaoTop
} from "../Components/index";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Pagination from "react-paginate";
import CONFIG from "../Config";
import {
  MdArrowBack,
  MdArrowForward,
  MdClose,
  MdCloudDone,
  MdCloudOff,
  MdDoneOutline,
  MdEdit,
  MdError,
  MdErrorOutline,
  MdRoute,
  MdUpgrade,
  MdWebhook
} from "react-icons/md";
import { useTranslation } from "react-i18next";

function Webhooks() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  
  const {t, i18n} = useTranslation()

  const navigate = useNavigate();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState("1");
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')
  const toast = useToast();
  const [loader, setLoader] = useState(false);

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      
      const referencia = await AXIOS.get(
        `/logs-requisicao/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }
      
      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  async function actualizarEndpints() {
    try {
      setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      
      const referencia = await AXIOS.get(
        `/logs-requisicao/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const buscarComPaginacao  = async function(){
    try {
      setLoader(false)
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/logs-requisicao/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
      console.log(referencia.data)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
  }

  const selectdItem = (evt) =>{
    const tipo = evt.target.value.toLocaleLowerCase()
    const modified = referencias.map(item => {
      
      return {
        ...item,
        "num_referencia": item.num_referencia,
        "referencia_do_montante": item.referencia_do_montante,
        "data_limite_pagamento": item.data_limite_pagamento,
        "data_inicio_de_pagamento": item.data_inicio_de_pagamento,
        "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
        "registo_produto": item.registo_produto
      }
      
    
    })
    const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
    setReferenciasFilter(filtered)
    
}
  
            
  let permissao = user?.pagina_webwook == "true";

  if(user?.pagina_webwook) permissao = user?.pagina_webwook == "true"
  else permissao = user.numero_entidade

  return (
    user?.habilitar_area_tecnica == "true"  ?  <>
      <Flex color="white" bg="white" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenu />
        <BotaoTop/>
        <Box flex={1}>
          <NavBar  titulo={t("Webhooks - Logs")} imagem={<MdWebhook size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"} />
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
            mt={5}
          >
            {permissao ? <Box px={10} color="black" mt={10}>
              {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold">
                Webhooks
              </Text> */}

              <DrawerEndpoints axiosReferencias={axiosReferencias} />
              <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarEndpints}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
              </Button>
              <Button ml={1} as={Link} to="/webhooks" leftIcon={<MdRoute/>} colorScheme="green">Rotas</Button>
              <Button ml={1} as={Link} to="/webhooks/retry" leftIcon={<MdError/>} colorScheme="red">Endpoints com falhas</Button>

              <Flex mt={5} width="50%" color="gray.600" alignItems="center"> 
                <Input w="xs" bg="white" type="search" placeholder={`${t('Pesquise')} ...`} onChange={selectdItem}/>
                <NumberInput
                  name='pagina'
                  onChange={(valueString) => setPagina(parseInt(valueString))}
                  value={pagina}
                  max={paginas}
                  min={1}
                  width={120}
                  ml={2}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                </Select>
                <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
              </Flex>
              {loader ? (
                
                  <TableContainer mt={3} bg="white" boxShadow="md">
                    <Table variant="striped" colorScheme="gray">
                      <TableCaption>
                        <Pagination
                          breakLabel="..."
                          nextLabel={<MdArrowForward />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          breakClassName="list-group-itemm"
                          previousLabel={<MdArrowBack />}
                          renderOnZeroPageCount={null}
                        />
                      </TableCaption>
                      <Thead p={10}>
                        <Tr bg="gray.600">
                          <Th py={3} color="gray.300">
                            #
                          </Th>
                          <Th py={3} color="gray.300">
                            URL
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Data do evento")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Evento")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Entidade")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Estado")}
                          </Th>
                          <Th py={3} color="gray.300">
                            {t("Resposta")}
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentItems.map((el) => (
                          <Tr key={el.id_logreq} color="blackAlpha.700">
                            <Td>{el.id_logreq}</Td>
                            <Td><Tooltip hasArrow label={el.url} bg='blue.400'><span>{el.url.substr(0, 25)} ...</span></Tooltip></Td>
                            <Td>
                              {new Date(
                                el.data_evento
                              ).toLocaleDateString()}{" "}
                              {new Date(
                                el.data_evento
                              ).toLocaleTimeString()}
                            </Td>
                            <Td>
                              {el.desc_evento?.split(".")[1] || "Todos"}
                            </Td>
                            <Td>
                              {el.desc_evento?.split(".")[0] || "Todos"}
                            </Td>
                            <Td>{(el.statusCode?.split(":")[1] == " 200") || (el.statusCode?.split(":")[1] == " 201") ? <MdCloudDone color="green" size={25} /> : <MdCloudOff color="red" size={25} />}</Td>
                            <Td>
                              <ModalVerRespostaEndpint actualizar={axiosReferencias} texto={el}/>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                      
                    
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
    :
    navigate("/404")
  );
}

export default Webhooks;
