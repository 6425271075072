import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast } from '@chakra-ui/react'
import {  MdAdd, MdOutlinePassword, MdPeople} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

export default function PlacementExample({axiosReferencias}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [user, setUser] = useState('1')
    const [tipoSenha, setTipoSenha] = useState('password')
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()


    const verSenha = () => {

      if(tipoSenha == "password") {
        setTipoSenha('text')
      }else{
        setTipoSenha('password')
      }
    }
    

    const formik = useFormik({
      initialValues:{
        nome_identificacao:''
      },
      onSubmit: async (values)=> {

        values.entidade_das_afiliadas = user.numero_entidade
        
        const res =   await AXIOS.post(`/afiliados/afiliados-indetificacao`,values)
        
        toast({
          title: 'Grupo de afiliados',
          description: "Á carregar o registro",
          status: 'loading',
          duration: 4000,
          isClosable: true,
        })

        if(res.data.status === "sucesso"){          
          
          
          setTimeout(()=>{
            formik.resetForm()
            setIsOpen(false)
          },1000)        
          
          setTimeout(()=>{
            toast({
              title: 'Grupo de afiliados',
              description: res.data.mensagem,
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
            axiosReferencias()
          },5000)
        }else{
          setTimeout(()=>{
            toast({
              title: 'Grupo de afiliados',
              description: res.data.mensagem,
              status: 'error',
              duration: 5000,
              isClosable: true,
            })
          },5000)
        }
        
        
      },
    })
    
    const onClose = function(){
        setIsOpen(false)
    }

    useEffect(()=>{
      const value = JSON.parse(loginValue)

      if(value?.mensagem?.numero_entidade){
        setUser(value.mensagem)
      }
      
  },[])
    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          Adicionar
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>Identificação de afiliados</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl>
                        <FormLabel>Grupo de identificação</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='nome_identificacao' onChange={formik.handleChange} value={formik.values.nome_identificacao} />
                         </Flex>
                    </FormControl>
                    <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Gravar</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }