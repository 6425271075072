import { Flex, Box, Text, Heading, Center} from '@chakra-ui/react'  
import { SideBarMenu, NavBar, BotaoTop } from '../Components/index'
import { Link } from 'react-router-dom'
import React,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import PDF from '../Assets/pdf/documentacaoAPI.pdf'
import { SiDocsdotrs } from 'react-icons/si'
import { MdError } from 'react-icons/md'


function ServicoAPI() {
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"  
  const navigate =  useNavigate()
  const [user, setUser] = useState('1')
  const {t, i18n} = useTranslation()

  
  useEffect(()=>{
      
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
          navigate('/login')
        }
  },[])

  let permissao = user?.pagina_servico_api == "true";

  if(user?.pagina_servico_api) permissao = user?.pagina_servico_api == "true"
  else permissao = user.numero_entidade

return (
  user?.habilitar_area_docs == "true"  ?  <>
  <Flex color='white' bg="white" direction="row">
    <SideBarMenu />
    <BotaoTop/>
    <Box flex={1}>
      <NavBar  titulo={t("WebService API")} imagem={<SiDocsdotrs size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
      <Flex direction="column" mt={5} justifyContent="space-between" style={{minHeight:'90vh'}}>
        {permissao ? <Box px={10} color="black" mt={10}>
            {/* <Heading fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>WebService API</Heading> */}
            <Text bg="white" rounded={5} p={10} mb={10} textAlign="justify">                  
                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi 
                pariatur error, quae ut labore! Ex, qui vel rerum facilis doloremque a quam fuga ipsam porro id magnam quod quis eum praesentium suscipit pariatur itaque quos laboriosam 
                voluptate laborum odit? Obcaecati adipisci dolorem nam autem maiores 
                atque minima dignissimos, nobis, reprehenderit, modi alias? Odio sunt, deserunt consectetur quibusdam natus recusandae obcaecati officiis repellat omnis similique harum repellendus id autem cupiditate minima ab! Eius nisi sapiente incidunt 
                perspiciatis adipisci eaque, maiores quo esse atque iste error eos enim sit expedita vero.
                <br/>
                <br/>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus porro dolores similique mollitia rerum non accusamus numquam repellendus quisquam tempora fugit a, 
                ducimus earum quo suscipit nulla, eveniet exercitationem iusto animi.
                <br/>
                <br/>
                voluptate laborum odit? Obcaecati adipisci dolorem nam autem maiores 
                atque minima dignissimos, nobis, reprehenderit, modi alias? Odio sunt, deserunt consectetur quibusdam natus recusandae obcaecati officiis repellat omnis similique harum repellendus id autem cupiditate minima ab! Eius nisi sapiente incidunt 
                perspiciatis adipisci eaque, maiores quo esse atque iste error eos enim sit expedita vero. */}
                <object width="100%" height="1000px"  type="application/pdf" data={PDF} />
            <br/>
            <br/>
            <br/>
            <Link style={{fontWeight:700, fontSize:18}} color="blue.500" to="https://documenter.getpostman.com/view/8763766/2s946mZ9VV#c261e6a0-cc00-4008-a6fe-97336c037f76" target='_blank'>{t("Ir na documentação oficia")}l</Link>
            </Text>
        </Box>    
        :<Center width="100%">
        <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
            <MdError size={200} color="red"/>
            <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
            <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
        </Flex>
      </Center>}
        <Box mt={5} color="gray.700" textAlign="center">
            <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
            <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
        </Box>
      </Flex> 
    </Box>
  </Flex>
  </>
  :
  navigate("/404")
)
}

export default ServicoAPI