import React, {useState} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton} from '@chakra-ui/react'
import {MdEdit, MdPassword} from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function ModalMudarSenha ({alterarPercentual, percentualExistente}) {
    const [open, setOpen] = useState(false)
    const [valorFixo, setValorFico] = useState('')
    const [percentual, setPercentual] = useState('')
    const [minimo, setMinimo] = useState('')
    const [maximo, setMaximo] = useState('')
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    
    return (
      <>
        <Button leftIcon={<MdEdit/>} mb={5} onClick={onOpen} colorScheme='whatsapp' mt={10}>Alterar</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Alterar Percentual por uso comum</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Valor Fixo</FormLabel>
              <Input type='number' name='valor_fixo' defaultValue={percentualExistente.valor_fixo} required minLength={6} onChange={(evt) => setValorFico(evt.target.value)} />
            </FormControl>

            <FormControl>
              <FormLabel>Percentual</FormLabel>
              <Input type='number' name='percentual' defaultValue={percentualExistente.percentual} required minLength={6} onChange={(evt) => setPercentual(evt.target.value)} />
            </FormControl>

            <FormControl>
              <FormLabel>Valor Minimo</FormLabel>
              <Input type='number' name='minimo' defaultValue={percentualExistente.minimo} required minLength={6} onChange={(evt) => setMinimo(evt.target.value)} />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Valor Maximo</FormLabel>
              <Input type='number' name='maximo' defaultValue={percentualExistente.maximo} required minLength={6} onChange={(evt) => setMaximo(evt.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button onClick={()=>{ alterarPercentual(valorFixo || percentualExistente.valor_fixo , percentual || percentualExistente.percentual, minimo || percentualExistente.minimo, maximo || percentualExistente.maximo, percentualExistente.forma_de_uso, percentualExistente.id_por_uso); onOpen()}} colorScheme='blue' mr={3}>
              Ok
            </Button>
            <Button onClick={onOpen}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
