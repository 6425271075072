import { useEffect, useRef, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Checkbox,
  Center,
  Spinner,
  useToast,
  Input,
  Heading,NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select
} from "@chakra-ui/react";
import {
  MdCached,
  MdClose,
  MdArrowBack,
  MdArrowForward,
  MdEdit,
  MdUpgrade,
  MdError
} from "react-icons/md";
import { FcProcess } from "react-icons/fc";
import { HiStatusOnline, HiStatusOffline } from "react-icons/hi";
import {
  BotaoTop,
  Drawer,
  DrawerFiltros,
  DrawerFiltrosReferencias,
  Loader,
  ModalGerarFileRef,
  ModalRemoveRef,
  ModalReportReferencias,
  ModalReportReferenciasGerarXML,
  ModalViewReference,
  NavBar,
  SideBarMenu
} from "../Components/index";

import AXIOS from '../Config/axiosConfig'
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CONFIG from "../Config";
import { useTranslation } from "react-i18next";
import { BsBank } from "react-icons/bs";
import { RiPassExpiredFill } from "react-icons/ri";

function Referencias() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  const [pesquinarBD, setPesquinarBD] = useState({status:false, text:''});
  const navigate = useNavigate();
  const {t, i18n} = useTranslation()
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState({});
  const [checkBox, setCheckBox] = useState([]);
  const [check, setcheck] = useState(false);
  const refREF = useRef(null);
  const [loader, setLoader] = useState(false);
  const toast = useToast();
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;

      const referencia = await AXIOS.get(
        `/referencias/entidade/${entidade}?limite=${quantidade}&pagina=${pagina}`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  async function preencherNaTabela(referencia) {
    try {

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  const actualizarReferencia = async () => {
    try {
      setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;

      const referencia = await AXIOS.get(
        `/referencias/entidade/${entidade}?limite=${quantidade}&pagina=${pagina}`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  };

  const excluirRef = async (ref) => {
    try {
    
      if((ref.estado_atm == "Á Processar") || (ref.estado_atm == "Activo")){
          
          toast({
            title: t("Exclusão de referência"),
            description: t("Não podemos excluir a sua referencia com este estado em processo ou activo"),
            status: "error",
            duration: 5000,
            isClosable: true
          });
          
          return
      }
      
      const refs = referencias.filter(
        (r) => r.id_referencia != ref.id_referencia
      );
      
      const res = await AXIOS.delete(
        `/referencias/${ref.id_referencia}`
      );
      if (res.data.status == "sucesso") {
        setReferencias(refs);
        toast({
          title: t("Exclusão de referência"),
          description: t("Conseguimos excluir a referência com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: t("Exclusão de referência"),
          description: res.data.mensagem,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }

      axiosReferencias();
    } catch (error) {
      navigate("/sem-comunicacao");
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  const encaminhar = async (ref, setOpen) => {
    try {
      const request = {
        dados: [ref],
        entidade: user.numero_entidade
      };
      const res = await AXIOS.post(
        `/ficheiros/gerar-fsec-fref`,
        request
      );
      if (res.data.status == "sucesso") {
        toast({
          title: t("Ficheiro de referência"),
          description:
            t("Conseguimos criar o seu ficheiro de referência com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
        setOpen(false);
      } else {
        toast({
          title: t("Ficheiro de referência"),
          description: t("Não conseguimos criar o seu ficheiro de referência"),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  const encaminharVarios = async (ref) => {
    try {
      const request = {
        dados: checkBox,
        entidade: user.numero_entidade
      };
      const res = await AXIOS.post(
        `/ficheiros/gerar-fsec-fref`,
        request
      );
      if (res.data.status == "sucesso") {
        toast({
          title: t("Ficheiro de referência"),
          description:
            t("Conseguimos criar os seus ficheiros de referência com sucesso"),
          status: "success",
          duration: 5000,
          isClosable: true
        });
        setCheckBox([]);
      } else {
        toast({
          title: t("Ficheiro de referência"),
          description: t("Não conseguimos criar os seus ficheiros de referência"),
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    } catch (error) {
      toast({
        title: t("Alerta"),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: "error"
      });
    }
  };

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 50;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const selectdItem = async (evt) => {
    const tipo = evt.target.value.toLocaleLowerCase();
    const modified = referencias.map((item) => {
      return {
        ...item,
        num_referencia: item.num_referencia,
        referencia_do_montante: item.referencia_do_montante,
        data_limite_pagamento: item.data_limite_pagamento,
        data_inicio_de_pagamento: item.data_inicio_de_pagamento,
        codigo_de_processamento:
          item.codigo_de_processamento == 80 ? "Activo" : "Inactivo",
        registo_produto: item.registo_produto
      };
    });

    const filtered = modified.filter(
      (item) =>
        JSON.stringify(item)
          .toLocaleLowerCase()
          .replaceAll("T", " ")
          .replaceAll("Z", "")
          .replaceAll("-", "/")
          .indexOf(tipo) > -1
    );

    if(filtered.length  == 0){

      setPesquinarBD({status:true, text:tipo});
    }

    setReferenciasFilter(filtered);
  };

  const buscarComPaginacao  = async function(){
    try {
      setLoader(false)
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/referencias/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
      console.log(referencia.data)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
  }
  

  const buscarNaBD = async (tipo) => {
    setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;

      const referencia = await AXIOS.get(
        `/referencias/referencia/${tipo}/${entidade}/`
      );
      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
      }

      setLoader(true);
  }

  let permissao = user?.pagina_referencia == "true";

  if(user?.pagina_referencia) permissao = user?.pagina_referencia == "true"
  else permissao = user.numero_entidade

  return (
    <>
      <Flex color="white" bg="white" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenu/>
        <BotaoTop/>
        <Box flex={1}>
          <NavBar titulo={t("Minhas referências")} imagem={<BsBank size={250}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
          >
            {permissao ? <Box px={10}>
              {/* <Text
                fontSize={50}
                color="blackAlpha.600"
                fontWeight="bold"
                mb={8}
              >
                {t("Minhas referências")}
              </Text> */}
              <Flex mt={5} alignItems="center" justifyContent="space-between">
                <Box>
                  <Drawer axiosReferenciass={axiosReferencias} />{" "}
                  {/* {checkBox.length > 1 && (
                    <Button
                      ml={2}
                      mr={2}
                      leftIcon={<MdCached />}
                      onClick={encaminharVarios}
                      colorScheme="orange"
                      variant="solid"
                    >
                      {t("Gerar ficheiros")}
                    </Button>
                  )} */}
                  <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarReferencia}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
                  </Button>
                  {/* <ModalReportReferencias entidade={user} /> */}
                  <ModalReportReferenciasGerarXML entidade={user.numero_entidade} />
                  <DrawerFiltrosReferencias preencherNaTabela={preencherNaTabela} entidade={user.numero_entidade}/>
                </Box>
                <Flex color="gray.500" alignItems="center">
                  <Text></Text>{" "}
                  {pesquinarBD.status && <Button onClick={() => buscarNaBD(pesquinarBD.text)}>Reforçar</Button>}
                  <Input
                    w="xs"
                    type="search"
                    bg="white"
                    placeholder={`${t('Pesquise')} ...`}
                    ml={1}
                    onChange={selectdItem}
                  />
                  <NumberInput
                  name='pagina'
                  onChange={(valueString) => setPagina(parseInt(valueString))}
                  value={pagina}
                  max={paginas}
                  min={1}
                  width={120}
                  ml={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                </Select>
                <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
                </Flex>
              </Flex>

              {/* <Text mt={2} color="black" fontWeight="bold">
                {t("Itens selecionados")}: {checkBox.length}
              </Text> */}
              {loader ? (
                <TableContainer mt={5} bg="white" boxShadow="md">
                  <Table variant="striped" colorScheme="gray">
                    <TableCaption>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<MdArrowForward />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        breakClassName="list-group-item"
                        previousLabel={<MdArrowBack />}
                        renderOnZeroPageCount={null}
                      />
                    </TableCaption>
                    <Thead p={10}>
                      <Tr bg="gray.600">
                        {/*<Th py={6} color="gray.300">
                          <Checkbox
                            onChange={() => (evt) =>
                              evt.target.checked
                                ? setcheck(true)
                                : setcheck(false)}
                          />
                        </Th>*/}
                        <Th py={6} color="gray.300">
                          {t("Referência")}
                        </Th>
                        <Th colSpan={1} py={6} color="gray.300">
                          {t("Estado no ATM")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("Criação")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("Tipo de registro")}
                        </Th>
                        <Th py={6} color="gray.300">
                          {t("limite de pagamento")}
                        </Th>
                        {/* <Th py={6} color="gray.300">
                          {t("Gerar ficheiro")}
                        </Th> */}
                        <Th py={6} color="gray.300">
                          {t("Acção")}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentItems.map((el) => (
                        <Tr key={el.id_referencia} color="blackAlpha.700">
                          {/*<Td>
                            <Checkbox
                              defaultChecked={check}
                              ref={refREF}
                              name="itensRef"
                              onChange={(evt) =>
                                evt.target.checked
                                  ? setCheckBox([...checkBox, el])
                                  : setCheckBox(
                                      checkBox.filter(
                                        (it) =>
                                          it.id_referencia != el.id_referencia
                                      )
                                    )
                              }
                            />
                            </Td>*/}
                          <Td onClick={() => window.location.href = `/pagamentos/${el.num_referencia}`} fontWeight={700} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>
                            {el.num_referencia
                              ? el.num_referencia
                              : el.referencia_do_montante}
                          </Td>
                          {/* <Td>{el.estado_atm}</Td> */}
                          <Td>
                            {el.estado_atm === "Expirada" ? (
                              <RiPassExpiredFill color="orange" size={30} />
                            ) : el.estado_atm === "Activo" ? (
                              <HiStatusOnline color="green" size={30} />
                            ) : (
                              <HiStatusOffline color="red" size={30} />
                            )}
                          </Td>
                          <Td>
                            {new Date(el.criada_r).toLocaleDateString()}{" "}
                            {new Date(el.criada_r).toLocaleTimeString()}
                          </Td>
                          <Td fontWeight={700}>{el.registo_produto == "Pagamento/Carregamento" ? el.tipo_referencia_pagamento == "P" ? "Pagamentos" : "Carregamentos" : el.registo_produto}</Td>
                          <Td>
                            {el.data_limite_pagamento ? (
                              new Date(
                                el.data_limite_pagamento
                              ).toLocaleDateString()
                            ) : (
                              <MdClose size={20} color="red" />
                            )}
                          </Td>
                          {/* <Td>
                            <ModalGerarFileRef
                              registo={el}
                              encaminhar={encaminhar}
                            />
                          </Td> */}
                          <Td>
                            <ModalViewReference registo={el} />
                            <Button
                              size="sm"
                              ml={2}
                              leftIcon={<MdEdit />}
                              as={Link}
                              to={`/referencias/${
                                el.num_referencia
                                  ? el.num_referencia
                                  : el.referencia_do_montante
                              }`}
                              bg="green.500"
                              color="white"
                              variant="solid"
                            ></Button>
                            <ModalRemoveRef
                              registo={el}
                              excluirRef={excluirRef}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Center mt={10}>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
        <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
            <MdError size={200} color="red"/>
            <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
            <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
        </Flex>
      </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default Referencias;
