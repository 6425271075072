import React, {useEffect, useState} from 'react'
import { Button, Flex,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader,HStack, PinInput, PinInputField, CardBody, Box, Heading, Text, Stack, StackDivider, useToast, FormControl, FormLabel, Select, Input, Progress, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react'
import { MdClose, MdMoney, MdSearch } from 'react-icons/md'
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const [loader, setLoader] = useState(false)
    const [resposta, setResposta] = useState({status:"error", mensagem:""})
    const [produtos, setProdutos] = useState([])
    const {t, i18n} = useTranslation()
    const [userCod, setUserCod] = useState({texto1:'',texto2:'',texto3:'',texto4:'',texto5:'',texto6:'',texto7:'',texto8:'',texto9:'',texto10:''})

    const TIPO_TERMINAL = {
      "A": "ATM",
      "M": "Multicaixa Express",
      "L": "Internet Banking"
    }

    const onOpen = () => {
            setOpen(!open)
    }

    const toast = useToast()

    async function axiosReferencias(){
      const entidade = registo.numero_entidade
      
      const referencia = await AXIOS.get(`/produtos-clientes/entidade/${entidade}`)
      if(referencia.data.status === "erro")
          setProdutos([])
      else{
        if(referencia.data.mensagem[0]?.registo)
            setProdutos(referencia.data.mensagem[0]?.registo)
           
      }
      
          
  }

    const CAIXA = String(Math.random()).replace('.','')
    const CONTA = String(Math.random()).replace('.','')
    const LOG_EGR = String(Math.random())
    const LOG_EGR_NUM =String(Math.random()).replace('.','')
    const ID_TERMINAL = String(Math.random())
    const LOCAL_CONTABILISTICO = String(Math.random()).replace('.','')
    const LOCAL_TRANSACAO = String(Math.random()).replace('.','')
    const TRANSACAO = String(Math.random()).replace('.','')
    const DATA =  new Date()

    const formik = useFormik({
      initialValues:{ 
        versao_mensagem: '03',
        Aplicacao_PDD:'1',
        Identificacao_Log_EGR: LOG_EGR.substr(-4),
        Numero_Log_EGR: LOG_EGR_NUM.substr(0,8),
        Referencia_do_Servico: '',
        Codigo_do_Produto: '',
        Codigo_do_Parametro: '00',
        Montante_Pago: '',
        Data: DATA.toISOString().split("T")[0].replaceAll('-',''),
        Hora_do_movimento: DATA.toISOString().split("T")[1].split('.')[0].replaceAll(':',''),
        Tipo_de_Terminal: 'A',
        Identificacao_do_Terminal: '00000'+ID_TERMINAL.substr(-5),
        Numero_Periodo_Contabilistico_Local: LOCAL_CONTABILISTICO.substr(0,3),
        Identificacao_Transacao_Local: LOCAL_TRANSACAO.substr(0,5),
       
      },
      onSubmit: async (values)=> {
        values.Montante_Pago = `${userCod.texto1}${userCod.texto2}${userCod.texto3}${userCod.texto4}${userCod.texto5}${userCod.texto6}${userCod.texto7}${userCod.texto8}${userCod.texto9}${userCod.texto10}`
        console.log(values)
        const value = {
          "dados": `E038${values.versao_mensagem}${values.Aplicacao_PDD}${values.Identificacao_Log_EGR}${values.Numero_Log_EGR}${registo.numero_entidade}${"0".repeat(15 - Number(values.Referencia_do_Servico.length)).concat(String(values.Referencia_do_Servico))}${values.Codigo_do_Produto}${values.Codigo_do_Parametro}${"0".repeat(13 - Number(values.Montante_Pago.length)).concat(String(values.Montante_Pago))}${values.Data}${values.Hora_do_movimento}${values.Tipo_de_Terminal}${values.Identificacao_do_Terminal}${values.Numero_Periodo_Contabilistico_Local}${values.Identificacao_Transacao_Local}Luanda         000000000000000000024`
        }
      
        const res =  await AXIOS.post(`/logs/verificar-pagamento`,value) 

        setLoader(true)
        
        if(res.data.status === "sucesso"){
         
          setTimeout(()=>{ 
            setResposta({status:"success", mensagem:res.data.subText})
            setLoader(false)
          },3000)
        }else{
          setTimeout(()=>{
            setResposta({status:"error", mensagem:res.data.subText})
            setLoader(false)
          },3000)
        }

        setTimeout(()=>{ 
          setResposta({status:"", mensagem:""})
          setLoader(false)
        },10000)
        
      },
    })

    const validarDigitos = (evt) =>{
      const keys = ["8","46","48","49","50","51","52","53","54","55","56","57"]
      const find = keys.find(key => key == evt.keyCode)
      if(!find) evt.target.value = evt.target.value.replace(evt.target.value.substr(-1),'')
      
      
  }
    
    useEffect(()=>{
      
      axiosReferencias();
  },[])

    return (
      <>
        <Button onClick={onOpen} size="sm" bg='blue.400' color="white" variant='solid'>Testar pagamento</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="6xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
          backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>Testar o procedimento de pagamento</ModalHeader>
            <ModalCloseButton />

              <Flex>
                <ModalBody>
                  <Card shadow={0}>
                    <CardHeader>
                      <Heading size='md'>Entidade: {registo.numero_entidade}</Heading>
                    </CardHeader>                
                    <CardBody>
                      <Stack divider={<StackDivider />} spacing='5'>
                        <Box>
                          <Heading size='xs' color="blue.500" textTransform='uppercase'>
                              {registo.nome_empresa}
                          </Heading>
                        </Box>
                        <form onSubmit={formik.handleSubmit}>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box mb={5}>
                            <Heading size='xs' textTransform='uppercase'>
                              Versão da mensagem
                            </Heading>
                            <FormControl mt={2}>
                              <Select required name='versao_mensagem' onChange={formik.handleChange} value={formik.values.versao_mensagem}>
                                <option value="03">Versão 3</option>
                                <option value="04">Versão 4</option>
                                <option value="05">Versão 5</option>
                              </Select>
                            </FormControl>
                          </Box>
                        <Box mb={5}>
                          <Heading size='xs' textTransform='uppercase'>
                            Produto
                          </Heading>
                          <FormControl mt={2}>
                            <Select placeholder='Selecione o produto' required name='Codigo_do_Produto' value={formik.values.Codigo_do_Produto} onChange={formik.handleChange}>
                            {produtos.map(el => <option value={`${"0".concat(el.id_tipo_produto)}`}>{el.produto}</option>)}
                            </Select>
                          </FormControl>
                        </Box>
                        </Flex>
                        <Flex direction="column" justifyContent="space-between">
                          <Box mb={5}>
                            <Heading size='xs' textTransform='uppercase'>
                              Referência
                            </Heading>
                            <Input type='text' name='Referencia_do_Servico' onKeyUp={validarDigitos} minLength={registo.preenchimento_refs_zeros_a_esquerda == "NÃO" ? registo.num_padrao_referencias : 1} maxLength={registo.preenchimento_refs_zeros_a_esquerda == "NÃO" ? registo.num_padrao_referencias : 15} required
                              onChange={formik.handleChange}
                            value={formik.values.Referencia_do_Servico} />
                          </Box>
                        <Box mb={5}>
                          <Heading size='xs' textTransform='uppercase'>
                            Montante
                          </Heading>
                          {/* <Input type='text' name='Montante_Pago'  required
                              onChange={formik.handleChange}
                            value={formik.values.Montante_Pago} /> */}
                          <HStack>
                            <PinInput size="sm" errorBorderColor = "red.500" name='Montante_Pago'>
                              <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto1:evt.target.value}) ) }/>
                              <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto2:evt.target.value}) ) }/>
                              <br/>
                              <span style={{color:"#000", fontSize:35}}>.</span>
                              <br/>
                              <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto3:evt.target.value}) ) }/>
                              <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto4:evt.target.value}) ) }/>
                              <PinInputField onChange={(evt) => setUserCod(prev => ({...prev, texto5:evt.target.value}) ) }/>
                              <br/>
                              <span style={{color:"#000", fontSize:35}}>.</span>
                              <br/>
                              <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto6:evt.target.value}) ) }/>
                              <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto7:evt.target.value}) ) }/>
                              <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto8:evt.target.value}) ) }/>
                              <br/>
                              <span style={{color:"#000", fontSize:35}}>,</span>
                              <br/>
                              <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto9:evt.target.value}) ) }/>
                              <PinInputField required onChange={(evt) => setUserCod(prev => ({...prev, texto10:evt.target.value}) ) }/>
                            </PinInput>
                          </HStack>
                        </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box mb={5}>
                            <Heading size='xs' textTransform='uppercase'>
                              Terminal
                            </Heading>
                            <FormControl mt={2}>
                              <Select required name='Tipo_de_Terminal' onChange={formik.handleChange} value={formik.values.Tipo_de_Terminal}>
                                <option value="A">ATM</option>
                                <option value="M">Express</option>
                                <option value="L">Internet Banking</option>
                              </Select>
                            </FormControl>
                            <Button leftIcon={<MdMoney/>} bg="blue.700" _hover={{backgroundColor:"blue.800"}} color="white"  type='submit' mt={5}>Confirmar</Button>
                          </Box>
                        </Flex>
                        {resposta.mensagem && <Alert status={resposta.status} mt={2}>
                              <AlertIcon />
                              <AlertTitle>{resposta.mensagem}</AlertTitle>
                            </Alert>}
                        {loader && <Progress mt={5} size='md' w="100%" isIndeterminate />}
                        </form>
                      </Stack>
                    </CardBody>
                  </Card>
                </ModalBody>  

                <ModalBody>
                <Card>
                <CardBody>
                    <Heading textAlign="center" size="md" mb={2}>* * REDE * *</Heading>
                    <Flex justify="space-between">
                      <Text>N.CAIXA: {CAIXA.substring(0,4)}/{CAIXA.substring(4,8)}/{CAIXA.substring(8,10)}</Text> <Text>TRANSACÇÃO: 0{TRANSACAO.substring(0,4)}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text>CONTA: {CONTA}</Text> <Text>{DATA.toLocaleDateString()} {DATA.toLocaleTimeString().substr(0,5)}</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text>MCX DEBIT</Text><Text alignSelf="flex-end">************{CAIXA.substring(3,7)} 00</Text>
                    </Flex>
                    <Flex justify="space-between">
                      <Text alignSelf="flex-end">ID: AO000009600200</Text>
                    </Flex>
                    
                    -------------------------------------------------------
                    <Heading textAlign="center" mb={7} size="sm">{registo.nome_empresa}</Heading>
                    
                    <Text>ENTIDADE: {registo.numero_entidade}</Text>
                    {/* <Text>PRODUTO: xx</Text> */}
                    <Text>REFERÊNCIA: {formik.values.Referencia_do_Servico}</Text>
                    <Text>VALOR: {Intl.NumberFormat("PT-br").format(`${String(formik.values.Montante_Pago).substr(0,String(formik.values.Montante_Pago).length - 2)}.${String(formik.values.Montante_Pago).substr(-2)}`)} Kz</Text>
                    
                    <Text textAlign="center" mt={5}>PAGAMENTO</Text>
                    <Text textAlign="center">{`Pagamento feito com sucesso`}</Text>
                    -------------------------------------------------------
                    <Text>Fique em casa. Use o BAIDIRECTO</Text>
                    <Text>BAI. Confiança no futuro</Text>
                    -------------------------------------------------------
                    <Text>PAGUE SEUS IMPOSTOS, O PAIS AGRADECE</Text>
                    -------------------------------------------------------
                    <Heading textAlign="center" size="md">* * OBRIGADO * *</Heading>
                  </CardBody>
                </Card>
                </ModalBody>
              </Flex>
              <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
