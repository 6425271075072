import React, { useEffect, useState } from 'react'
import './css/loader.css'
import { Button, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Divider,
  Box,
  Flex,} from '@chakra-ui/react'
import { MdArrowCircleUp } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../Config'


function Loader() {
const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.COOKIE)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.COOKIE)).split(CONFIG.COOKIE_SESSION.COOKIE)[1] : ""
const [cookie, setCookie] = useState({}) 
const navigate =  useNavigate()

  const irParaCima = () => {
    window.scrollTo({
      top:0,
      behavior: 'smooth'
    })
  }

  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )

  const OverlayTwo = () => (
    <ModalOverlay
      bg='none'
      backdropFilter='auto'
      backdropInvert='90%'
      backdropBlur='2px'
    />
  )

  const aceitarOuRejeitarCookies = function(codicao, area){

      if(codicao){

        if(area == "adm"){
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 1000*60*60*24*7;
          now.setTime(expireTime);
          document.cookie = CONFIG.COOKIE_SESSION.COOKIE+"aceitou"+';expires='+now.toGMTString()+';path=/adm';
          onClose()
        }

        if(area == "cliente"){
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + 1000*60*60*24*7;
          now.setTime(expireTime);
          document.cookie = CONFIG.COOKIE_SESSION.COOKIE+"aceitou"+';expires='+now.toGMTString()+';path=/';
          onClose()
        }

      }else{

        if(area == "adm"){
          document.cookie = CONFIG.COOKIE_SESSION.COOKIE + '=; Max-Age=0 ;path=/adm'
          document.cookie = CONFIG.COOKIE_SESSION.ADM + '=; Max-Age=0 ;path=/adm'
          navigate('/adm/login')
        }

        if(area == "cliente"){
            document.cookie = CONFIG.COOKIE_SESSION.COOKIE + '=; Max-Age=0 ;path=/'
            document.cookie = CONFIG.COOKIE_SESSION.CLIENT + '=; Max-Age=0 ;path=/'
            navigate('/login')
        }

      }

  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)

  // window.addEventListener("offline", (e) => {
  //   alert("offline");
  // });
  
  // window.addEventListener("online", (e) => {
  //   alert("online");
  // });

  useEffect(() => {
    if(loginValue !== "aceitou"){
       setOverlay(<OverlayTwo />)
       onOpen()
    }
    if(loginValue){
      const value = loginValue
      setCookie(value)
     }

  },[])
  

  return (
    <div className="">
       <Button
        rounded="50%" 
        bg="transparent"
        size="lg" 
        fontSize="sm"
        fontFamily="AvenirBold"
        position="fixed"
        _hover={{backgroundColor:"transparent"}}
        bottom={10}
        right={0}
        zIndex={1}
        >
          <MdArrowCircleUp onClick={irParaCima} color='#3294ed' size={50}/>
      </Button>
      <Modal size="4xl" isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent>
          <ModalHeader>Nós usamos cookies</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
                <Box>
                  <Text fontSize="small">Utilizamos cookies neste site para garantir a melhor experiência possível.<br />
                  Os cookies são pequenos arquivos de texto que armazenam informações no seu dispositivo para nos ajudar a entender como você usa nosso site.<br />
                  Isso permite-nos personalizar o conteúdo e os anúncios, além de melhorar os nossos serviços.</Text>
                </Box>
                <Divider orientation='vertical' />
                <Box>
                  <Flex>
                    <Button colorScheme='blue' onClick={()=>aceitarOuRejeitarCookies(true,"cliente")}>Aceitar todos</Button>
                    <Button ml={2} colorScheme='blue' onClick={()=>aceitarOuRejeitarCookies(false,"cliente")}>Rejeitar todos</Button>
                  </Flex>
                    <Button mt={2} width="100%">Gerir individualmente</Button>
                </Box>

            </Flex>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="flex-start">
            <Link to="/termos-e-condicoes">Termos e condições</Link>
            &nbsp;&nbsp;&nbsp;
            <Link to="/politica-de-privacidade">Política de privacidade</Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default Loader
