import { Flex, Box, Text, Heading, Center, Table,  Tr, Tbody, Td,
  Thead,
  Th,
  Button
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { BotaoTop, Loader, NavBar, SideBarMenu } from '../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'


function Referencias() { 

    const {t, i18n} = useTranslation()    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState({}) 

    
  const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem?.numero_entidade
  const fetcher = async (url) =>{
    const {data} = await AXIOS.get(url) 
    
    return data
  }

  const { data, error, isLoading } = useSWR(`/dashboard/entidade/${entidade}`, fetcher)

      
    useEffect(()=>{
       
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)

      }else{
            navigate('/login')
          }
    },[])   
     
    if (isLoading){ 
      return <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
              <Center mt={10}> 
                <Loader /> 
              </Center>
            </Flex>
    }
    
  return (
  <>    
     <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
      <SideBarMenu/>
      <BotaoTop/>
      <Box flex={1} justifyContent="space-around" width="100%">
        <NavBar/>
        <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
          
            <Box px={5} w="100%">
              

              <Box mt={5} borderLeft="5px solid" borderColor="blue.500" rounded={5} flex={1} shadow="md"  className="donut" bg="white" py={5} px={5} direction="column">
                  <Heading color="blue.500" fontWeight={500}>{t("Pagamentos mensais")}</Heading>    
                  <Chart options={{
                          chart: {
                            id: "basic-bar",
                            stacked: true,
                          },
                          xaxis: {
                            categories: ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"]
                          },
                          stroke:{
                            curve: "smooth"
                          }
                        }} series={[
                            {
                              name:"Quantidade",
                              data: data.mensagem.somatorioMensais.quantidade
                            },
                            {
                              name:"Montante",
                              data: data.mensagem.somatorioMensais.soma
                            }
                        ]
                        } type="area"  width="100%" height={400}/>
                  </Box>
                  
              
              <Flex color="gray.500" py={3} mt={5} justifyContent="space-between" flexDir="column">
                    
                <Flex flex={0} className="donut" shadow="md" rounded={5}  bg="white" p={5} direction="column" borderLeft="5px solid" borderColor="blue.500">
                  <Heading color="blue.500" fontWeight={500} mb={5}>{t("Ultimos periodos")}</Heading>
                  <Table variant='striped' colorScheme='gray'>
                  <Thead>
                      <Tr>
                        <Th>Periodo</Th>
                        <Th>Data e hora</Th>
                        <Th>Montante</Th>
                        <Th>Quantidade de pagamentos</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.mensagem.ultimosPeridos.map((el,i) =>
                          <Tr key={i} color="blackAlpha.700">
                            <Td onClick={() => window.location.href = `/periodo-contabilistico/periodo/${el.Identificacao_Log_EGR}/data/${el.data_periodo}`} fontWeight={700} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>{el.Identificacao_Log_EGR}</Td>
                            <Td>{el.data_periodo} até 20:00</Td>
                            <Td fontWeight={700}>AKZ {Intl.NumberFormat("PT-br").format(el.somatorio)}</Td>
                            <Td fontWeight={700}>{el.quantidade}</Td>
                          </Tr>
                      ).slice(0,5)}
                    </Tbody>
                  </Table>
                      <Button as={Link} to="/periodo-contabilistico" bg="blue.500" alignSelf="flex-end" color="white" w="25%" mt={4}>Todos os periodos contabilisticos</Button>
                </Flex>

                <Flex mt={10} flex={1} width="100%" className="donut" shadow="md" borderLeft="5px solid" borderColor="blue.500" rounded={5}  bg="white" p={5} direction="column">
                  <Heading color="blue.500" fontWeight={500} mb={5}>{t("Ultimos pagamentos")}</Heading>
                  <Table variant='striped' colorScheme='gray'>
                    <Thead>
                      <Tr>
                        <Th>Referência</Th>
                        <Th>Montante</Th>
                        <Th>data e hora</Th>
                        <Th>Periodo</Th>
                        <Th>Transação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.mensagem.ultimosPagamentos.map((el,i) =>
                          <Tr key={i} color="blackAlpha.700">
                            <Td onClick={() => window.location.href = `/pagamentos/${el.referencia_do_servico}`} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>{el.referencia_do_servico}</Td>
                            <Td fontWeight={700}>AKZ {Intl.NumberFormat("PT-br").format(el.montante_da_operacao)}</Td>
                            <Td>{new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleDateString()} {new Date(el.data_movimento + " " + el.hora_do_movimento).toLocaleTimeString()}</Td>
                            <Td>{el.Identificacao_Log_EGR}</Td>
                            <Td>{el.Identificacao_Log_EGR + el.numero_Log_EGR}</Td>
                          </Tr>
                      ).slice(0,5)}
                    </Tbody>
                  </Table>
                      <Button as={Link} to="/pagamentos" alignSelf="flex-end" bg="blue.500" color="white" w="25%" mt={4}>Todos os pagamentos</Button>
                </Flex>
                
                {/* <Box ml={2} shadow="md" rounded={5}>
                    <Flex direction="column" bg="white" p={5}>
                      <Heading color="blue.500" fontWeight={500}>{t("Montante mensal")}</Heading>
                        <Flex flexDir="column" justifyContent="center" align="center" minHeight="100%">
                          <Stat>
                            <StatNumber fontSize={40}>AKZ {Intl.NumberFormat("PT-br").format(pagamentos3.sume || 0)}</StatNumber>
                            <StatHelpText>{new Date().toLocaleDateString().substr(-7)}</StatHelpText>
                          </Stat>
                        </Flex>                        
                    </Flex>

                    <Flex direction="column" mt={2} bg="white" p={5}>
                      <Heading color="blue.500" fontWeight={500}>{t("Quantidade mensal")}</Heading>
                        <Flex flexDir="column" justifyContent="center" align="center" minHeight="100%">
                          <Stat>
                            <StatNumber fontSize={40}>{pagamentos3.quantity || 0}</StatNumber>
                          </Stat>
                        </Flex>                        
                    </Flex>
                </Box> */}

              </Flex>
            </Box>
            
          
          <Box mt={5} color="gray.700" textAlign="center">
              <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
              <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </>
  )
}

export default Referencias