import { Flex, Box, Text, Center, Heading, useToast, TableContainer, Table, Thead, Tr, Th, Td, Tbody, Button} from '@chakra-ui/react'
import AXIOS from '../../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenuAdm, NavBarAdm, Loader, BotaoTop } from '../../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import CONFIG from '../../Config'
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { BGiConfirmed, GiConfirmed } from "react-icons/gi";
import ReactPaginate from 'react-paginate'
import { MdArrowBack, MdArrowForward, MdError, MdRemoveCircle, MdUpgrade } from 'react-icons/md'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [prods, setProds] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast();
  
  async function axiosReferencias(){
    setLoader(false)
    const {mensagem:{empresa_contratante: empresa}} = JSON.parse(loginValue)
    const referencia = await AXIOS.get(`/clientes/`, {headers: {empresa}})
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }
        
    console.log(referencia.data.mensagem)
    setLoader(true)
        
}

  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const filteresItensServices = async function(){
    const buscarTipos = await AXIOS.get(`/produtos-clientes/`)
    
    if(buscarTipos.data.status === "sucesso") {
       setProds(buscarTipos.data.mensagem)
    }else {
      setProds([])
    }
}


  useEffect(()=>{
     
    axiosReferencias(); 
    filteresItensServices()
    const value = JSON.parse(loginValue)
    if(value?.mensagem?.tipo_usuario){
     setUser(value.mensagem)
    }else{
        navigate('/login')
      }
      
  },[])

  const vertificarTipoProdutosOuServicos = function(entidade, tipo){
      
      
    if(prods.length){
            const find = prods.find(el => el.numero_entidade == entidade)
            if(find){
              const tipos = find?.registo?.find(el => el.id_tipo_produto == tipo)
              if(tipos){
                return "SIM"
              }else{
                return "NAO"
              }
              
            }else{
              // console.log("CONSOLE = ",entidade, "NAO")
              return "NAO"
            }
      }else {
        return "NAO"
      }
      
}

let permissao = user?.pagina_servicos == "true";

if(user?.pagina_servicos) permissao = user?.pagina_servicos == "true"
else permissao = user.todas_areas ? true : false;

  
return (
  <>
  <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm />
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>
          {
          
          permissao

          ? 
          <Box px={10} color="black" mt={10}>
          <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>Configuração de serviço</Text>
          <Button
            bg="gray.600"
            _hover={{ backgroundColor: "gray.700" }}
            mt={3}
            onClick={axiosReferencias}
            leftIcon={<MdUpgrade />}
            color="white"
            mb={2}
          >
            Actualizar lista
          </Button>
          <ReactPaginate
          
                   breakLabel="..."
                   nextLabel={<MdArrowForward/>}
                   onPageChange={handlePageClick}
                   pageRangeDisplayed={5}
                   pageCount={pageCount}
                   breakClassName="list-group-item"
                   previousLabel={<MdArrowBack/>}
                   renderOnZeroPageCount={null}
                    />
        {
        loader
        ?
        <Box>
          <Box shadow="sm" rounded={5} bg="white" p={10} mt={3} mb={5}>
            <Heading mb={10} fontSize={20}>Serviços de pagamento por sector</Heading>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th py={6} color="gray.500">Entidade</Th>
                    <Th py={6} color="gray.500">Pagamento / Carregamento</Th>
                    <Th py={6} color="gray.500">Facturas</Th>
                    {/* <Th py={6} color="gray.500">Recargas</Th> */}
                    <Th py={6} color="gray.500">&nbsp;</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {currentItems.map((el) => ( <Tr key={el.id_clientes} color="blackAlpha.700">
                      <Td fontWeight={500}>{el.nome_empresa}</Td>
                      <Td>
                        {vertificarTipoProdutosOuServicos(el.numero_entidade, 1) == "SIM" ? <GiConfirmed color='green' size={30} /> : <MdRemoveCircle color='red' size={30} />}
                      </Td>
                      <Td>
                      {vertificarTipoProdutosOuServicos(el.numero_entidade, 3) == "SIM" ? <GiConfirmed color='green' size={30} /> : <MdRemoveCircle color='red' size={30} />}
                      </Td>
                      <Td>
                      {/* {vertificarTipoProdutosOuServicos(el.numero_entidade, 2) == "SIM" ? <GiConfirmed color='green' size={30} /> : <MdRemoveCircle color='red' size={30} />} */}
                      </Td>
                      <Td>
                        <Link to={`/adm/servicos/${el.id_clientes}`} style={{color:"blue"}} >Parametrizar</Link>
                      </Td>
                    </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        :
        <Center>
          <Loader/>
        </Center>}
        </Box>
        :
          
        <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
          <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
          <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
          <MdError color='red' size={200} />
        </Box>
      }
    </Box>
  </Flex>
</>
)
}

export default Notificacoes