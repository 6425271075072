import { Flex, Box, Text, Center, useToast, Alert,
  AlertIcon,
  Stack,
  Button,
  Heading} from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from 'react'
import {  SideBarMenu, NavBar, Loader, BotaoTop } from '../Components/index'
import { useNavigate } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import CONFIG from '../Config'
import { MdArrowBack, MdArrowForward, MdError, MdMarkAsUnread, MdNotifications, MdOutlineMarkChatRead, MdUpgrade } from 'react-icons/md'
import { useTranslation } from 'react-i18next'


function Notificacoes() {

  const [referencias, setReferencias] = useState([])
  const [referenciasFilter, setReferenciasFilter] = useState([])
  const navigate =  useNavigate()
  const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
  const [user, setUser] = useState('1')
  const [loader, setLoader] = useState(false)
  const toast = useToast()
  const {t, i18n} = useTranslation()
  
  const marcarNaoLida = async (id_notficacao_entidade) => {
      try {
        const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
        const referencia = await AXIOS.patch(`/notificacoes-entidades/entidade/${entidade}/marcar-como-nao-lida/${id_notficacao_entidade}`)
        
        if(referencia.data.status === "erro")
            toast({
              title: t('Marcar notificação'),
              description: t(referencia.data.mensagem),
              status: 'error',
            })
        else{
            axiosReferencias()
            toast({
              title: t('Marcar notificação'),
              description: t(referencia.data.mensagem),
              status: 'success',
            })
        }
            
        console.log(referencia.data.mensagem)
        setLoader(true)
        
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
      }
  }
  const marcarLida = async (id_notficacao_entidade) => {
        try {
          const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
          const referencia = await AXIOS.patch(`/notificacoes-entidades/entidade/${entidade}/marcar-como-lida/${id_notficacao_entidade}`)
          
          if(referencia.data.status === "erro")
              toast({
                title: t('Marcar notificação'),
                description: t(referencia.data.mensagem),
                status: 'error',
              })
          else{
              axiosReferencias()
              toast({
                title: t('Marcar notificação'),
                description: t(referencia.data.mensagem),
                status: 'success',
              })
          }
              
          console.log(referencia.data.mensagem)
          setLoader(true)
          
        }catch (error) {
          toast({
            title: t('Alerta'),
            description: t("Não conseguimos ter comunicação! Verifique sua internet"),
            status: 'error',
          })
      }
  }
  
  async function axiosReferencias(){
  
  try {
    const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
    const referencia = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}`)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)
    }
        
    console.log(referencia.data.mensagem)
    setLoader(true)
    
  }catch (error) {
    toast({
      title: t('Alerta'),
      description: t("Não conseguimos ter comunicação! Verifique sua internet"),
      status: 'error',
    })
  }
        
}

  
  useEffect(()=>{
      axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
          navigate('/login')
        }
  },[])
  
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 10
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  async function actualizarNotificacoes(){
  
    try {
      setLoader(false)
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/notificacoes-entidades/entidade/${entidade}`)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem)
      }
          
      console.log(referencia.data.mensagem)
      setLoader(true)
      
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
    }
          
  }
  
  
  let permissao = user?.pagina_notificacao == "true";

  if(user?.pagina_notificacao) permissao = user?.pagina_notificacao == "true"
  else permissao = user.numero_entidade

return (
  <>
  <Flex color='white' bg="white" direction="row">
    <SideBarMenu />
    <BotaoTop/>
    <Box flex={1}>
      <NavBar titulo={t("Minhas notificações")} imagem={<MdNotifications size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}/>
      <Flex direction="column" mt={5} justifyContent="space-between" style={{minHeight:'90vh'}}>
      {permissao ? <Box px={10} color="black" mt={10}>
          {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>{t("Minhas notificações")}</Text> */}
          <Button mb={5}
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarNotificacoes}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
                  </Button>
        {
        loader
        ?           
            <Stack spacing={3}>              
              {currentItems.map(el =>
                <Alert status={el.estado} variant='left-accent'>
                    <AlertIcon />
                    <Flex justifyContent="space-between" w="100%" alignItems="center">
                      {t(el.descricao_notificado).trim()}
                      <Text>{new Date(el.data_notificada).toLocaleDateString()} {new Date(el.data_notificada).toLocaleTimeString()} &nbsp; {el.leitura == "não lida" ? <Button size="sm" leftIcon={<MdMarkAsUnread />} onClick={() => marcarLida(el.id_notficacao_entidade)} colorScheme='gray'>{t("Marcar como lida")}</Button> : <Button size="sm" onClick={() => marcarNaoLida(el.id_notficacao_entidade)} leftIcon={<MdOutlineMarkChatRead />}  colorScheme='blackAlpha'>{t("Marcar como não lida")}</Button>}</Text>                      
                    </Flex>
                </Alert>
              )} 
              <ReactPaginate
                     breakLabel="..."
                     nextLabel={<MdArrowForward />}
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={5}
                     pageCount={pageCount}
                     breakClassName="list-group-item"
                     previousLabel={<MdArrowBack />}
                     renderOnZeroPageCount={null}
                      />
            </Stack>
        :
        <Center>
          {/* <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          /> */}
          <Loader />
        </Center>}
          </Box>
          :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
        <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
        </Box>
      </Flex>
    </Box>
  </Flex>
</>
)
}

export default Notificacoes