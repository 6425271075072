import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, FormControl, Divider, FormLabel, Input, Box, Text, Select, Heading, Flex, useToast, Alert } from '@chakra-ui/react'
import {  MdAdd, MdCancel, MdDelete, MdEdit, MdPeople, MdProductionQuantityLimits, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { ModalAlterarPag, ModalRemoveTipoProduto } from '../Components'

export default function PlacementExample({texto, produto, entidadeID}) {
    const [placement] = useState('right')
    const [isOpen, setIsOpen] = useState(false)
    const [referencias, setReferencias] = useState([])
    const [user, setUser] = useState('1')
    const [desablitarAdd, setDesablitarAdd] = useState(false)
    const [atualizador, setAtualizador] = useState(false)
    const [produtoSelecionado, setProdutoSelecionado] = useState({})
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
      const referencia = await AXIOS.get(`/produtos-clientes/produto/${produto}`);
      
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length)
          setReferencias(referencia.data.mensagem[0].registo)
        else
        setReferencias([])
      }
          
  }  
    

    const formik = useFormik({
      initialValues:{
        produto:'',
        codigo_do_produto: '',
        montantes_fixo: 'NÃO'
      },
      onSubmit: async (values)=> {

        try {
          const res =  await AXIOS.post(`/produtos-clientes`,{...values, cliente_tipo_produto: produto})

          toast({
            title: 'Adicionar produtos',
            description: "Á carregar o registro",
            status: 'loading',
            duration: 4000,
            isClosable: true,
          })
          
          if(res.data.status === "sucesso"){
            axiosReferencias()
            setTimeout(()=>{            
              setIsOpen(false)
              formik.resetForm()
            },1000)        
            
            setTimeout(()=>{
              toast({
                title: 'Adicionar produtos',
                description: res.data.mensagem,
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
            },5000)

          }else{
            setTimeout(()=>{
              toast({
                title: 'Adicionar produtos',
                description: res.data.mensagem,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
            },5000)
          }
        
        } catch (error) {
          toast({
            title: 'Adicionar de produtos',
            description: "Aconteceu algo! Tente de novo",
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
        
      },

    })

    const excluirTipoProd = async function(value){

      try {
        const res = await AXIOS.delete(`/produtos-clientes/${value}`)

        toast({
          title: 'Exlusão de produtos',
          description: "Á carregar o pedido",
          status: 'loading',
          duration: 1000,
          isClosable: true,
        })
        
        setTimeout(() => {
          if(res.data.status == "sucesso"){
            toast({
              title: 'Exlusão de produtos',
              description: res.data.mensagem,
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          }else{
            toast({
              title: 'Exlusão de produtos',
              description: res.data.mensagem,
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
          
          axiosReferencias(); 
        }, 1500)

      } catch (error) {
        toast({
          title: 'Adicionar valores fixos do produto',
          description: "Aconteceu algo! Tente de novo.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
  }

  const selecionarProdutosParaActualizar = async (registo)=>{
  
    
      
      try {
        formik.handleChange({target : {value: registo.produto, name: "produto"}})
        formik.handleChange({target : {value: registo.codigo_do_produto, name: "codigo_do_produto"}})
        formik.handleChange({target : {value: registo.montantes_fixo, name: "montantes_fixo"}})
        
        setProdutoSelecionado(registo.id_produto)
        setDesablitarAdd(true)
        setAtualizador(false)

      } catch (error) {
        toast({
          title: 'Adicionar valores fixos do produto',
          description: "Aconteceu algo! Tente de novo.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
  }

  const cancelarActualizador = async ()=>{
  
    
      try {
        formik.handleChange({target : {value: "", name: "produto"}})
        formik.handleChange({target : {value: "", name: "codigo_do_produto"}})
        formik.handleChange({target : {value: "", name: "montantes_fixo"}})
        
        setDesablitarAdd(false)
        setAtualizador(true)
      } catch (error) {
        toast({
          title: 'Adicionar valores fixos do produto',
          description: "Aconteceu algo! Tente de novo.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
  }

  const addValoresFixos = async (registo, desc, userCod)=>{


    try {
      const montante = `${userCod.texto1}${userCod.texto2}${userCod.texto3}${userCod.texto4}${userCod.texto5}${userCod.texto6}${userCod.texto7}${userCod.texto8}${userCod.texto9}${userCod.texto10}`
    const paraEnviarBD = `${String(montante).substr(0,String(montante).length - 2)}.${String(montante).substr(-2)}`
    
    if(!desc){
      toast({
        title: 'Adicionar valores fixos do produto',
        description: "Descrição invalida",
        status: 'error',
        duration: 2000,
        isClosable: true,
      })

      return
    }

    if(!montante){
      toast({
        title: 'Adicionar valores fixos do produto',
        description: "Descrição invalida",
        status: 'error',
        duration: 2000,
        isClosable: true,
      })

      return
    }

    const dados = {
      produto_cliente: registo.id_produto,
      entidade_produdo: entidadeID,
      descricao: desc,
      montante_fixo: paraEnviarBD

    }



    const res =  await AXIOS.post(`/produtos-valores-fixos`,dados)

    toast({
      title: 'Adicionar valores fixos do produto',
      description: "Á carregar o pedido",
      status: 'loading',
      duration: 1000,
      isClosable: true,
    })

    setTimeout(()=> {
      if(res.data.status === "sucesso"){
        toast({
          title: 'Adicionar valores fixos do produto',
          description: res.data.mensagem,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      }else {
        toast({
          title: 'Adicionar valores fixos do produto',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    },1400)
    

    } catch (error) {
      toast({
        title: 'Adicionar valores fixos do produto',
        description: "Aconteceu algo! Tente de novo.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    

  }


  const actualizadorProdutos = async () => {


    try {
      const res =  await AXIOS.patch(`/produtos-clientes/${produtoSelecionado}`,formik.values)

      toast({
        title: 'Actualização do produto',
        description: "Á carregar o pedido",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })
      
      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          toast({
            title: 'Actualização do produto',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          axiosReferencias()
        },2000)
      }else{
        toast({
          title: 'Actualização do produto',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: 'Actualização do produto',
        description: "Aconteceu algo! Tente de novo.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }

    
  }
    
    
    
    const onClose = function(){
        setIsOpen(false)
    }
    useEffect(()=>{
      if(loginValue){
       const value = JSON.parse(loginValue)
       setUser(value.mensagem)
      }
      axiosReferencias()
      
  },[])
    return (
      <>
        <Text onClick={()=>setIsOpen(true)}>
          {texto}
        </Text>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Adicionar produtos para")} <span>{texto}</span></DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                   <FormControl mt={2}>
                        <FormLabel>{t("Nome do produto")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='produto' onChange={formik.handleChange} value={formik.values.produto} />
                         </Flex>
                    </FormControl>
                   <FormControl mt={2}>
                        <FormLabel>{t("Código do produto")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' name='codigo_do_produto' onChange={formik.handleChange} value={formik.values.codigo_do_produto} />
                         </Flex>
                    </FormControl>
                    {texto == "Pagamento/Carregamento" && <FormControl mt={2}>
                        <FormLabel>Valores Fixos? (<span style={{color:"red"}}>"SIM"</span> PARA CARREGAMENTOS)</FormLabel>
                        <Select required name='montantes_fixo' onChange={formik.handleChange} value={formik.values.montantes_fixo}>
                          <option value="NÃO">NÃO</option>
                          <option value="SIM">SIM</option>
                        </Select>
                      </FormControl>}
                    <Button isDisabled={desablitarAdd} leftIcon={<MdProductionQuantityLimits/>} bg="blue.900" color="white" mr={5} type='submit' mt={5}>{t("Adicionar")}</Button>
                    {desablitarAdd && <Button onClick={actualizadorProdutos} isDisabled={atualizador} leftIcon={<MdEdit/>} bg="green.600" color="white" mt={5}>{t("Actualizar")}</Button>}
                    {desablitarAdd && <Button isDisabled={atualizador} onClick={cancelarActualizador} leftIcon={<MdCancel/>} bg="red.600" color="white" ml={5} mt={5}>{t("Cancelar")}</Button>}
                 
                </Box> 
              </form>
              <Divider my={5} />
              <Text my={3}>{t("Lista de produtos")}</Text>
              {referencias.map(el => 
                <Alert display="flex" bg="white" borderLeft="5px solid" borderColor="blue.400" justifyContent="space-between" mt={2}>
                  <Heading cursor="pointer" onClick={() => selecionarProdutosParaActualizar(el)} size="md">{el.produto}</Heading>
                  <Box display="flex" justifyContent="space-between">
                    <ModalAlterarPag gravarValoresFixos={addValoresFixos} tipoProduto={texto} produto={el} entidade={entidadeID} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <ModalRemoveTipoProduto registo={el} excluirRef={excluirTipoProd} />
                  </Box>

                </Alert>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }